import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFlask } from "@fortawesome/free-solid-svg-icons";
import RightSideSection from "../../../../right_section/RightSideSection";
import MCQTestCardCarousel from "./MCQTestCardCarousel";
import PostCard from "../../../PostCard";
import Technologies from "../../../../right_section/Technologies";
import { Helmet } from "react-helmet";
import { quizTests } from "./helper";
import CardCover from "../../../../../shared_components/CardCover";
import CustomButton from "../../../../../shared_components/CustomButton";
import { FiSearch } from "react-icons/fi";
class IndexQuizeTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { query } = this.state;

    const filteredData = quizTests.filter((item) => {
      return item.title.toLowerCase().includes(query.toLowerCase());
    });

    const description =
      "Time to boost your Brain, let’s evaluate our skills without being fear of judged.";
    const title = "Self Evalution Programming Quiz Tests | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet="true"
            name="description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            http-equiv="cleartype"
            content="on"
          ></meta>
          <meta
            data-react-helmet="true"
            name="apple-mobile-web-app-capable"
            content="yes"
          ></meta>
          <meta
            data-react-helmet="true"
            name="robots"
            content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:locale"
            content="en_US"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:type"
            content="website"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:url"
            content="https://www.datacode.in/"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:site_name"
            content="Datacode.in"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:secure_url"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:width"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:height"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            name="keywords"
            content="programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:site"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:creator"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <link
            data-react-helmet="true"
            rel="shortlink"
            href="https://www.datacodoe.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//www.datacode.in"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//s.w.org"
          ></link>
          <link
            data-react-helmet="true"
            rel="alternate"
            type="application/rss+xml"
            title="Datacode"
            href="https://www.datacode.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="canonical"
            href="https://www.datacode.in/"
          ></link>
        </Helmet>
        <div className="row mx-0">
          <div className="col-12 px-0">
            <div className="row mx-0 px-3">
              <div className="col-1"></div>
              <div className="col-md-8 px-0 col-12 mt-5">
                <h1
                  className=" mb-4 rounded"
                  
                >
                Programming Quiz Test
                </h1>
                <h5 className="">
                  Time to boost your Brain, let’s evaluate our skills without
                  being fear of judged.
                </h5>
                <p className="">
                  Learning never exhausts the mind, it’s time to become a gamer
                  in the world of programming. Hustling should not be stopped!{" "}
                </p>
                <p className="">
                  Doing Practice makes you perfect, let’s dive in the era of
                  programming.
                </p>

                {/* <Link
              to='/home'
              className='badge mr-1 my-4'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link> */}
              </div>
              <div className="col-3"></div>
            </div>
            <div className="row mx-0 py-md-5 py-3">
              <div className="col-1"></div>
              <div className="col-4">
                <div className="d-flex border rounded-lg">
                  <input
                    type="text"
                    placeholder="Search Here"
                    value={query}
                    onChange={this.handleChange}
                    className="w-100 px-2 border-0 rounded-lg"
                  />
                  <CustomButton
                    icon={<FiSearch />}
                    className="px-3"
                    hoverEffect={false}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0 justify-content-center justify-content-lg-start">
              <div className="col-1"></div>
              <div className="col-md-8 col-12 px-0">
                <div className="col-12 px-0">
                  <div className="row mx-0">
                    {filteredData.length > 0 ? (
                      filteredData.map((resource, index) => {
                        return (
                          <div className="col-md-4 my-3" key={resource.title}>
                            <PostCard
                              key={index}
                              cardComponent={
                                <CardCover
                                  index={index}
                                  className="h-100 d-flex justify-content-center"
                                  cardName={resource.title}
                                />
                              }
                              title={resource.title}
                              paragraph={resource.paragraph}
                              link={resource.link}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12 text-center mt-4">
                        <h4 className="text-muted">No results found.</h4>
                      </div>
                    )}
                  </div>
                </div>

                <h5 className="mt-3">For More Quiz Tests</h5>
                <MCQTestCardCarousel />
              </div>
              <div className="col-md-3 col-12">
                <RightSideSection />
                <Technologies />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IndexQuizeTests;
