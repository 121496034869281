import Aos from "aos";
import { useEffect } from "react";
import CustomButton from "../../../shared_components/CustomButton";

const EnrollSection = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className="row mx-0 justify-content-center py-lg-5">
        <div className="col-lg-10 col-12 px-lg-0 px-3 py-5">
          <div className="border-0 py-5 enroll-section" data-aos="fade-up">
            <div className="p-lg-5 p-3  text-center d-flex flex-column justify-content-center align-items-center gap-1 ">
              <h5 className="p-2 border-0 rounded-lg">
                Real Work Experience Platform
              </h5>
              <h1 className="">
                Experience gets you the job. Not your degree.
              </h1>
              <p className="px-lg-5">
                Datacode is experience providing platform to help you break out
                of no experience and no job cycle in your resume.
              </p>
              <div>
                <CustomButton buttonName="Enroll Now" className="fs-18" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EnrollSection;
