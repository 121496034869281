import React, { useEffect } from "react";
import AOS from "aos";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FcLike } from "react-icons/fc";
import testimonialData from "../../../../src/utils/testimonialData";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const SuccessStories = () => {
  return (
    <div className="row mx-0 justify-content-center projects my-4">
      <div className="col-12 col-md-8 col-lg-8 px-0">
        <h2 className="text-center pb-5">Success Stories Of Our Students</h2>
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows={true}
          autoPlay
          autoPlaySpeed={5000}
          centerMode={false}
          className="px-0"
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={true}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {testimonialData.map((items) => (
            <div className="row mx-0 container mb-4 px-0">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 px-0 box1 d-flex justify-content-center">
                <div className="">
                  <img src={items.image1} className="" height={300} />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 box2 px-0 success-card-text">
                <div className="p-4">
                  <h4 className="text-light">
                    {items.name} <FcLike />
                  </h4>
                  <h6 className="text-light py-2">{items.college}</h6>
                  <p className="py-2 text-light">{items.review}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
export default SuccessStories;
