import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "../../../../../app/components/footer_component/CardCarouselItem";
import CardCover from "../../../../../shared_components/CardCover";

class MCQTestCardCarousel extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className="mb-5 d-none d-sm-block">
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={0}
                      cardName="Python Quiz Test"
                      className="py-5"
                    />
                  }
                  title="Python Quiz Test"
                  link="/python_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={1}
                      cardName="C Quiz Test"
                      className="py-5"
                    />
                  }
                  title="C Quiz Test"
                  link="/c_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={2}
                      cardName="C++ Quiz Test"
                      className="py-5"
                    />
                  }
                  title="C++ Quiz Test"
                  link="/cpp_quize_tests"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={3}
                      cardName="Java Quiz Test"
                      className="py-5"
                    />
                  }
                  title="Java Quiz Test"
                  link="/java_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={4}
                      cardName="avaScript Quiz Test"
                      className="py-5"
                    />
                  }
                  title="JavaScript Quiz Test"
                  link="/js_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={2}
                      cardName="Data structure Quiz Test"
                      className="py-5"
                    />
                  }
                  title="Data structure Quiz Test"
                  link="/ds_quize_tests"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={4}
                      cardName="DBMS Quiz Test"
                      className="py-5"
                    />
                  }
                  title="DBMS Quiz Test"
                  link="/dbms_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={2}
                      cardName="SQL Quiz Test"
                      className="py-5"
                    />
                  }
                  title="SQL Quiz Test"
                  link="/sql_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={5}
                      cardName="OOPs Quiz Test"
                      className="py-5"
                    />
                  }
                  title="OOPs Quiz Test"
                  link="/oops_quize_tests"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={0}
                      cardName="Html & CSS  Quiz Test"
                      className="py-5"
                    />
                  }
                  title="Html & CSS  Quiz Test"
                  link="/html_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={1}
                      cardName="Angular Quiz Test"
                      className="py-5"
                    />
                  }
                  title="Angular Quiz Test"
                  link="/angular_quize_tests"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      index={0}
                      cardName="React Quiz Test"
                      className="py-5"
                    />
                  }
                  title="React Quiz Test"
                  link="/react_quize_tests"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className="mb-5 d-block d-md-none">
          <Carousel indicators={false}>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={0}
                    cardName="Python Quiz Test"
                    className="py-5"
                  />
                }
                title="Python Quiz Test"
                link="/python_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={1}
                    cardName="C Quiz Test"
                    className="py-5"
                  />
                }
                title="C Quiz Test"
                link="/c_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={2}
                    cardName="C++ Quiz Test"
                    className="py-5"
                  />
                }
                title="C++ Quiz Test"
                link="/cpp_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={3}
                    cardName="Java Quiz Test"
                    className="py-5"
                  />
                }
                title="Java Quiz Test"
                link="/java_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={4}
                    cardName="avaScript Quiz Test"
                    className="py-5"
                  />
                }
                title="Js Quiz Test"
                link="/js_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={2}
                    cardName="Data structure Quiz Test"
                    className="py-5"
                  />
                }
                title="Data Structure Quiz Test"
                link="/ds_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={4}
                    cardName="DBMS Quiz Test"
                    className="py-5"
                  />
                }
                title="Dbms Quiz Test"
                link="/dbms_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={2}
                    cardName="SQL Quiz Test"
                    className="py-5"
                  />
                }
                title="Sql Quiz Test"
                link="/sql_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={5}
                    cardName="OOPs Quiz Test"
                    className="py-5"
                  />
                }
                title="OOPs Quiz Test"
                link="/oops_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={0}
                    cardName="Html & CSS  Quiz Test"
                    className="py-5"
                  />
                }
                title="Html & CSS Quiz Test"
                link="/html_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={0}
                    cardName="Android Quiz Test"
                    className="py-5"
                  />
                }
                title="Android Quiz Test"
                link="/android_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={0}
                    cardName="React Quiz Test"
                    className="py-5"
                  />
                }
                title="React Quiz Test"
                link="/react_quize_tests"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    index={1}
                    cardName="Angular Quiz Test"
                    className="py-5"
                  />
                }
                title="Angular Quiz Test"
                link="/angular_quize_tests"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}

export default MCQTestCardCarousel;
