import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "../../../../app/components/footer_component/CardCarouselItem";
import CardCover from "../../../../shared_components/CardCover";

class PracticeQuestionCardCarousel extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className="mb-5 d-none d-sm-block">
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="C Programming Practice Questions"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="C Programming Practice Questions"
                  link="/index_c_practice_questions"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Python Programming Practice Questions"
                      index={2}
                      className="py-5"
                    />
                  }
                  title="Python Programming Practice Questions"
                  link="/index_python_practice_questions"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Java Programming Practice Questions"
                      index={3}
                      className="py-5"
                    />
                  }
                  title="Java Programming Practice Questions"
                  link="/index_java_practice_questions"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  imgsource="dsa_programming_questions.jpeg"
                  title="DS & Algo Practice Questions"
                  link="/index_ds_practice_questions"
                />
                <CardCarouselItem
                  cardComponent = {<CardCover index={0} className="py-5" cardName="Coming soon"/>}
                  title="Practice Questions"
                  link="/index_practice_questions"
                />
                <CardCarouselItem
                  cardComponent = {<CardCover index={0} className="py-5" cardName="Coming soon"/>}
                  title="Practice Questions"
                  link="/index_practice_questions"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className="mb-5 d-block d-md-none">
          <Carousel indicators={false}>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="C Programming Practice Questions"
                    index={0}
                    className="py-5"
                  />
                }
                title="C programming questions"
                link="/c_programming_questions"
              />
            </Carousel.Item>

            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                    <CardCover
                      cardName="Java Programming Practice Questions"
                      index={3}
                      className="py-5"
                    />
                  }
                title="Java Programming Questions"
                link="/java_programming_questions"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="Python Programming" index={2} />
                }
                title="Python Programming"
                link="/python"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}

export default PracticeQuestionCardCarousel;
