import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { techIcon } from "../helpers";

import { Helmet } from "react-helmet";
import {
  FaBookmark,
  FaComment,
  FaHeart,
  FaRegBookmark,
  FaRegHeart,
} from "react-icons/fa6";
import { handleTextVisibility } from "../../../../../../utils";
import Comment from "../comment";
import CustomButton from "../../../../../../shared_components/CustomButton";

const TutorialPage = ({
  match: {
    params: { id },
  },
  userData,
  userData: { _id, userName, email, myTutorialsLibrary },
  getTutorialById,
  tutorial,
  tutorial: { tags, img, description, link, createdAt },
  unlikeTutorial,
  likeTutorial,
  saveToLibrary,
  unsaveFromLibrary,
  submitter,
}) => {
  const history = useHistory();
  const [techDetails, setTechDetails] = useState({});
  const [isSaved, setSaved] = useState();
  const [isLiked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    techIcon.forEach((item) => {
      if (item.id === id) {
        return setTechDetails(item);
      }
    });

    getTutorialById(id).then((res) => {
      if (res) {
        setTechDetails(res.data);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    techIcon.find((item) => {
      if (item.id === id) {
        return setTechDetails(item);
      }
    });
    if (tutorial) {
      setLiked(
        tutorial
          ? tutorial.likes && tutorial.likes.find((like) => (like === _id) || (like === userName))
          : false,
      );
      setLikeCount(tutorial ? tutorial.likes && tutorial.likes.length : 0);
      setSaved(
        userData &&
        userData.myTutorialsLibrary &&
        myTutorialsLibrary.find((lib) => lib === tutorial.id),
      );
    }
  }, [tutorial]);

  const handleUnlike = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    unlikeTutorial({ id, user: _id }).then((res) => {
      if (res) {
        setLiked(res && res?.data?.likes.find((like) => (like === _id) || (like === userName)));
        setLikeCount(res && res?.data?.likes.length);
        getTutorialById(id);
      }
    });
  };

  const handleLike = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    likeTutorial({ id, user: _id }).then((res) => {
      if (res) {
        setLiked(res?.data?.likes.find((like) => (like === _id) || (like === userName)));
        setLikeCount(res?.data?.likes.length);
        getTutorialById(id);
        // handleTutorialList()
      }
    });
  };

  const handleSaveToLibrary = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    const saveItem = tutorial.id;
    saveToLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(true);
      }
    });
  };

  const handleUnsaveFromLibrary = () => {
    const saveItem = tutorial.id;
    unsaveFromLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(false);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{`Find the Best ${techDetails && techDetails.technology
          } Courses & Tutorials | Datacode.in`}</title>
        <meta
          data-react-helmet='true'
          name='description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          http-equiv='cleartype'
          content='on'
        ></meta>
        <meta
          data-react-helmet='true'
          name='apple-mobile-web-app-capable'
          content='yes'
        ></meta>
        <meta
          data-react-helmet='true'
          name='robots'
          content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:locale'
          content='en_US'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:type'
          content='website'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:title'
          content={`Find the Best ${techDetails && techDetails.technology
            } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://www.datacode.in/'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:site_name'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:secure_url'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:width'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:height'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          name='keywords'
          content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:card'
          content='summary_large_image'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:title'
          content={`Find the Best ${techDetails && techDetails.technology
            } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:site'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:creator'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <link
          data-react-helmet='true'
          rel='shortlink'
          href='https://www.datacodoe.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//www.datacode.in'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//s.w.org'
        ></link>
        <link
          data-react-helmet='true'
          rel='alternate'
          type='application/rss+xml'
          title='Datacode'
          href='https://www.datacode.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://www.datacode.in/'
        ></link>
      </Helmet>
      <div className='row mx-0 mt-3 mt-md-0  tutorial-page justify-content-center'>
        <div className='col-12 p-0 mb-5'>
          <div className='row mx-0  py-md-0 category-header justify-content-center p-3'>
            <div className='col-12 col-md-7  left-header-section px-md-5   px-2'>
              <p className='text-left nav-link px-2 px-md-0 pt-4'>
                <Link to='/tutorials'>Tutorials</Link> /{" "}
                <Link
                  to={`/tutorials/${techDetails && techDetails.technology}`}
                >{`${techDetails && techDetails.technology
                  } Tutorial`}</Link>{" "}
                /{" "}
                <Link
                  to={`/tutorials/${techDetails && techDetails.technology}`}
                >{`${handleTextVisibility(techDetails.title, 50)}`}</Link>
              </p>
              <div className='tutorial-name mt-5'>
                <h1>{techDetails && techDetails.title}</h1>
                <h2>{techDetails && techDetails.technology}</h2>
              </div>
              <div className='mt-3 submitter-card row mx-0 justify-content-between align-items-center'>
                <Link to={`/profile/${submitter && submitter.userName}`}>
                  {/* <h6>Tutorial Submitted By:-</h6> */}

                  <div className='col-12 px-0 d-flex align-items-center'>
                    <img
                      src={
                        submitter && submitter.imgUrl
                          ? submitter.imgUrl
                          : require(`../../../../../../assets/images/svg/React.png`)
                      }
                      alt='avatar'
                      loading='lazy'
                      className='rounded-circle border'
                    />
                    <div className='d-flex flex-column ml-1'>
                      <span>
                        <b>
                          {submitter && submitter.firstName
                            ? `${submitter.firstName} ${submitter.lastName}`
                            : "Datacode Contributor"}
                        </b>
                      </span>
                      <span>
                        <span className="d-block">{submitter ? submitter.bio : ""}</span>
                        <span className="text-secondary">Uploaded {moment(createdAt).fromNow()}</span>
                      </span>
                    </div>
                  </div>
                </Link>
                <div className='d-flex '>
                  <div>
                    {isLiked ? (
                      <FaHeart
                        onClick={() => handleUnlike()}
                        className='fad fa-heart red mx-2'
                      />
                    ) : (
                      <FaRegHeart
                        onClick={() => handleLike()}
                        className='far fa-heart red mx-2'
                      />
                    )}
                    <span className='mt-md-4 mt-2 mb-0'>{likeCount}</span>
                  </div>
                  <div className=''>
                    {isSaved ? (
                      <>
                        <FaBookmark
                          onClick={() => handleUnsaveFromLibrary()}
                          className='fad fa-bookmark voilet mx-2'
                        />
                      </>
                    ) : (
                      <>
                        <FaRegBookmark
                          onClick={() => handleSaveToLibrary()}
                          className='far fa-bookmark voilet mx-2'
                        />
                      </>
                    )}
                  </div>
                  <div className='d-flex'>
                    <Link to={`/tutorial/${id}`}>
                      <FaComment className=' voilet mx-2' />
                    </Link>
                    <span className='mb-0'>
                      {tutorial.comments && tutorial.comments.length}
                    </span>
                  </div>
                </div>
              </div>
              <div className='tags-input row m-0 mt-3'>
                <div className='col-12 p-0'>
                  <div className='tags-area d-flex justify-content- border-0'>
                    {tags &&
                      tags.map((tag, index) => (
                        <div className='tag' key={index}>
                          <span>{tag}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <p className='text-justify'>
                {`${handleTextVisibility(description, 200)}`}
              </p>

              <a target='blank' href={techDetails && techDetails.link}>
                <CustomButton buttonName="Start Learning"/>
              </a>
            </div>
            <div className='col-12 col-md-4 px-0 text-center d-flex justify-content-right right-header-section mt-5'>
              <img
                src={
                  img
                    ? img
                    : require(`../../../../../../assets/images/svg/tech_mentor.svg`)
                }
                alt='avatar'
                loading='lazy'
                className='text-right my-auto mx-auto img-fluid img-circle d-block'
              />
            </div>
          </div>
        </div>
        <div className='tutorial-feedback w-100 mt-5 py-4 pb-5'>
          <div className='row mx-0 mt-5 px-3 comment-section justify-content-center'>
            <div className='col-12 px-0 d-flex justify-content-center'>
              <div>
                <h4 className='text-center text-justify'>
                  Write your reviews and comments of this tutorial
                </h4>
                <p className='px-4 text-justify'>
                  Ask question and provide information that might be helpful to
                  the people taking this course.
                </p>
                <Comment id={id} userName={userName} />
              </div>
            </div>
            <div className='col-12 mt-5'>
              <h4>
                Comments <FaComment />
              </h4>
              {tutorial.comments &&
                tutorial.comments.reverse().map((item, i) => (
                  <div className='my-3 user-comment p-3' key={i}>
                    <div className='d-flex p-0 mb-2 align-items-center'>
                      <img
                        height='35px'
                        width='35px'
                        src={require(`../../../../../../assets/images/svg/React.png`)}
                        alt='avatar'
                        loading='lazy'
                        className='mr-2 text-right img-fluid img-circle d-block'
                      />
                      <div className='comment-name text-left'>
                        <h6 className='mb-0'>{item.postedBy}</h6>
                        <span className='comment-time text-start'>
                          {moment(item.createdAt).fromNow()}
                        </span>
                      </div>
                    </div>
                    <p className='text-left'>{item.text}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TutorialPage.defaultProps = {
  userName: "",
  email: "",
  id: "",
  userData: {},
  tutorial: {},
  tags: [],
};

TutorialPage.propTypes = {
  userName: PropTypes.string,
  email: PropTypes.string,
  userData: PropTypes.object,
  id: PropTypes.string,
  tutorial: PropTypes.object,
};

export default TutorialPage;
