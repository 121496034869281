import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../shared_components/CustomButton";
import CardCover from "../../shared_components/CardCover";
class PostCard extends Component {
  state = {};

  render() {
    const { cardComponent } = this.props;
    return (
      <>
        <div className="post-card h-100">
          <div className="card h-100 tech-card">
            <div
              className="card-img-container position-relative "
              style={{ height: "180px" }}
            >
              <Link to={this.props.link} className="text-decoration-none">
                {cardComponent}
              </Link>
            </div>
            <div className="card-body">
              <Link to={this.props.link}>
                <h3 className="card-title h4 fw-bold mb-3">
                  {this.props.title.length > 25
                    ? this.props.title.slice(0, 20) + "..."
                    : this.props.title}
                </h3>
              </Link>
              <p className="card-text text-muted">{this.props.paragraph}</p>
            </div>
            <div className="mt-auto px-3 mb-3 ">
              <Link to={this.props.link}>
                <CustomButton
                  buttonName="Learn More"
                  className="w-100 py-2 fw-semibold"
                />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PostCard;
