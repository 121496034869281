import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { connect } from "react-redux";

import { setLocalUser } from "../../../components/user/actions";
import DatacodeConfig from "../../../core/config";

// --------------------------------------------
// import HomeComponent from "../../../components/main_component/home";
import LandingPage from "../../../components/main_component/landing_page";
import ContactUs from "../header_component/ContactUs";
import OurPartners from "../header_component/OurPartners";
// Our Story
import OurStory from "../header_component/OurStory";

// Articles
import IndexArticles from "../../../components/main_component/aticles/IndexArticles";
// Progrmminng blogs
import ProgrammingLanguagesBlog from "../../../components/main_component/aticles/programming_article/Programming_Languages_Blog";
import ProgrammingBlog2 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog2";
import ProgrammingBlog3 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog3";
import ProgrammingBlog4 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog4";
import ProgrammingBlog5 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog5";
import ProgrammingBlog6 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog6";
import ProgrammingBlog7 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog7";
import ProgrammingBlog8 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog8";
import ProgrammingBlog9 from "../../../components/main_component/aticles/programming_article/ProgrammingBlog9";

// Machine learning Blogs
import MachineLearningBlog from "../../../components/main_component/aticles/machine_learning_article/MachineLearningBlog";
//WebDesign Blog Pagination
import WebDesignBlog from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog";
import WebDesignBlog2 from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog2";
import WebDesignBlog3 from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog3";
import WebDesignBlog4 from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog4";
import WebDesignBlog5 from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog5";
import WebDesignBlog6 from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog6";
import WebDesignBlog7 from "../../../components/main_component/aticles/webdesign_article/WebDesignBlog7";

// Documentation Blogs
import DocumentationBlog from "../../../components/main_component/aticles/documentation/DocumentationBlog";
import DocumentationBlog2 from "../../../components/main_component/aticles/documentation/DocumentationBlog2";
import DocumentationBlog3 from "../../../components/main_component/aticles/documentation/DocumentationBlog3";
import DocumentationBlog4 from "../../../components/main_component/aticles/documentation/DocumentationBlog4";

// DBMS Blogs
import DBMSBlog from "../../../components/main_component/aticles/dbms_article/DBMSBlog";
import DBMSBlog2 from "../../../components/main_component/aticles/dbms_article/DBMSBlog2";
import DBMSBlog3 from "../../../components/main_component/aticles/dbms_article/DBMSBlog3";

//Mock Drive Article
import MockDriveArticle from "../../../components/main_component/aticles/mock_drive_article/MockDriveArticle";

// Interview Routinig

import AndroidInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/AndroidInterviewQuestions";
import AngularInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/AngularInterviewQuestions";
import BootstrapInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/BootstrapInterviewQuestions";
import CppInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/C++InterviewQuestions";
import CInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/CInterviewQuestions";
import CloudComputingInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/CloudComputingInterviewQuestions";
import CNInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/CNInterviewQuestions";
import DataStructureInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/DataStructureInterviewQuestions";
import DBMSInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/DBMSInterviewQuestions";
import DjangoInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/DjangoInterviewQuestions";
import HtmlInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/HtmlInterviewQuestions";
import IndexInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/IndexInterviewQuestions";
import JavaInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/JavaInterviewQuestions";
import JSInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/JSInterviewQuestions";
import OOPsInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/OOPsInterviewQuestions";
import OSInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/OSInterviewQuestions";
import RDBMSInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/RDBMSInterviewQuestions";
import ReactInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/ReactInterviewQuestions";
import SDLCInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/SDLCInterviewQuestions";
import SQLInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/SQLInterviewQuestions";
import TestingInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/TestingInterviewQuestions";

// Books Routing
import IndexBooks from "../../../components/main_component/posts/books/IndexBooks";
import PythonBooks from "../../../components/main_component/posts/books/PythonBooks";
// Index Quize Test
import IndexQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/IndexQuizeTests";

// Python Quize Test
import IndexPythonQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/python_quizes_tests/IndexPythonQuizeTests";
import PythonQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/python_quizes_tests/PythonQuizeTest1";
import PythonQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/python_quizes_tests/PythonQuizeTest2";
import PythonQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/python_quizes_tests/PythonQuizeTest3";
import PythonQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/python_quizes_tests/PythonQuizeTest4";

// C Quize Test
import CQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/c_quizes_tests/CQuizeTest1";
import CQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/c_quizes_tests/CQuizeTest2";
import CQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/c_quizes_tests/CQuizeTest3";
import CQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/c_quizes_tests/CQuizeTest4";
import IndexCQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/c_quizes_tests/IndexCQuizeTests";

// java Quize Test
import IndexJavaQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/java_quizes_tests/IndexJavaQuizeTests";
import JavaQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/java_quizes_tests/JavaQuizeTest1";
import JavaQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/java_quizes_tests/JavaQuizeTest2";
import JavaQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/java_quizes_tests/JavaQuizeTest3";
import JavaQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/java_quizes_tests/JavaQuizeTest4";

// JS Quize Test
import IndexJSQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/js_quizes_tests/IndexJSQuizeTests";
import JSQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/js_quizes_tests/JSQuizeTest1";
import JSQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/js_quizes_tests/JSQuizeTest2";
import JSQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/js_quizes_tests/JSQuizeTest3";
import JSQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/js_quizes_tests/JSQuizeTest4";

// DataStructure Quize Test
import DSQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/ds_quizes_tests/DSQuizeTest1";
import DSQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/ds_quizes_tests/DSQuizeTest2";
import DSQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/ds_quizes_tests/DSQuizeTest3";
import DSQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/ds_quizes_tests/DSQuizeTest4";
import IndexDSQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/ds_quizes_tests/IndexDSQuizeTests";

// Html Quize Test
import HtmlQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/html_quizes_tests/HtmlQuizeTest1";
import HtmlQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/html_quizes_tests/HtmlQuizeTest2";
import HtmlQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/html_quizes_tests/HtmlQuizeTest3";
import HtmlQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/html_quizes_tests/HtmlQuizeTest4";
import IndexHtmlQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/html_quizes_tests/IndexHtmlQuizeTests";

// SQL Quize Test
import IndexSQLQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/sql_quizes_tests/IndexSQLQuizeTests";
import SQLQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/sql_quizes_tests/SQLQuizeTest1";
import SQLQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/sql_quizes_tests/SQLQuizeTest2";
import SQLQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/sql_quizes_tests/SQLQuizeTest3";
import SQLQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/sql_quizes_tests/SQLQuizeTest4";

// DBMS Quize Test
import DBMSQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/dbms_quizes_tests/DBMSQuizeTest1";
import DBMSQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/dbms_quizes_tests/DBMSQuizeTest2";
import DBMSQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/dbms_quizes_tests/DBMSQuizeTest3";
import DBMSQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/dbms_quizes_tests/DBMSQuizeTest4";
import IndexDBMSQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/dbms_quizes_tests/IndexDBMSQuizeTests";

// OOPs Quize Test
import IndexOOPsQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/oops_quizes_tests/IndexOOPsQuizeTests";
import OOPsQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/oops_quizes_tests/OOPsQuizeTest1";
import OOPsQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/oops_quizes_tests/OOPsQuizeTest2";
import OOPsQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/oops_quizes_tests/OOPsQuizeTest3";
import OOPsQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/oops_quizes_tests/OOPsQuizeTest4";

// Angular Quize Test
import AngularQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/angular_quizes_tests/AngularQuizeTest1";
import AngularQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/angular_quizes_tests/AngularQuizeTest2";
import AngularQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/angular_quizes_tests/AngularQuizeTest3";
import AngularQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/angular_quizes_tests/AngularQuizeTest4";
import IndexAngularQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/angular_quizes_tests/IndexAngularQuizeTests";

// React Quize Test
import IndexReactQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/react_quizes_tests/IndexReactQuizeTests";
import ReactQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/react_quizes_tests/ReactQuizeTest1";
import ReactQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/react_quizes_tests/ReactQuizeTest2";
import ReactQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/react_quizes_tests/ReactQuizeTest3";
import ReactQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/react_quizes_tests/ReactQuizeTest4";

// Android Quize Test
import AndroidQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/android_quizes_tests/AndroidQuizeTest1";
import AndroidQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/android_quizes_tests/AndroidQuizeTest2";
import AndroidQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/android_quizes_tests/AndroidQuizeTest3";
import AndroidQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/android_quizes_tests/AndroidQuizeTest4";
import IndexAndroidQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/android_quizes_tests/IndexAndroidQuizeTests";

// Django Quize Test
import DjangoQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/django_quizes_tests/DjangoQuizeTest1";
import DjangoQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/django_quizes_tests/DjangoQuizeTest2";
import DjangoQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/django_quizes_tests/DjangoQuizeTest3";
import DjangoQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/django_quizes_tests/DjangoQuizeTest4";
import IndexDjangoQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/django_quizes_tests/IndexDjangoQuizeTests";
// Bootstrap Quize Test
import BootstrapQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/bootstrap_quizes_tests/BootstrapQuizeTest1";
import BootstrapQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/bootstrap_quizes_tests/BootstrapQuizeTest2";
import BootstrapQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/bootstrap_quizes_tests/BootstrapQuizeTest3";
import BootstrapQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/bootstrap_quizes_tests/BootstrapQuizeTest4";
import IndexBootstrapQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/bootstrap_quizes_tests/IndexBootstrapQuizeTests";
// Git Quize Test
import GitQuizeTest1 from "../../../components/main_component/posts/mcqQuestions/quize_test/git_quizes_tests/GitQuizeTest1";
import GitQuizeTest2 from "../../../components/main_component/posts/mcqQuestions/quize_test/git_quizes_tests/GitQuizeTest2";
import GitQuizeTest3 from "../../../components/main_component/posts/mcqQuestions/quize_test/git_quizes_tests/GitQuizeTest3";
import GitQuizeTest4 from "../../../components/main_component/posts/mcqQuestions/quize_test/git_quizes_tests/GitQuizeTest4";
import IndexGitQuizeTests from "../../../components/main_component/posts/mcqQuestions/quize_test/git_quizes_tests/IndexGitQuizeTests";

// Index MCQ
import IndexMcqQuestions from "../../../components/main_component/posts/mcqQuestions/IndexMcqQuestions";
// Python MCQ Routing
import IndexPythonMCQ from "../../../components/main_component/posts/mcqQuestions/python_mcqs/IndexPythonMCQ";
import PythonMCQ1 from "../../../components/main_component/posts/mcqQuestions/python_mcqs/PythonMCQ";
import PythonMCQ2 from "../../../components/main_component/posts/mcqQuestions/python_mcqs/PythonMCQ2";
import PythonMCQ3 from "../../../components/main_component/posts/mcqQuestions/python_mcqs/PythonMCQ3";
import PythonMCQ4 from "../../../components/main_component/posts/mcqQuestions/python_mcqs/PythonMCQ4";
import PythonMCQ5 from "../../../components/main_component/posts/mcqQuestions/python_mcqs/PythonMCQ5";

// CMCQs Routing
import CMCQ1 from "../../../components/main_component/posts/mcqQuestions/c_mcqs/CMCQ1";
import CMCQ2 from "../../../components/main_component/posts/mcqQuestions/c_mcqs/CMCQ2";
import IndexCMCQ from "../../../components/main_component/posts/mcqQuestions/c_mcqs/IndexCMCQ";
// Java MCQ Routing
import IndexJavaMCQ from "../../../components/main_component/posts/mcqQuestions/java_mcqs/IndexJavaMCQ";
import JavaMCQ1 from "../../../components/main_component/posts/mcqQuestions/java_mcqs/JavaMCQ1";

// JavaScript MCQs
import IndexJSMCQ from "../../../components/main_component/posts/mcqQuestions/javascript_mcqs/IndexJSMCQ";
import JSMCQ1 from "../../../components/main_component/posts/mcqQuestions/javascript_mcqs/JSMCQ1";
import JSMCQ2 from "../../../components/main_component/posts/mcqQuestions/javascript_mcqs/JSMCQ2";
// React MCQs
import IndexReactMCQ from "../../../components/main_component/posts/mcqQuestions/react_mcqs/IndexReactMCQ";
import ReactMCQ1 from "../../../components/main_component/posts/mcqQuestions/react_mcqs/ReactMCQ1";
// Cpp Mcqs
import CppMCQ1 from "../../../components/main_component/posts/mcqQuestions/cpp_mcqs/CppMCQ1";
import IndexCppMCQ from "../../../components/main_component/posts/mcqQuestions/cpp_mcqs/IndexCppMCQ";
// Css Mcqs
import CssMCQ1 from "../../../components/main_component/posts/mcqQuestions/css_mcqs/CssMCQ1";
import IndexCssMCQ from "../../../components/main_component/posts/mcqQuestions/css_mcqs/IndexCssMCQ";
// Html Mcqs
import HtmlMCQ1 from "../../../components/main_component/posts/mcqQuestions/html_mcqs/HtmlMCQ1";
import IndexHtmlMCQ from "../../../components/main_component/posts/mcqQuestions/html_mcqs/IndexHtmlMCQ";
// Bootstrap MCqs
import BootStrapMCQ1 from "../../../components/main_component/posts/mcqQuestions/bootstrap_mcqs/BootStrapMCQ1";
import IndexBootstrapMCQ from "../../../components/main_component/posts/mcqQuestions/bootstrap_mcqs/IndexBootStrapMCQ";
// DBMS MCQs
import DBMSMCQ1 from "../../../components/main_component/posts/mcqQuestions/dbms_mcqs/DBMSMCQ1";
import IndexDBMSMCQ from "../../../components/main_component/posts/mcqQuestions/dbms_mcqs/IndexDBMSMCQ";
// Django MCQs
import DjangoMCQ1 from "../../../components/main_component/posts/mcqQuestions/django_mcqs/DjangoMCQ1";
import IndexDjangoMCQ from "../../../components/main_component/posts/mcqQuestions/django_mcqs/IndexDjangoMCQ";
// Git MCQs
import GitMCQ1 from "../../../components/main_component/posts/mcqQuestions/git_mcqs/GitMCQ1";
import IndexGitMCQ from "../../../components/main_component/posts/mcqQuestions/git_mcqs/IndexGitMCQ";
// DS MCQs
import DSMCQ1 from "../../../components/main_component/posts/mcqQuestions/ds_mcqs/DSMCQ1";
import IndexDSMCQ from "../../../components/main_component/posts/mcqQuestions/ds_mcqs/IndexDSMCQ";
// Android MCQs
import AndroidMCQ1 from "../../../components/main_component/posts/mcqQuestions/android_mcqs/AndroidMCQ1";
import IndexAndroidMCQ from "../../../components/main_component/posts/mcqQuestions/android_mcqs/IndexAndroidMCQ";
// SQL MCQs
import IndexSQLMCQ from "../../../components/main_component/posts/mcqQuestions/sql_mcqs/IndexSQLMCQ";
import SQLMCQ1 from "../../../components/main_component/posts/mcqQuestions/sql_mcqs/SQLMCQ1";
// OOPs MCQs
import IndexOOPsMCQ from "../../../components/main_component/posts/mcqQuestions/oops_mcqs/IndexOOPsMCQ";
import OOPsMCQ1 from "../../../components/main_component/posts/mcqQuestions/oops_mcqs/OOPsMCQ1";
// Angular MCQs
import AngularMCQ1 from "../../../components/main_component/posts/mcqQuestions/angular_mcqs/AngularMCQ1";
import IndexAngularMCQ from "../../../components/main_component/posts/mcqQuestions/angular_mcqs/IndexAngularMCQ";

// Technogies

// Index Programming Questions

import CPgramQuestions from "../../../components/main_component/posts/programming_questions/CPgramQuestions";
import DatastructurePgramQuestions from "../../../components/main_component/posts/programming_questions/DatastructurePgramQuestions";
import IndexProgrammingQuestions from "../../../components/main_component/posts/programming_questions/IndexProgrammingQuestions";
import JavaPgramQuestions from "../../../components/main_component/posts/programming_questions/JavaPgramQuestions";
import JavascriptPgramQuestions from "../../../components/main_component/posts/programming_questions/JavascriptPgramQuestions";
import PythonPgramQuestions from "../../../components/main_component/posts/programming_questions/PythonPgramQuestions";

// Index Evens
import IndexEvent from "../../../components/main_component/events/IndexEvent";
// index CodeChallange
import CodeChallangeWeek1 from "../../../components/main_component/code_challange/CodeChallangeWeek1";
import CodeChallangeWeek2 from "../../../components/main_component/code_challange/CodeChallangeWeek2";
import IndexCodeChallange from "../../../components/main_component/code_challange/IndexCodeChallange";
import EventGallary from "../../../components/main_component/events/EventGallary";
import PythonInterviewQuestions from "../../../components/main_component/posts/technical_interview_questions/PythonInterviewQuestions";

// Practice Question
import CPracticeQuestionsAdvance from "../../../components/main_component/posts/practice_questions/c_practice_questions/CPracticeQuestionsAdvance";
import CPracticeQuestionsBeginner from "../../../components/main_component/posts/practice_questions/c_practice_questions/CPracticeQuestionsBeginner";
import CPracticeQuestionsIntermediate from "../../../components/main_component/posts/practice_questions/c_practice_questions/CPracticeQuestionsIntermediate";
import IndexCPracticeQuestions from "../../../components/main_component/posts/practice_questions/c_practice_questions/IndexCPracticeQuestions";
import IndexPracticeQuestions from "../../../components/main_component/posts/practice_questions/IndexPracticeQuestions";

import IndexJavaPracticeQuestions from "../../../components/main_component/posts/practice_questions/java_practice_questions/IndexJavaPracticeQuestions";
import JavaPracticeQuestionsAdvance from "../../../components/main_component/posts/practice_questions/java_practice_questions/JavaPracticeQuestionsAdvance";
import JavaPracticeQuestionsBeginner from "../../../components/main_component/posts/practice_questions/java_practice_questions/JavaPracticeQuestionsBeginner";
import JavaPracticeQuestionsIntermediate from "../../../components/main_component/posts/practice_questions/java_practice_questions/JavaPracticeQuestionsIntermediate";

import IndexPythonPracticeQuestions from "../../../components/main_component/posts/practice_questions/python_practice_questions/IndexPythonPracticeQuestions";
import PythonPracticeQuestionsAdvance from "../../../components/main_component/posts/practice_questions/python_practice_questions/PythonPracticeQuestionsAdvance";
import PythonPracticeQuestionsBeginner from "../../../components/main_component/posts/practice_questions/python_practice_questions/PythonPracticeQuestionsBeginner";
import PythonPracticeQuestionsIntermediate from "../../../components/main_component/posts/practice_questions/python_practice_questions/PythonPracticeQuestionsIntermediate";

import DSPracticeQuestionsAdvance from "../../../components/main_component/posts/practice_questions/ds_practice_questions/DSPracticeQuestionsAdvance";
import DSPracticeQuestionsBeginner from "../../../components/main_component/posts/practice_questions/ds_practice_questions/DSPracticeQuestionsBeginner";
import DSPracticeQuestionsIntermediate from "../../../components/main_component/posts/practice_questions/ds_practice_questions/DSPracticeQuestionsIntermediate";
import IndexDSPracticeQuestions from "../../../components/main_component/posts/practice_questions/ds_practice_questions/IndexDSPracticeQuestions";

// Learning Platforms
import CertificationPlatforms from "../../../components/main_component/aticles/learning_platforms/CertificationPlatforms";
import ComputerSciencePlatforms from "../../../components/main_component/aticles/learning_platforms/ComputerSciencePlatforms";
import DataSciencePlatforms from "../../../components/main_component/aticles/learning_platforms/DataSciencePlatforms";
import IndexLearningPlatforms from "../../../components/main_component/aticles/learning_platforms/IndexLearningPlatforms";
import ProgrammingLearningPlatforms from "../../../components/main_component/aticles/learning_platforms/ProgrammingLearningPlatforms";
import ProgrammingPracticePlatforms from "../../../components/main_component/aticles/learning_platforms/ProgrammingPracticePlatforms";
import WebDevelopmentPlatforms from "../../../components/main_component/aticles/learning_platforms/WebDevelopmentPlatforms";

// TrainingCertification
import CppTrainingCertification from "../../../components/main_component/training_and_certification/CppTrainingCertification";
import CTrainingCertification from "../../../components/main_component/training_and_certification/CTrainingCertification";
import IndexTrainingCertification from "../../../components/main_component/training_and_certification/IndexTrainingCertification";
import PythonTrainingCertification from "../../../components/main_component/training_and_certification/PythonTrainingCertification";

import AndroidTrainingCertification from "../../../components/main_component/training_and_certification/AndroidTrainingCertification";
import AngularTrainingCertification from "../../../components/main_component/training_and_certification/AngularTrainingCertification";
import ComputerScienceTrainingCertification from "../../../components/main_component/training_and_certification/ComputerScienceTrainingCertification";
import DataScienceTrainingCertification from "../../../components/main_component/training_and_certification/DataScienceTrainingCertification";
import DataStructureTrainingCertification from "../../../components/main_component/training_and_certification/DataStructureTrainingCertification";
import DBMSTrainingCertification from "../../../components/main_component/training_and_certification/DBMSTrainingCertification";
import GithubTrainingCertification from "../../../components/main_component/training_and_certification/GithubTrainingCertification";
import HTMLTrainingCertification from "../../../components/main_component/training_and_certification/HTMLTrainingCertification";
import JavaTrainingCertification from "../../../components/main_component/training_and_certification/JavaTrainingCertification";
import JSTrainingCertification from "../../../components/main_component/training_and_certification/JSTrainingCertification";
import MachineLearningTrainingCertification from "../../../components/main_component/training_and_certification/MachineLearningTrainingCertification";
import NodeJsTrainingCertification from "../../../components/main_component/training_and_certification/NodeJsTrainingCertification";
import OOPsTrainingCertification from "../../../components/main_component/training_and_certification/OOPsTrainingCertification";
import PHPTrainingCertification from "../../../components/main_component/training_and_certification/PHPTrainingCertification";
import ReactJSTrainingCertification from "../../../components/main_component/training_and_certification/ReactTrainingCertification";
import SQLTrainingCertification from "../../../components/main_component/training_and_certification/SQLTrainingCertification";
import SwiftTrainingCertification from "../../../components/main_component/training_and_certification/SwiftTrainingCertification";
import WebdevelopmentTrainingCertification from "../../../components/main_component/training_and_certification/WebdevelopmentTrainingCertification";

// Video
import IndexVideo from "../../../components/main_component/video/IndexVideo";

// RGPV MCQs LINKS
import ARVR from "../../../components/main_component/rgpv/ARVR";
import CloudComputing from "../../../components/main_component/rgpv/CloudComputing";
import IndexRgpv from "../../../components/main_component/rgpv/IndexRgpv";
import MachineLearning from "../../../components/main_component/rgpv/MachineLearning";
// Quiz Exam Board

// Email Templates
import MockCodingTestLink from "../../../components/main_component/email_template/mock_drive/MockCodingTestLink";
import MockCodingTestReport from "../../../components/main_component/email_template/mock_drive/MockCodingTestReport";
import MockDriveInvitationMail from "../../../components/main_component/email_template/mock_drive/MockDriveInvitationMail";
import MockDriveRegConfirmation from "../../../components/main_component/email_template/mock_drive/MockDriveRegConfirmation";
import MockInterviewSlot from "../../../components/main_component/email_template/mock_drive/MockInterviewSlot";
import MockPlacementTestLink from "../../../components/main_component/email_template/mock_drive/MockPlacementTestLink";
import MockPlacementTestReport from "../../../components/main_component/email_template/mock_drive/MockPlacementTestReport";

// Quiz Board and USer.........
import ContributorHome from "../../../components/quiz_board/component/contributor/contributor_home";
import CreateQuizOverview from "../../../components/quiz_board/component/contributor/create_quiz_test/overview";
import CreateQuestion from "../../../components/quiz_board/component/contributor/create_quiz_test/questions";
import CreateQuizSettings from "../../../components/quiz_board/component/contributor/create_quiz_test/settings";
import QuizBoardHome from "../../../components/quiz_board/component/QuizBoardHome";
import Confirmation from "../../../components/user/component/Confirmation";
import ForgotPassword from "../../../components/user/component/forgot_password";
import Login from "../../../components/user/component/login";
import NotFound from "../../../components/user/component/NotFound";
import ResetPassword from "../../../components/user/component/reset_password";
import Signup from "../../../components/user/component/signup";
import SocialMediaLinks from "../../../components/user/component/social_media/SocialMediaLinks";
import SpeakerRegistration from "../../../components/user/component/speaker_registration";
// Contributor --- Tutorial
import AddTutorial from "../../../components/main_component/tutorials/components/AddTutorial";
import IndexTechnologies from "../../../components/main_component/tutorials/components/Tutorials/IndexTutorial";
import TechTutorials from "../../../components/main_component/tutorials/components/Tutorials/TechTutorials";
import TutorialPage from "../../../components/main_component/tutorials/components/Tutorials/TutorialPage";
// Profile
import EditProfile from "../../../components/user/component/profile/edit_profile";
import MyLibrary from "../../../components/user/component/profile/my_library";
import MyProfile from "../../../components/user/component/profile/my_profile";
// --------------------------------------------------------------------

import UsersList from "../../../components/user/component/admin/UsersList";

// Contributor ------
import ContributorLanding from "../../../components/contributor";

// Blogs Text Editor  --------------
import TextEditor from "../../../components/main_component/aticles/BlogsEditor/TextEditor";

// New Event
import CreateEventPage from "../../../components/main_component/events/components/CreateEvent/CreateEventPage";
import EventDashboard from "../../../components/main_component/events/components/EventDashboard/EventDashboard";
import EventDashboardPage from "../../../components/main_component/events/components/EventDashboard/EventDashboardPage";
import RegisterList from "../../../components/main_component/events/components/EventDashboard/RegisterList";
import EventPage from "../../../components/main_component/events/components/EventPage/EventPage";
import GettingStartedWebDev from "../../../components/main_component/events/components/EventPage/GettingStartedWebDev";
import EventsList from "../../../components/main_component/events/components/EventsList";
import MyRegEvents from "../../../components/main_component/events/components/EventsList/MyRegEvents";

// 30DaysCode
import DaysCodeTimeLine from "../../../components/main_component/DaysCode/components/DaysCodeDashboard/DaysCodeTimeLine";
import AddQuizChallenge from "../../../components/main_component/DaysCode/components/Settings/Challenge/AddQuizChallenge";
import QuizChallengesList from "../../../components/main_component/DaysCode/components/Settings/Challenge/QuizChallengesList";
import DaysCodeBatches from "../../../components/main_component/DaysCode/components/Settings/DaysCodeBatch/DaysCodeBatches";
import DaysCodeBatchPage from "../../../components/main_component/DaysCode/components/Settings/DaysCodeBatch/DaysCodeBatchPage";
import FeedBacks from "../../../components/main_component/DaysCode/components/Settings/FeedBacks";
import AddLesson from "../../../components/main_component/DaysCode/components/Settings/Lesson/AddLesson";
import LessonsList from "../../../components/main_component/DaysCode/components/Settings/Lesson/LessonsList";
import CreateMentor from "../../../components/main_component/DaysCode/components/Settings/Mentor/CreateMentor";
import MentorsList from "../../../components/main_component/DaysCode/components/Settings/Mentor/MentorsList";
import Notification from "../../../components/main_component/DaysCode/components/Settings/Notification";
import AddProblem from "../../../components/main_component/DaysCode/components/Settings/Problems/AddProblem";
import ProblemsList from "../../../components/main_component/DaysCode/components/Settings/Problems/ProblemsList";
import SubmissionsList from "../../../components/main_component/DaysCode/components/Settings/Submission/SubmissionsList";
import AddUserAttendance from "../../../components/main_component/DaysCode/components/Settings/UserAttendance/AddUserAttendance";
import UserAttendanceList from "../../../components/main_component/DaysCode/components/Settings/UserAttendance/UserAttendanceList";
import DaysUsersList from "../../../components/main_component/DaysCode/components/Settings/UsersList";
import AddInterviewQuestions from "../../../components/main_component/Placement/components/InterviewQuestions/AddInterviewQuestions";
import InterviewQuestions from "../../../components/main_component/Placement/components/InterviewQuestions/InterviewQuestions";
import SuperAdmin from "../../../components/superadmin/SuperAdmin";
import CustomLoader from "../../../shared_components/CustomLoader";
// import UserSubmissions from "../../../components/main_component/DaysCode/components/Settings/Submission/UserSubmissions";

import ProblemPreview from "../../../components/main_component/DaysCode/components/Settings/Problems/ProblemPreview";
import UsersProgress from "../../../components/main_component/DaysCode/components/Settings/Submission/UsersProgress";
import PageBreak from "../../../components/user/component/PageBreak";

// Club
import Applications from "../../../components/superadmin/Club/components/Applications";
import ClupApplicationView from "../../../components/superadmin/Club/components/ClupApplicationView";
import ClubApplication from "../../../components/superadmin/Club/components/StudentClubPage/ClubApplication/ClubApplication";
import StudentLearningClubLanding from "../../../components/superadmin/Club/components/StudentClubPage/StudentLearningClubLandingPage";

// Datacode Academy
import AcademyLandingPage from "../../../components/main_component/DatacodeAcademy/components/AcademyLandingPage/AcademyLandingPage";
import WorkshopLandingPage from "../../../components/main_component/DatacodeAcademy/components/Workshop/WorkshopLandingPage";
import WorkshopPage from "../../../components/main_component/DatacodeAcademy/components/Workshop/WorkshopPage/WorkshopPage";
import CreateCodeBatch from "../../../components/main_component/DaysCode/components/Settings/DaysCodeBatch/CreateCodeBatch";

// Appointment Book
import { useDispatch } from "react-redux";
import UserAppointment from "../../../components/main_component/AppointmentBook/components/UserAppointment";
import EventRegistration from "../../../components/main_component/events/components/EventPage/EventRegistration";
import EventFeedbackForm from "../../../components/main_component/events/components/EventPage/EventUserAction/EventFeedbackForm";
import RoleForm from "../../../components/superadmin/RoleForm";
import PrivateRoute from "./PrivateRoute";
import InternshipProgram from "../../../components/main_component/Community/components/Carrer";
import BlogDashBoard from "../../../components/main_component/Blog/BlogDashBoard";
import LessonPreview from "../../../components/main_component/DaysCode/components/DaysCodePage/ContentSections/LessonSection/LessonPreview";
import BlogPreview from "../../../components/main_component/Blog/BlogPreview";
import AiSummit from "../../../components/main_component/events/components/EventPage/AiSummit/AiSummit";
import TechnologyQuestions from "../../../components/main_component/posts/technical_interview_questions/TechnologyQuestions";
import Bootcamp from "../../../components/main_component/Bootcamp";

// Community
const Community = lazy(() =>
  import("../../../components/main_component/Community/components/Community")
);
// Sagethon
const SagethonPage = lazy(() =>
  import("../../../components/main_component/Sagethon/components/SagethonPage")
);

// FreeCodeClass
const FreeCodeClassPage = lazy(() =>
  import(
    "../../../components/main_component/FreeCodeClass/components/FreeCodeClassPage"
  )
);

// Community
const Wittyhacks = lazy(() =>
  import("../../../components/main_component/Wittyhacks/Wittyhacks")
);

const DaysCodeDashboard = lazy(() =>
  import(
    "../../../components/main_component/DaysCode/components/DaysCodeDashboard"
  )
);
const DaysCode = lazy(() =>
  import("../../../components/main_component/DaysCode/components/DaysCode")
);
const DaysCodePage = lazy(() =>
  import("../../../components/main_component/DaysCode/components/DaysCodePage")
);
const UserBatchDashboard = lazy(() =>
  import(
    "../../../components/main_component/DaysCode/components/Batch/UserBatchesDashboard"
  )
);
const EnrollBatch = lazy(() =>
  import(
    "../../../components/main_component/DaysCode/components/Batch/BatchPage/EnrollBatch"
  )
);
const BatchPage = lazy(() =>
  import(
    "../../../components/main_component/DaysCode/components/Batch/BatchPage"
  )
);

const AppRouters = () => {
  const dispatch = useDispatch();
  const batchMentors = DatacodeConfig.batchMentors || [];
  // const { daysUserDetails, daysUserDetailsLoading } =
  //   useSelector(({ dayscode }) => dayscode) || {};

  // const { currentUser } = useSelector(({ user }) => user);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    var localUser = JSON.parse(localStorage.getItem("userDetails"));
    let token = localStorage.getItem("access_token");
    let batchParticipant = JSON.parse(localStorage.getItem("batchParticipant"));
    let codeBatchDetails = JSON.parse(localStorage.getItem("batchDetails"));
    if (localUser && localUser !== null) {
      dispatch(
        setLocalUser({
          data: localUser,
          token,
          batchParticipant,
          codeBatchDetails,
        })
      );
    }
  }, []);

  const authGuard = (Component) => () => {
    var localUser = JSON.parse(localStorage.getItem("userDetails"));
    return localUser && localUser.userName ? (
      <Component userName={localUser.userName} />
    ) : (
      <Redirect to="/login" />
    );
  };

  const authAdminGuard = (Component) => () => {
    var localUser = JSON.parse(localStorage.getItem("userDetails"));
    return localUser &&
      localUser.userName === process.env.REACT_APP_ADMIN_USERNAME &&
      localUser.email === process.env.REACT_APP_ADMIN_EMAIL ? (
      <Component userName={localUser.userName} />
    ) : (
      <Redirect to="/" />
    );
  };

  const authMentorGuard = (Component) => () => {
    var localUser = JSON.parse(localStorage.getItem("userDetails"));
    return localUser &&
      batchMentors.find(
        (x) => x.email === localUser.email && x.userName === localUser.userName
      ) ? (
      <Component userName={localUser.userName} />
    ) : (
      <Redirect to="/" />
    );
  };

  // const authDaysCodeGuard = (Component1, Component2) => () => {
  //   var localUser = JSON.parse(localStorage.getItem("userDetails"));
  //   return localUser && localUser.dayscodeUser ? (
  //     <Component2 />
  //   ) : (
  //     <Component1 />
  //   );
  // };

  return (
    <>
      <Suspense fallback={<CustomLoader />}>
        <Switch>
          {/* Navigarion Routing */}

          {/* <Route path='/home' component={HomeComponent} /> */}
          <Route path="/our_story" component={OurStory} />
          <Route path="/contact_us" component={ContactUs} />
          <Route path="/our_partners" component={OurPartners} />
          {/* End ______________ */}
          {/* Starting Articles */}
          <Route path="/index_articles" component={IndexArticles} />
          <Route path="/prog_lan_blog" component={ProgrammingLanguagesBlog} />
          <Route path="/prog_lan_blog2" component={ProgrammingBlog2} />
          <Route path="/prog_lan_blog3" component={ProgrammingBlog3} />
          <Route path="/prog_lan_blog4" component={ProgrammingBlog4} />
          <Route path="/prog_lan_blog5" component={ProgrammingBlog5} />
          <Route path="/prog_lan_blog6" component={ProgrammingBlog6} />
          <Route path="/prog_lan_blog7" component={ProgrammingBlog7} />
          <Route path="/prog_lan_blog8" component={ProgrammingBlog8} />
          <Route path="/prog_lan_blog9" component={ProgrammingBlog9} />
          {/* Blogs */}
          <Route path="/blogs" component={BlogDashBoard} />
          <Route path="/blog/preview/:id" component={BlogPreview} />

          {/* Web Blog */}
          <Route path="/webdesign_blog" component={WebDesignBlog} />
          <Route path="/webdesign_blog2" component={WebDesignBlog2} />
          <Route path="/webdesign_blog3" component={WebDesignBlog3} />
          <Route path="/webdesign_blog4" component={WebDesignBlog4} />
          <Route path="/webdesign_blog5" component={WebDesignBlog5} />
          <Route path="/webdesign_blog6" component={WebDesignBlog6} />
          <Route path="/webdesign_blog7" component={WebDesignBlog7} />
          {/*Machine BLogs  */}
          <Route
            path="/machine_learning_blog"
            component={MachineLearningBlog}
          />
          {/* DBMS Blogs */}
          <Route path="/dbms_blog" component={DBMSBlog} />
          <Route path="/dbms_blog2" component={DBMSBlog2} />
          <Route path="/dbms_blog3" component={DBMSBlog3} />
          {/* Documentation Blog */}
          <Route path="/documentation_blog" component={DocumentationBlog} />
          <Route path="/documentation_blog2" component={DocumentationBlog2} />
          <Route path="/documentation_blog3" component={DocumentationBlog3} />
          <Route path="/documentation_blog4" component={DocumentationBlog4} />
          {/* Mock Drive Article */}
          <Route path="/mock_drive" component={MockDriveArticle} />
          {/* Ending Article */}
          {/* Starting Index MCQs */}
          <Route exact path="/index_mcqs" component={IndexMcqQuestions} />
          {/* Starting Python MCQs */}
          <Route exact path="/index_python_mcqs" component={IndexPythonMCQ} />
          <Route exact path="/python_mcq_1" component={PythonMCQ1} />
          <Route exact path="/python_mcq_2" component={PythonMCQ2} />
          <Route exact path="/python_mcq_3" component={PythonMCQ3} />
          <Route exact path="/python_mcq_4" component={PythonMCQ4} />
          <Route exact path="/python_mcq_5" component={PythonMCQ5} />
          {/* Ending Python MCQS */}
          {/* Starting C MCQs */}
          <Route exact path="/index_c_mcqs" component={IndexCMCQ} />
          <Route exact path="/c_mcq_1" component={CMCQ1} />
          <Route exact path="/c_mcq_2" component={CMCQ2} />
          {/* Ending C MCQs */}
          {/* Starting Java MCQs */}
          <Route exact path="/index_java_mcqs" component={IndexJavaMCQ} />
          <Route exact path="/java_mcq_1" component={JavaMCQ1} />
          {/* Ending Java MCQS */}
          {/* Starting JavaScript MCQs */}
          <Route exact path="/index_js_mcqs" component={IndexJSMCQ} />
          <Route exact path="/js_mcq_1" component={JSMCQ1} />
          <Route exact path="/js_mcq_2" component={JSMCQ2} />
          {/* Ending JavaScript MCQS */}
          {/* Starting Cpp MCQs */}
          <Route exact path="/index_cpp_mcqs" component={IndexCppMCQ} />
          <Route exact path="/cpp_mcq_1" component={CppMCQ1} />
          {/* Ending  MCQS */}
          {/* Starting Css MCQs */}
          <Route exact path="/index_css_mcqs" component={IndexCssMCQ} />
          <Route exact path="/css_mcq_1" component={CssMCQ1} />
          {/* Ending Css MCQS */}
          {/* Starting Html MCQs */}
          <Route exact path="/index_html_mcqs" component={IndexHtmlMCQ} />
          <Route exact path="/html_mcq_1" component={HtmlMCQ1} />
          {/* Ending  Html MCQS */}
          {/* Starting BootStrap MCQs */}
          <Route
            exact
            path="/index_bootstrap_mcqs"
            component={IndexBootstrapMCQ}
          />
          <Route exact path="/bootstrap_mcq_1" component={BootStrapMCQ1} />
          {/* Ending BootStrap MCQS */}
          {/* Starting Dbms MCQs */}
          <Route exact path="/index_dbms_mcqs" component={IndexDBMSMCQ} />
          <Route exact path="/dbms_mcq_1" component={DBMSMCQ1} />
          {/* Ending DBMS MCQS */}
          {/* Starting Django MCQs */}
          <Route exact path="/index_django_mcqs" component={IndexDjangoMCQ} />
          <Route exact path="/django_mcq_1" component={DjangoMCQ1} />
          {/* Ending Django MCQS */}
          {/* Starting Git MCQs */}
          <Route exact path="/index_git_mcqs" component={IndexGitMCQ} />
          <Route exact path="/git_mcq_1" component={GitMCQ1} />
          {/* Ending Git MCQS */}
          {/* Starting React MCQs */}
          <Route exact path="/index_react_mcqs" component={IndexReactMCQ} />
          <Route exact path="/react_mcq_1" component={ReactMCQ1} />
          {/* Ending React MCQS */}
          {/* Starting DS MCQs */}
          <Route exact path="/index_ds_mcqs" component={IndexDSMCQ} />
          <Route exact path="/ds_mcq_1" component={DSMCQ1} />
          {/* Ending DS MCQS */}
          {/* Starting Android MCQs */}
          <Route exact path="/index_android_mcqs" component={IndexAndroidMCQ} />
          <Route exact path="/android_mcq_1" component={AndroidMCQ1} />
          {/* Ending Android MCQS */}
          {/* Starting SQL  MCQs */}
          <Route exact path="/index_sql_mcqs" component={IndexSQLMCQ} />
          <Route exact path="/sql_mcq_1" component={SQLMCQ1} />
          {/* Ending SQL MCQS */}
          {/* Starting OOPs  MCQs */}
          <Route exact path="/index_oops_mcqs" component={IndexOOPsMCQ} />
          <Route exact path="/oops_mcq_1" component={OOPsMCQ1} />
          {/* Ending OOPs MCQS */}
          {/* Starting angular  MCQs */}
          <Route exact path="/index_angular_mcqs" component={IndexAngularMCQ} />
          <Route exact path="/angular_mcq_1" component={AngularMCQ1} />
          {/* Ending angular MCQS */}
          {/* Index Quize Tests Routing */}
          <Route exact path="/index_quize_tests" component={IndexQuizeTests} />
          {/* Ending Index Quize Tests Routing */}
          {/* C Quize Test Routing */}
          <Route exact path="/c_quize_tests" component={IndexCQuizeTests} />
          <Route exact path="/c_quize_test_1" component={CQuizeTest1} />
          <Route exact path="/c_quize_test_2" component={CQuizeTest2} />
          <Route exact path="/c_quize_test_3" component={CQuizeTest3} />
          <Route exact path="/c_quize_test_4" component={CQuizeTest4} />
          {/* Ending  C Quize Test Routing */}
          {/* Python Quize Test Routing */}
          <Route
            exact
            path="/python_quize_tests"
            component={IndexPythonQuizeTests}
          />
          <Route
            exact
            path="/python_quize_test_1"
            component={PythonQuizeTest1}
          />
          <Route
            exact
            path="/python_quize_test_2"
            component={PythonQuizeTest2}
          />
          <Route
            exact
            path="/python_quize_test_3"
            component={PythonQuizeTest3}
          />
          <Route
            exact
            path="/python_quize_test_4"
            component={PythonQuizeTest4}
          />
          {/* Enging Python Quize Test Routing */}
          {/*Android  Quize Test Routing */}
          <Route
            exact
            path="/android_quize_tests"
            component={IndexAndroidQuizeTests}
          />
          <Route
            exact
            path="/android_quize_test_1"
            component={AndroidQuizeTest1}
          />
          <Route
            exact
            path="/android_quize_test_2"
            component={AndroidQuizeTest2}
          />
          <Route
            exact
            path="/android_quize_test_3"
            component={AndroidQuizeTest3}
          />
          <Route
            exact
            path="/android_quize_test_4"
            component={AndroidQuizeTest4}
          />
          {/*Angular  Quize Test Routing */}
          <Route
            exact
            path="/angular_quize_tests"
            component={IndexAngularQuizeTests}
          />
          <Route
            exact
            path="/angular_quize_test_1"
            component={AngularQuizeTest1}
          />
          <Route
            exact
            path="/angular_quize_test_2"
            component={AngularQuizeTest2}
          />
          <Route
            exact
            path="/angular_quize_test_3"
            component={AngularQuizeTest3}
          />
          <Route
            exact
            path="/angular_quize_test_4"
            component={AngularQuizeTest4}
          />
          {/*React  Quize Test Routing */}
          <Route
            exact
            path="/react_quize_tests"
            component={IndexReactQuizeTests}
          />
          <Route exact path="/react_quize_test_1" component={ReactQuizeTest1} />
          <Route exact path="/react_quize_test_2" component={ReactQuizeTest2} />
          <Route exact path="/react_quize_test_3" component={ReactQuizeTest3} />
          <Route exact path="/react_quize_test_4" component={ReactQuizeTest4} />
          {/*SQL  Quize Test Routing */}
          <Route exact path="/sql_quize_tests" component={IndexSQLQuizeTests} />
          <Route exact path="/sql_quize_test_1" component={SQLQuizeTest1} />
          <Route exact path="/sql_quize_test_2" component={SQLQuizeTest2} />
          <Route exact path="/sql_quize_test_3" component={SQLQuizeTest3} />
          <Route exact path="/sql_quize_test_4" component={SQLQuizeTest4} />
          {/*OOPs  Quize Test Routing */}
          <Route
            exact
            path="/oops_quize_tests"
            component={IndexOOPsQuizeTests}
          />
          <Route exact path="/oops_quize_test_1" component={OOPsQuizeTest1} />
          <Route exact path="/oops_quize_test_2" component={OOPsQuizeTest2} />
          <Route exact path="/oops_quize_test_3" component={OOPsQuizeTest3} />
          <Route exact path="/oops_quize_test_4" component={OOPsQuizeTest4} />
          {/*JavaScript  Quize Test Routing */}
          <Route exact path="/js_quize_tests" component={IndexJSQuizeTests} />
          <Route exact path="/js_quize_test_1" component={JSQuizeTest1} />
          <Route exact path="/js_quize_test_2" component={JSQuizeTest2} />
          <Route exact path="/js_quize_test_3" component={JSQuizeTest3} />
          <Route exact path="/js_quize_test_4" component={JSQuizeTest4} />
          {/*Java  Quize Test Routing */}
          <Route
            exact
            path="/java_quize_tests"
            component={IndexJavaQuizeTests}
          />
          <Route exact path="/java_quize_test_1" component={JavaQuizeTest1} />
          <Route exact path="/java_quize_test_2" component={JavaQuizeTest2} />
          <Route exact path="/java_quize_test_3" component={JavaQuizeTest3} />
          <Route exact path="/java_quize_test_4" component={JavaQuizeTest4} />
          {/*html  Quize Test Routing */}
          <Route
            exact
            path="/html_quize_tests"
            component={IndexHtmlQuizeTests}
          />
          <Route exact path="/html_quize_test_1" component={HtmlQuizeTest1} />
          <Route exact path="/html_quize_test_2" component={HtmlQuizeTest2} />
          <Route exact path="/html_quize_test_3" component={HtmlQuizeTest3} />
          <Route exact path="/html_quize_test_4" component={HtmlQuizeTest4} />
          {/*ds  Quize Test Routing */}
          <Route exact path="/ds_quize_tests" component={IndexDSQuizeTests} />
          <Route exact path="/ds_quize_test_1" component={DSQuizeTest1} />
          <Route exact path="/ds_quize_test_2" component={DSQuizeTest2} />
          <Route exact path="/ds_quize_test_3" component={DSQuizeTest3} />
          <Route exact path="/ds_quize_test_4" component={DSQuizeTest4} />
          {/*dbms  Quize Test Routing */}
          <Route
            exact
            path="/dbms_quize_tests"
            component={IndexDBMSQuizeTests}
          />
          <Route exact path="/dbms_quize_test_1" component={DBMSQuizeTest1} />
          <Route exact path="/dbms_quize_test_2" component={DBMSQuizeTest2} />
          <Route exact path="/dbms_quize_test_3" component={DBMSQuizeTest3} />
          <Route exact path="/dbms_quize_test_4" component={DBMSQuizeTest4} />
          {/*bootstrap  Quize Test Routing */}
          <Route
            exact
            path="/bootstrap_quize_tests"
            component={IndexBootstrapQuizeTests}
          />
          <Route
            exact
            path="/bootstrap_quize_test_1"
            component={BootstrapQuizeTest1}
          />
          <Route
            exact
            path="/bootstrap_quize_test_2"
            component={BootstrapQuizeTest2}
          />
          <Route
            exact
            path="/bootstrap_quize_test_3"
            component={BootstrapQuizeTest3}
          />
          <Route
            exact
            path="/bootstrap_quize_test_4"
            component={BootstrapQuizeTest4}
          />
          {/*Django  Quize Test Routing */}
          <Route
            exact
            path="/django_quize_tests"
            component={IndexDjangoQuizeTests}
          />
          <Route
            exact
            path="/django_quize_test_1"
            component={DjangoQuizeTest1}
          />
          <Route
            exact
            path="/django_quize_test_2"
            component={DjangoQuizeTest2}
          />
          <Route
            exact
            path="/django_quize_test_3"
            component={DjangoQuizeTest3}
          />
          <Route
            exact
            path="/django_quize_test_4"
            component={DjangoQuizeTest4}
          />
          {/*Git  Quize Test Routing */}
          <Route exact path="/Git_quize_tests" component={IndexGitQuizeTests} />
          <Route exact path="/git_quize_test_1" component={GitQuizeTest1} />
          <Route exact path="/git_quize_test_2" component={GitQuizeTest2} />
          <Route exact path="/git_quize_test_3" component={GitQuizeTest3} />
          <Route exact path="/git_quize_test_4" component={GitQuizeTest4} />
          {/* Starting Interview Routing */}
          <Route
            exact
            path="/index_interview_questioins"
            component={IndexInterviewQuestions}
          />
          <Route
            path="/interview_question/:tech"
            component={TechnologyQuestions}
          />
          {/* <Route
            path='/dbms_interview_question'
            component={DBMSInterviewQuestions}
          />
          <Route
            path='/oops_interview_question'
            component={OOPsInterviewQuestions}
          />
          <Route
            path='/python_interview_question'
            component={PythonInterviewQuestions}
          />
          <Route path='/c_interview_question' component={CInterviewQuestions} />
          <Route
            path='/cpp_interview_question'
            component={CppInterviewQuestions}
          />
          <Route
            path='/cloud_computing_interview_question'
            component={CloudComputingInterviewQuestions}
          />
          <Route
            path='/cn_interview_question'
            component={CNInterviewQuestions}
          />
          <Route
            path='/datastructre_interview_question'
            component={DataStructureInterviewQuestions}
          />
          <Route
            path='/java_interview_question'
            component={JavaInterviewQuestions}
          />
          <Route
            path='/os_interview_question'
            component={OSInterviewQuestions}
          />
          <Route
            path='/rdbms_interview_question'
            component={RDBMSInterviewQuestions}
          />
          <Route
            path='/sdlc_interview_question'
            component={SDLCInterviewQuestions}
          />
          <Route
            path='/sql_interview_question'
            component={SQLInterviewQuestions}
          />
          <Route
            path='/testing_interview_question'
            component={TestingInterviewQuestions}
          />
          <Route
            path='/html_interview_question'
            component={HtmlInterviewQuestions}
          />
          <Route
            path='/js_interview_question'
            component={JSInterviewQuestions}
          />
          <Route
            path='/angular_interview_question'
            component={AngularInterviewQuestions}
          />
          <Route
            path='/react_interview_question'
            component={ReactInterviewQuestions}
          />
          <Route
            path='/android_interview_question'
            component={AndroidInterviewQuestions}
          />
          <Route
            path='/bootstrap_interview_question'
            component={BootstrapInterviewQuestions}
          />
          <Route
            path='/django_interview_question'
            component={DjangoInterviewQuestions}
          /> */}
          {/* Einding Interview Routing */}
          {/* Book Routing */}
          <Route exact path="/index_books" component={IndexBooks} />
          <Route exact path="/python_books" component={PythonBooks} />
          {/* Ending Book Routing */}
          {/* Starting Technologies */}
          <Route exact path="/tutorials" component={IndexTechnologies} />
          {/* Ending Technologies */}
          {/* Starting TrainingCertification */}
          <Route
            exact
            path="/index_training_certification"
            component={IndexTrainingCertification}
          />
          <Route
            exact
            path="/datascience_training_certification"
            component={DataScienceTrainingCertification}
          />
          <Route
            exact
            path="/computerscience_training_certification"
            component={ComputerScienceTrainingCertification}
          />
          <Route
            exact
            path="/machinelearning_training_certification"
            component={MachineLearningTrainingCertification}
          />
          <Route
            exact
            path="/webdevelopment_training_certification"
            component={WebdevelopmentTrainingCertification}
          />
          <Route
            exact
            path="/swift_training_certification"
            component={SwiftTrainingCertification}
          />
          <Route
            exact
            path="/python_training_certification"
            component={PythonTrainingCertification}
          />
          <Route
            exact
            path="/c_training_certification"
            component={CTrainingCertification}
          />
          <Route
            exact
            path="/cpp_training_certification"
            component={CppTrainingCertification}
          />
          <Route
            exact
            path="/java_training_certification"
            component={JavaTrainingCertification}
          />
          <Route
            exact
            path="/js_training_certification"
            component={JSTrainingCertification}
          />
          <Route
            exact
            path="/datastructure_training_certification"
            component={DataStructureTrainingCertification}
          />
          <Route
            exact
            path="/html_training_certification"
            component={HTMLTrainingCertification}
          />
          <Route
            exact
            path="/dbms_training_certification"
            component={DBMSTrainingCertification}
          />
          <Route
            exact
            path="/sql_training_certification"
            component={SQLTrainingCertification}
          />
          <Route
            exact
            path="/oops_training_certification"
            component={OOPsTrainingCertification}
          />
          <Route
            exact
            path="/angular_training_certification"
            component={AngularTrainingCertification}
          />
          <Route
            exact
            path="/react_training_certification"
            component={ReactJSTrainingCertification}
          />
          <Route
            exact
            path="/android_training_certification"
            component={AndroidTrainingCertification}
          />
          <Route
            exact
            path="/nodejs_training_certification"
            component={NodeJsTrainingCertification}
          />
          <Route
            exact
            path="/php_training_certification"
            component={PHPTrainingCertification}
          />
          <Route
            exact
            path="/github_training_certification"
            component={GithubTrainingCertification}
          />
          {/* Ending TrainingCertification */}
          {/* Starting Programming Questions */}
          <Route
            exact
            path="/index_programming_questions"
            component={IndexProgrammingQuestions}
          />
          <Route
            exact
            path="/python_programming_questions"
            component={PythonPgramQuestions}
          />
          <Route
            exact
            path="/java_programming_questions"
            component={JavaPgramQuestions}
          />
          <Route
            exact
            path="/c_programming_questions"
            component={CPgramQuestions}
          />
          <Route
            exact
            path="/javascript_programming_questions"
            component={JavascriptPgramQuestions}
          />
          <Route
            exact
            path="/datastructure_programming_questions"
            component={DatastructurePgramQuestions}
          />
          {/* Ending Programming Questions  */}
          {/* Starting Event PAge */}
          <Route exact path="/index_events" component={IndexEvent} />
          <Route exact path="/events_gallary" component={EventGallary} />
          <Route
            exact
            path="/event_register"
            component={GettingStartedWebDev}
          />
          <Route
            exact
            path="/event_register_list"
            render={authAdminGuard(RegisterList)}
          />
          <Route exact path="/events" component={EventsList} />
          <Route
            exact
            path="/create_event"
            render={authGuard(CreateEventPage)}
          />
          <Route
            exact
            path="/event/dashboard"
            render={authAdminGuard(EventDashboardPage)}
          />
          <Route
            exact
            path="/event/dashboard/:id"
            render={authAdminGuard(EventDashboard)}
          />
          <Route exact path="/event/:id" component={EventPage} />
          {/* type === register || feedback */}
          <Route
            exact
            path="/event/:id/register"
            component={EventRegistration}
          />
          <Route
            exact
            path="/event/:id/feedback"
            component={EventFeedbackForm}
          />
          <Route exact path="/events/me" render={authGuard(MyRegEvents)} />
          {/* Ending Event PAge */}

          {/* Starting AiSummit Page */}
          <Route exact path="/aisummit" component={AiSummit} />
          <Route exact path="/aisummit/rsvp" component={AiSummit} />
          {/* Starting COde Challange PAge */}
          <Route
            exact
            path="/index_code_challange"
            component={IndexCodeChallange}
          />
          <Route
            exact
            path="/code_challange_week_1"
            component={CodeChallangeWeek1}
          />
          <Route
            exact
            path="/code_challange_week_2"
            component={CodeChallangeWeek2}
          />
          {/* Ending COde Challange PAge */}
          {/* Starting Practice Questions */}
          <Route
            exact
            path="/index_practice_questions"
            component={IndexPracticeQuestions}
          />
          <Route
            exact
            path="/index_c_practice_questions"
            component={IndexCPracticeQuestions}
          />
          <Route
            exact
            path="/c_practice_questions_beginner"
            component={CPracticeQuestionsBeginner}
          />
          <Route
            exact
            path="/c_practice_questions_intermediate"
            component={CPracticeQuestionsIntermediate}
          />
          <Route
            exact
            path="/c_practice_questions_advance"
            component={CPracticeQuestionsAdvance}
          />
          <Route
            exact
            path="/index_java_practice_questions"
            component={IndexJavaPracticeQuestions}
          />
          <Route
            exact
            path="/java_practice_questions_beginner"
            component={JavaPracticeQuestionsBeginner}
          />
          <Route
            exact
            path="/java_practice_questions_intermediate"
            component={JavaPracticeQuestionsIntermediate}
          />
          <Route
            exact
            path="/java_practice_questions_advance"
            component={JavaPracticeQuestionsAdvance}
          />
          <Route
            exact
            path="/index_python_practice_questions"
            component={IndexPythonPracticeQuestions}
          />
          <Route
            exact
            path="/python_practice_questions_beginner"
            component={PythonPracticeQuestionsBeginner}
          />
          <Route
            exact
            path="/python_practice_questions_intermediate"
            component={PythonPracticeQuestionsIntermediate}
          />
          <Route
            exact
            path="/python_practice_questions_advance"
            component={PythonPracticeQuestionsAdvance}
          />
          <Route
            exact
            path="/index_ds_practice_questions"
            component={IndexDSPracticeQuestions}
          />
          <Route
            exact
            path="/ds_practice_questions_beginner"
            component={DSPracticeQuestionsBeginner}
          />
          <Route
            exact
            path="/ds_practice_questions_intermediate"
            component={DSPracticeQuestionsIntermediate}
          />
          <Route
            exact
            path="/ds_practice_questions_advance"
            component={DSPracticeQuestionsAdvance}
          />
          {/* Ending Code Challange */}
          {/* Strting Learning Platform  */}
          <Route
            exact
            path="/index_learning_platforms"
            component={IndexLearningPlatforms}
          />
          <Route
            exact
            path="/certification_platforms"
            component={CertificationPlatforms}
          />
          <Route
            exact
            path="/webdevelopment_platforms"
            component={WebDevelopmentPlatforms}
          />
          <Route
            exact
            path="/programming_practice_platforms"
            component={ProgrammingPracticePlatforms}
          />
          <Route
            exact
            path="/programming_learning_platforms"
            component={ProgrammingLearningPlatforms}
          />
          <Route
            exact
            path="/datascience_platforms"
            component={DataSciencePlatforms}
          />
          <Route
            exact
            path="/computerscience_platforms"
            component={ComputerSciencePlatforms}
          />
          {/* Ending Learning paltform */}
          {/* Starting video  */}
          <Route exact path="/index_video" component={IndexVideo} />
          {/* Ending Video */}
          {/* Starting RGPV */}
          <Route exact path="/rgpv" component={IndexRgpv} />
          <Route
            exact
            path="/rgpv_cloud_computing"
            component={CloudComputing}
          />
          <Route
            exact
            path="/rgpv_machine_learning"
            component={MachineLearning}
          />
          <Route exact path="/rgpv_ar_vr" component={ARVR} />
          {/* Ending RGPV */}
          {/* Quiz Exam Routes*/}
          {/* Email Templates Datacode */}
          <Route
            exact
            path="/email_mock_drive_reg"
            component={MockDriveRegConfirmation}
          />
          <Route
            exact
            path="/email_mock_drive_invitation"
            component={MockDriveInvitationMail}
          />
          <Route
            exact
            path="/email_mock_placement_test_link"
            component={MockPlacementTestLink}
          />
          <Route
            exact
            path="/email_mock_placement_test_report"
            component={MockPlacementTestReport}
          />
          <Route
            exact
            path="/email_mock_coding_test_link"
            component={MockCodingTestLink}
          />
          <Route
            exact
            path="/email_mock_coding_test_report"
            component={MockCodingTestReport}
          />
          <Route
            exact
            path="/email_mock_interview_slot_link"
            component={MockInterviewSlot}
          />
          {/*Quiz Board and User. . . . .  */}
          <Route exact path="/quiz_board_home" component={QuizBoardHome} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/confirmation/:type" component={Confirmation} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route
            exact
            path="/reset_password/:resetCode"
            component={ResetPassword}
          />
          <Route path="/contributor_home" render={authGuard(ContributorHome)} />
          <Route
            path="/create_quiz_test_overview/:quizId"
            render={authGuard(CreateQuizOverview)}
          />
          <Route
            exact
            path="/create_quiz_test_question"
            component={CreateQuestion}
          />
          <Route
            exact
            path="/create_quiz_test_settings"
            render={authGuard(CreateQuizSettings)}
          />
          {/* -------------------- */}
          <Route exact path="/social_media" component={SocialMediaLinks} />
          <Route exact path="/speaker_form" component={SpeakerRegistration} />
          {/* ------------------------ */}
          {/*Admin . . . Contributor Tutorial  */}
          {/* <Route path="/add_tutorial" render={authGuard(AddTutorial)} /> */}
          <Route path="/add_tutorial" render={authGuard(AddTutorial)} />
          {/* Tutorials Tech Pages */}
          <Route path="/tutorials/:technology" component={TechTutorials} />
          <Route path="/tutorial/:id" component={TutorialPage} />
          {/* Profile */}
          {/* <Route path="/my_profile/:email" render={authGuard(MyProfile)} /> */}
          <Route path="/my_profile/:userName" component={MyProfile} />
          <Route path="/profile/:userName" component={MyProfile} />
          <Route exact path="/edit_profile" render={authGuard(EditProfile)} />
          <Route exact path="/my_library" render={authGuard(MyLibrary)} />
          <Route exact path="/admin/users" render={authAdminGuard(UsersList)} />
          {/* Contributor Landing */}
          <Route exact path="/contributor" component={ContributorLanding} />
          {/* -------BLog Editor---- */}
          <Route path="/editor" component={TextEditor} />
          {/* ____________________________________________ */}
          {/* Days Code */}
          {/* <Route
            path="/dayscode/batches"
            component={UserBatchDashboard}
          /> */}
          <PrivateRoute
            path="/dayscode/batch/:batch/enroll"
            component={EnrollBatch}
          />
          {/* <Route path="/days_code_problems" render={authDaysCodeGuard(DaysCode, DaysCodePage)} /> */}
          {/*  This changes is only after batch ended */}
          <PrivateRoute
            path="/dayscode/batch/:batch/content"
            checkBatchUser={true}
            checkBatchStatus={true}
            component={DaysCodePage}
            alternateComponent={BatchPage}
          />
          <PrivateRoute
            path="/dayscode/batch/:batch/dashboard"
            checkBatchUser={true}
            checkBatchStatus={true}
            component={DaysCodeDashboard}
            alternateComponent={BatchPage}
          />
          <PrivateRoute path="/dayscode/batch/:batch" component={BatchPage} />
          <PrivateRoute
            path="/dayscode"
            component={DaysCode}
            checkDaysCodeUser={true}
            alternateComponent={UserBatchDashboard}
          />
          <Route path="/role" component={RoleForm} />
          <Route
            path="/admin/dayscode/problem/preview/:id"
            render={authMentorGuard(ProblemPreview)}
          />
          <Route
            path="/admin/dayscode/problem/:id"
            render={authMentorGuard(AddProblem)}
          />
          <Route
            path="/admin/dayscode/problems"
            render={authMentorGuard(ProblemsList)}
          />
          <Route
            path="/admin/dayscode/submissions"
            render={authMentorGuard(SubmissionsList)}
          />
          <Route
            path="/admin/dayscode/users/:batchId"
            render={authAdminGuard(DaysUsersList)}
          />
          <Route
            path="/admin/dayscode/notification"
            render={authMentorGuard(Notification)}
          />
          <Route
            path="/admin/dayscode/lesson/:id"
            render={authMentorGuard(AddLesson)}
          />
          <Route
            path="/admin/dayscode/lessons"
            render={authMentorGuard(LessonsList)}
          />
          <Route
            path="/admin/dayscode/quiz_challenge/:id"
            render={authAdminGuard(AddQuizChallenge)}
          />
          <Route
            path="/admin/dayscode/quiz_challenges"
            render={authAdminGuard(QuizChallengesList)}
          />
          <Route
            path="/admin/dayscode/user_attendance_list"
            render={authMentorGuard(UserAttendanceList)}
          />
          <Route
            path="/admin/dayscode/session_attendance"
            render={authMentorGuard(AddUserAttendance)}
          />
          <Route
            path="/admin/dayscode/feedbacks"
            render={authAdminGuard(FeedBacks)}
          />
          <Route
            path="/admin/dayscode/batch_users"
            render={authMentorGuard(UsersProgress)}
          />
          <Route
            path="/admin/dayscode/batch/:id"
            render={authAdminGuard(CreateCodeBatch)}
          />
          <Route
            path="/admin/dayscode/batch_dashboard/:id"
            render={authAdminGuard(DaysCodeBatchPage)}
          />
          <Route
            path="/admin/dayscode/batches"
            render={authAdminGuard(DaysCodeBatches)}
          />
          <Route
            path="/admin/dayscode/mentor/:id"
            render={authAdminGuard(CreateMentor)}
          />
          <Route
            path="/admin/dayscode/mentors"
            render={authAdminGuard(MentorsList)}
          />
          <Route path="/days_timeline" component={DaysCodeTimeLine} />
          {/* FreeCode Class */}

          <Route path="/free-class" component={FreeCodeClassPage} />
          {/* Bootcamp */}

          <Route path="/bootcamp" component={Bootcamp} />

          {/* Appointment Book */}
          <Route path="/appointment/:event" component={UserAppointment} />
          {/* Datacode Community */}
          <Route path="/community" component={Community} />
          {/* Datacode Community */}
          <Route path="/sagethon" component={SagethonPage} />
          {/* Interview / Placement */}

          {/* Internship_Program/Carrer */}
          <Route path="/internship" component={InternshipProgram}></Route>
          <Route
            path="/add_interview_questions"
            component={AddInterviewQuestions}
          />
          <Route path="/interview_questions" component={InterviewQuestions} />
          {/* Club Routes */}
          <Route path="/club/application" component={ClubApplication} />
          <Route path="/club" component={StudentLearningClubLanding} />
          <Route
            path="/admin/club/applications"
            render={authAdminGuard(Applications)}
          />
          <Route
            path="/admin/club/application/:id"
            render={authAdminGuard(ClupApplicationView)}
          />
          {/* Datacode Academy */}
          <Route path="/academy/workshop/:id" component={WorkshopPage} />
          <Route path="/academy/workshop" component={WorkshopLandingPage} />
          <Route path="/academy" component={AcademyLandingPage} />
          {/* Super Admin  Routes*/}
          <Route path="/admin" render={authAdminGuard(SuperAdmin)} />
          {/* wittyhacks */}
          <Route exact path="/wittyhacks" component={Wittyhacks} />
          <Route path="/error" component={PageBreak} />
          <Route exact path="/" component={LandingPage} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppRouters;
