import Aos from "aos";
import { useEffect } from "react";
import { learnSection } from "./Constants";

const WhatLearn = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div className="row mx-0 py-5 justify-content-center what-learn-section">
        <div className="col-lg-10 col-12 px-lg-0 px-3">
          <div className="">
            <h1
              className="text-center py-lg-5 py-4 sub-heading"
              data-aos="fade-up"
            >
              What You will Learn
            </h1>
            <div className="row mx-0 py-lg-5 py-3 align-items-stretch justify-content-center align-items-center">
              {learnSection.map((learn, index) => (
                <div className="py-4 col-md-4 col-12 px-md-2 px-0" key={index}>
                  <div
                    className="card border-0 rounded-lg shadow-sm h-100  card-section"
                    data-aos="fade-up"
                  >
                    <div className="card-body">
                      {" "}
                      <div className="d-flex gap-1">
                        <div className=" ">
                          <div
                            className={`d-flex justify-content-center align-items-center text-center p-2 fs-36  ${
                              index % 2 !== 0
                                ? "icon-section-2 "
                                : "icon-section"
                            }`}
                          >
                            <img
                              src={learn.icon}
                              alt=""
                              width={40}
                              height={40}
                              className={`${
                                index === 2 || index === 4
                                  ? "rounded-circle"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                        <div>
                          <h5>{learn.skill}</h5>
                          <p className="fs-18">{learn.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhatLearn;
