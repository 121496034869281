import { SiElementor } from "react-icons/si";
import { FaCode } from "react-icons/fa6";
import { GrResume } from "react-icons/gr";
import { PiCertificateBold } from "react-icons/pi";

import htmlImg from "../../../assets/images/html_tech.jpeg";
import cssImg from "../../../assets/images/css_tech.jpeg";
import jsImg from "../../../assets/images/js_tech.jpeg";
import reactImg from "../../../assets/images/internship/react.png";
import gitImg from "../../../assets/images/git_tech.jpeg";
import codeReviewImg from "../../../assets/images/web_tool.jpeg";
import projectLayoutImg from "../../../assets/images/os_tech.jpeg";
import liveImg from "../../../assets/images/cover4.jpeg";
import nextImg from "../../../assets/images/internship/next.png";
import { FaPaste, FaStar } from "react-icons/fa";

export const learnSection = [
  {
    icon: htmlImg,
    skill: "HTML Essentials",
    description: "Structure your web pages professionally",
  },
  {
    icon: cssImg,
    skill: " CSS & Bootstrap",
    description: "Create beautiful, responsive designs",
  },
  {
    icon: jsImg,
    skill: "JavaScript Mastery",
    description: "Add interactivity to your web apps",
  },
  {
    icon: reactImg,
    skill: "React Components",
    description: "Build reusable UI components",
  },
  {
    icon: nextImg,
    skill: "Next Framework",
    description: "Build reusable UI components",
  },
  {
    icon: gitImg,
    skill: "GitHub & Deployment",
    description: " Manage your code like a pro",
  },
  {
    icon: projectLayoutImg,
    skill: "Project Layout Planning",
    description: "Architect your web application",
  },
  {
    icon: codeReviewImg,
    skill: "Code Reviews & Best Practices",
    description: "Write cleaner, optimized code",
  },
  {
    icon: liveImg,
    skill: "Live Project",
    description: "A real-world project that boosts your resume and portfolio",
  },
];

export const whyJoin = [
  {
    icon: <SiElementor />,
    head: "Upskilling",
    details:
      "8 Weeks of intensive training & certifications through structured, industry vetted curriculum",
  },
  {
    icon: <FaCode />,
    head: "Live Projects ",
    details:
      "Build real, ready-to-ship products with team members & professionals for 4 weeks",
  },
  {
    icon: <GrResume />,
    head: "Workplace Skills",
    details:
      "Build your resume, enhance your profile & get noticed by recruiters from top companies.",
  },
  {
    icon: <PiCertificateBold />,
    head: "Industry Certification",
    details:
      "Get live project & training certificates and gain a point of differentiation on your CV",
  },
];

export const successStory = [
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1732198750/a6p41uy2fghxtlvphzhh.png",
    name: "smriti Jain",
    profession: "Student - React Developer",
    description:
      " High value projects! The case studies, assignments, work-ex problems were all pinpoint perfect",
    size: "big",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731946937/jiu8hatj06rgbx70qlky.png",
    name: "Poorti Jain",
    profession: "Student - React Developer",
    description:
      "This was actually better than expected. Extremely challenging and unique experience!",
    size: "small",
  },

  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png",
    name: "Sheetal Chouhan",
    profession: "Student - React Developer",
    description:
      " I'm Sheetal , a React developer recently completed MCA. I enjoy learning new technologies .",
    size: "small",
  },
  {
    // img: "http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png",
    name: "Prakhar Attarde",
    profession: "Student - React Developer",
    description:
      " I'm Prakhar , a React developer currently pursuing my B.Tech. Love to learn new technologies .",
    size: "big",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1732199056/lrgvzm7wydfmxgb1dlt1.png",
    name: "Divya Attarde",
    profession: "Student - React Developer",
    description:
      " I'm Divya , a passionate react developer, always eager to learn from anyone and anywhere.",
    size: "big",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731999476/xwlgbzgodgb9tjm513xx.png",
    name: "Aryan",
    profession: "Student - React Developer",
    description: " I'm Aryan , a quick learner and make good out of resources",
    size: "small",
  },
];

export const whoJoin = [
  {
    icon: <FaStar />,
    categories: "Students",
    description:
      "Currently pursuing your education? Start building a strong foundation in frontend development with hands-on projects, coding challenges, and the latest technologies to prepare for future opportunities.",
  },
  {
    icon: <FaStar />,
    categories: "Freshers",
    description:
      "Just graduated and ready to enter the tech industry? Kickstart your career in frontend development with essential skills, portfolio projects, and guidance on landing your first job.",
  },
  {
    icon: <FaStar />,
    categories: "Aspiring Frontend Developers",
    description:
      "Passionate about creating stunning user interfaces? Enhance your knowledge of HTML, CSS, JavaScript, and modern frameworks to turn your passion into a profession",
  },
  {
    icon: <FaStar />,
    categories: "Professionals Looking to Upskill",
    description:
      "Stay ahead in the fast-evolving tech world! Level up your frontend development skills with advanced concepts, best practices, and the latest industry trends to grow your career.",
  },
];

export const certificateType = [
  // {
  //   icon : <FaPaste />,
  //   name: "Virtual Internship Certificate",
  //   certificate: "Internship Certificate",
  //   certify: "This is to certify",
  //   internName: "Your Name",
  //   course: "Frontend Development",
  //   hasCompleted : "Virtual Internship"
  // },
  {
    icon: <FaPaste />,
    name: "Live Internship Certificate",
    certificate: "Project Certificate",
    certify: "This is to certify",
    internName: "Your Name",
    course: "Datacode.in",
    hasCompleted: "Live Project",
  },
  // {
  //   icon : <FaPaste />,
  //   name: "Training Internship Certificate",
  //   certificate: "Trining Certificate",
  //   certify: "This is to certify",
  //   internName: "Your Name",
  //   course: "",
  //   hasCompleted : ""
  // },
];

export const feesSteps = [
  {
    step: 1,
    heading: "Pay and Enroll in Program",
    description: "Kickstart your 0-risk learning experience",
  },
  {
    step: 2,
    heading: "8 week Projects",
    description:
      "Work on 8 Live projects and seek out expert assistance when you're stuck",
  },
  {
    step: 3,
    heading: "Get full program fee refunded",
    description: "Get your entire fee back post program completion",
  },
];
