import { useHistory } from "react-router-dom";
import CustomButton from "../../../shared_components/CustomButton";

const GetStart = () => {
  const history = useHistory()

  const handleApplyNow = () => {
    debugger
    history.push('https://api.whatsapp.com/send/?phone=917400898402&text=Hello%2C+I+am+interested+to+join+bootcamp&type=phone_number&app_absent=0')
  }

  return (
    <>
      <div className="row mx-0 justify-content-center py-5 get-start-section">
        <div className="col-md-10 col-12 px-0 text-center py-lg-5 py-3">
          <h1>Get Started Today</h1>
          <p className="">
            Build work experience which helps you crack top tech jobs and
            internships.
          </p>
          <div>
            <a
              href='https://api.whatsapp.com/send/?phone=917400898402&text=Hello%2C+I+am+interested+to+join+bootcamp&type=phone_number&app_absent=0'
              target='_blank'
              rel='noopener noreferrer'
            >
              <CustomButton
                buttonName="Apply Now"
                className="no-hover-button font-weight-bold fs-18"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetStart;
