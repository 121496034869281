export const learningPlatforms = [
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157739/bsah50eh5ewf9kbeehpp.jpg",
    title: "Online Certification Learning Platforms",
    paragraph:
      "We Love to Code and this is the best way to express ourlove to Computer Science Wolrd.",
    link: "/certification_platforms",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157739/bsah50eh5ewf9kbeehpp.jpg",
    title: "Programming Learning Platforms",
    paragraph:
      "We Love to Code and this is the best way to express our love to Computer Science Wolrd.",
    link: "/programming_learning_platforms",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157739/bsah50eh5ewf9kbeehpp.jpg",
    title: "Programming Practice Platforms",
    paragraph:
      "We Love to Code and this is the best way to express ourlove to Computer Science Wolrd.",
    link: "/programming_practice_platforms",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157739/bsah50eh5ewf9kbeehpp.jpg",
    title: "WebDevelopment Learning Platforms",
    paragraph:
      "We Love to Code and this is the best way to express ourlove to Computer Science Wolrd.",
    link: "/webdevelopment_platforms",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157739/bsah50eh5ewf9kbeehpp.jpg",
    title: "ComputerScience Learning Platforms",
    paragraph:
      "We Love to Code and this is the best way to express our love to Computer Science Wolrd.",
    link: "/computerscience_platforms",
  },
  {
    imgsource:
      "http://res.cloudinary.com/datacode/image/upload/v1731157739/bsah50eh5ewf9kbeehpp.jpg",
    title: "DataScience Learning Platforms",
    paragraph:
      "We Love to Code and this is the best way to express our love to Computer Science Wolrd.",
    link: "/datascience_platforms",
  },
];
