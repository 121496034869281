import React from "react";
import Carousel from 'react-multi-carousel';
import { responsive } from "../../../../../utils";
import CustomButton from "../../../../../shared_components/CustomButton";

const data = [
  {
    title: "Full Stack Web Development",
    content: "Learn how to create a Website from Scratch!",
  },
  {
    title: "React - The Complete Guide",
    content: "Learn how to create a Website from Scratch!",
  },
  {
    title: "React - The Complete Guide",
    content: "Learn how to create a Website from Scratch!",
  },
  {
    title: "React - The Complete Guide",
    content: "Learn how to create a Website from Scratch!",
  },
  {
    title: "React - The Complete Guide",
    content: "Learn how to create a Website from Scratch!",
  },
  {
    title: "React - The Complete Guide",
    content: "Learn how to create a Website from Scratch!",
  }
]
const TrainingWorkshopSection = () => {
  return (
    <>
      <div className="row mx-0 my-5 justify-content-center training-workshop-section">
        <div className="col-md-10 col-12">
          <div className="row mx-0">
            <div className="col-12">
              <h1>
                Transition to your dream career with Trainings and Workshops
              </h1>
              <p>Learn from industry exeprts and get ready to follow your dream career path</p>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12">
              <h1 className="text-center mt-5">
                Upcoming Trainings
              </h1>
              <div className="my-5" style={{ position: "relative" }}>
                <Carousel
                  draggable={true}
                  infinite={true}
                  dotListClass="custom-dot-list-style"
                  responsive={responsive}
                  autoPlay={true}
                  className="card-group"
                >
                  {
                    data && data.map((item, i) => (
                      <div className="col-12 border workshop-card">
                        <div className="img-block">
                          <img
                            className="img-fluid mx-auto"
                            loading="lazy"
                            src={`http://res.cloudinary.com/datacode/image/upload/v1687763436/vr2hbjnhli7y3iewspc4.png`}
                            alt="avatar"
                          />
                        </div>
                        <div className="text-left">
                          <h6>{item.title}</h6>
                          <p className="mt-3">{item.content}</p>
                        </div>
                        <div className="text-center p-5">
                          {/* <button className="btn btn-primary">More</button> */}
                          <CustomButton buttonName="More"  className="font-weight-bold"/>
                        </div>
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </div>
          </div>
          <div className="text-center">
            {/* <button className="btn btn-primary">Explore Training</button> */}
            <CustomButton buttonName="Explore Training" className="font-weight-bold" />
          </div>
        </div>
      </div>
    </>
  )
}

export default TrainingWorkshopSection