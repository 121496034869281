import React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaCog,
  FaEye,
  FaHeart,
  FaRegHeart,
  FaShareAlt,
  FaNewspaper,
} from "react-icons/fa";
import CustomButton from "../../../shared_components/CustomButton";

const backgroundColors = [
  "#B5EAD7", // Soft Mint
  "#E2F0CB", // Sage Green
  "#FFDAC1", // Peach
  "#FFB7B2", // Salmon Pink
  "#C7CEEA", // Periwinkle
  "#F0E6EF", // Dusty Rose
  "#98DDCA", // Aqua
  "#FFD93D", // Warm Yellow
  "#95E1D3", // Seafoam
  "#A2D2FF", // Sky Blue
  "#FF9AA2", // Light Coral
  "#BDADEA", // Light Purple
];

const BlogListCard = ({ lesson, items, selectedBLog }) => {
  const [formatDate, setFormatDate] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [bgColor, setBgColor] = useState(
    backgroundColors[Math.floor(Math.random() * backgroundColors.length)]
  );

  useEffect(() => {
    const date = moment(lesson?.updatedAt);
    setFormatDate(date.format("MMMM Do YYYY"));
  }, [lesson]);

  return (
    <div>
      <div className="card h-100 blog-card shadow p-0">
        <div className="blog-hover">
          <div
            style={{
              background: bgColor,
              height: "200px",
              borderRadius: "8px 8px 0 0",
              boxShadow: "inset 0 0 15px rgba(0,0,0,0.1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaNewspaper
              style={{ fontSize: "60px", color: "rgba(255, 255, 255, 0.7)" }}
            />
          </div>
          <div className="card-body px-2 pt-2 ">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <CustomButton className="p-2">
                {" "}
                {lesson.category || "Development"}
              </CustomButton>
              <span className="text-muted d-flex align-items-center mr-2">
                <FaEye className="mr-1" />
                {12000}
              </span>
            </div>
            <h5 className="card-title ">
              {lesson.name.length > 24
                ? lesson.name.slice(0, 23) + "..."
                : lesson.name || "Nobody Wants To Wor..."}
            </h5>
            <p className="card-text text-muted">
              {lesson.description?.length > 0
                ? lesson.description.slice(0, 115) + "..."
                : "Lorem ipsum dolor sit amet consectetur modi illo vel, itaque praesentium eos nihil, minima inventore labore mol..."}
            </p>
          </div>
          <div className="py-0 px-2 bg-transparent border-top-0">
            <div className="d-flex justify-content-between align-items-center">
              <span className="text-muted d-flex align-items-center">
                <img
                  className="j border mr-2 rounded-pill"
                  height={30}
                  width={30}
                  src={
                    lesson.author?.imgUrl ||
                    "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
                  }
                  alt=""
                />
                {lesson.author
                  ? lesson.author?.firstName + " " + lesson.author?.lastName
                  : "Divy Attarde"}
              </span>
            </div>
          </div>
          <hr className="my-2" />
          <div className="card--footer w-100 pr-2">
            <div className="d-flex justify-content-between gap-3 px-2">
              <button
                className={`btn btn-link p-0 ${
                  isLiked ? "text-danger" : "text-muted"
                }`}
              >
                {isLiked ? <FaHeart /> : <FaRegHeart />}
                <span className="ml-1">450</span>
              </button>
              <button className="btn p-0 text-muted">
                <FaShareAlt />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListCard;
