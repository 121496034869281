import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "../../../../app/components/footer_component/CardCarouselItem";
import CardCover from "../../../../shared_components/CardCover";

class MCQCardCarousel extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className="mb-5 d-none d-sm-block">
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                
                  cardComponent={
                    <CardCover
                      cardName="Python MCQ's"
                      className="py-5"
                      index={0}
                    />
                  }
                  title="Python MCQ's"
                  link="/index_python_mcqs"
                />
                <CardCarouselItem
                  // imgsource="c_mcq.jpeg"
                  cardComponent={
                    <CardCover cardName="C MCQ's" className="py-5" index={1} />
                  }
                  title="C MCQ's"
                  link="/index_c_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Java MCQ's"
                      className="py-5"
                      index={2}
                    />
                  }
                  title="Java MCQ's"
                  link="/index_java_mcqs"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem

                  cardComponent={
                    <CardCover
                      cardName="C++ MCQ's"
                      className="py-5"
                      index={3}
                    />
                  }
                  title="C++ MCQ's"
                  link="/index_cpp_mcqs"
                />
                <CardCarouselItem
                  
                  cardComponent={
                    <CardCover
                      cardName="DBMS MCQ's"
                      className="py-5"
                      index={4}
                    />
                  }
                  title="DBMS MCQ's"
                  link="/index_dbms_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="HTML MCQ's"
                      className="py-5"
                      index={5}
                    />
                  }
                  title="HTML MCQ's"
                  link="/index_html_mcqs"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  
                  cardComponent={
                    <CardCover
                      cardName="CSS MCQ's"
                      className="py-5"
                      index={0}
                    />
                  }
                  title="CSS MCQ's"
                  link="/index_css_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Android MCQ's"
                      className="py-5"
                      index={5}
                    />
                  }
                  title="Android MCQ's"
                  link="/index_android_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Datastructure MCQ's"
                      className="py-5"
                      index={3}
                    />
                  }
                  title="Datastructure MCQ's"
                  link="/index_ds_mcqs"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Bootstrap MCQ's"
                      className="py-5"
                      index={5}
                    />
                  }
                  title="Bootstrap MCQ's"
                  link="/index_bootstrap_mcqs"
                />
                <CardCarouselItem
                  
                  cardComponent={
                    <CardCover
                      cardName="Django MCQ's"
                      className="py-5"
                      index={3}
                    />
                  }
                  title="Django MCQ's"
                  link="/index_django_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="GIT MCQ's"
                      className="py-5"
                      index={2}
                    />
                  }
                  title="GIT MCQ's"
                  link="/index_git_mcqs"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Javascript MCQ's"
                      className="py-5"
                      index={0}
                    />
                  }
                  title="Javascript MCQ's"
                  link="/index_js_mcqs"
                />
                <CardCarouselItem
                  // imgsource="oops_mcq.jpeg"
                  cardComponent={
                    <CardCover
                      cardName="OOPS MCQ's"
                      className="py-5"
                      index={2}
                    />
                  }
                  title="OOPS MCQ's"
                  link="/index_oops_mcqs"
                />
                <CardCarouselItem
                 
                  cardComponent={
                    <CardCover
                      cardName="OOPS MCQ's"
                      className="py-5"
                      index={4}
                    />
                  }
                  title="React MCQ's"
                  link="/index_react_mcqs"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="SQL MCQ's"
                      className="py-5"
                      index={2}
                    />
                  }
                  title="SQL MCQ's"
                  link="/index_sql_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Angular MCQs Coming Soon"
                      className="py-4"
                      index={4}
                    />
                  }
                  title="Angular MCQs Coming Soon"
                  link="/index_mcqs"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="React MCQ's"
                      className="py-5"
                      index={4}
                    />
                  }
                  title="React MCQ's"
                  link="/index_react_mcqs"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className="mb-5 d-block d-md-none">
          <Carousel indicators={false}>
            <Carousel.Item>
              <CardCarouselItem
                // imgsource='c_mcq.jpeg'
                cardComponent={
                  <CardCover cardName="C MCQ's" className="py-5" index={1} />
                }
                title="C MCQ's"
                link="/index_c_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
             
                cardComponent={
                  <CardCover cardName="C++ MCQ's" className="py-5" index={3} />
                }
                title=" C++ MCQ's"
                link="/index_cpp_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
               
                cardComponent={
                  <CardCover
                    cardName="Python MCQ's"
                    className="py-5"
                    index={0}
                  />
                }
                title="Python MCQ's"
                link="/index_python_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="Java MCQ's" className="py-5" index={2} />
                }
                title="Java MCQ's"
                link="/index_java_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="HTML MCQ's" className="py-5" index={5} />
                }
                title="HTML MCQ's "
                link="/index_html_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="CSS MCQ's" className="py-5" index={0} />
                }
                title="CSS MCQs"
                link="/index_css_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Bootstrap MCQ's"
                    className="py-5"
                    index={5}
                  />
                }
                title="Bootstrap MCQ's"
                link="/index_bootstrap_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Javascript MCQ's"
                    className="py-5"
                    index={0}
                  />
                }
                title="Javascript MCQ's"
                link="/index_js_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
               
                title="React MCQ's"
                link="/index_react_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Angular MCQs Coming Soon"
                    className="py-3"
                    index={4}
                  />
                }
                title="Angular MCQs Coming Soon"
                link="/index_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Django MCQ's"
                    className="py-5"
                    index={3}
                  />
                }
                title="Django MCQ's "
                link="/index_django_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Datastructure MCQ's"
                    className="py-5"
                    index={3}
                  />
                }
                title="Data Structure MCQ's"
                link="/index_ds_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="OOPS MCQ's" className="py-5" index={2} />
                }
                title="OOPS MCQ's"
                link="/index_oops_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="SQL MCQ's" className="py-5" index={5} />
                }
                title="SQL MCQ's "
                link="/index_sql_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
              
                cardComponent={
                  <CardCover cardName="DBMS MCQ's" className="py-5" index={4} />
                }
                title="DBMS MCQ's"
                link="/index_dbms_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Android MCQ's"
                    className="py-5"
                    index={5}
                  />
                }
                title="Android MCQ's"
                link="/index_android_mcqs"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="GIT MCQ's" className="py-5" index={2} />
                }
                title="Git MCQ's"
                link="/index_git_mcqs"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}

export default MCQCardCarousel;
