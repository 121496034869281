import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostCard from "../PostCard";
import RightSideSection from "../../right_section/RightSideSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import RecommendPost from "../../right_section/RecommendPost";
import ArticleCardCarousel from "./ArticleCardCarousel";
import Discuss from "../Discuss";
import Technologies from "../../right_section/Technologies";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ArticlesResources } from "./helper";
import CardCover from "../../../shared_components/CardCover";
import { FiSearch } from "react-icons/fi";
import CustomButton from "../../../shared_components/CustomButton";

class IndexArticles extends Component {
  state = {
    persons: [],
    query: "",
  };

  handleChange = (e) => {
    this.setState({
      query: e.target.value,
    });
  };
  componentDidMount() {
    axios
      .get(`http://localhost:3000/api/get_users`)
      .then((req, res) => {
        console.log(res.json);
        this.setState({ persons: res.json });
        console.log("Data AA RAHA HE");
      })
      .catch(function (error) {
        console.log("error  aaa rhi he");
      });
    window.scrollTo(0, 0);
  }
  render() {
    const { query } = this.state;
    const filterData = ArticlesResources.filter((item) => {
      return item.title.toLowerCase().includes(query.toLowerCase());
    });

    const description =
      "Time to enhance your beauty with the information of special documentation made for Special Learners";
    const title = "Technical Learning Blogs | Datacode.in";
    return (
      <>
        {/* Card Code */}
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet="true"
            name="description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            http-equiv="cleartype"
            content="on"
          ></meta>
          <meta
            data-react-helmet="true"
            name="apple-mobile-web-app-capable"
            content="yes"
          ></meta>
          <meta
            data-react-helmet="true"
            name="robots"
            content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:locale"
            content="en_US"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:type"
            content="website"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:url"
            content="https://www.datacode.in/"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:site_name"
            content="Datacode.in"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:secure_url"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:width"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:height"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            name="keywords"
            content="programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:site"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:creator"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <link
            data-react-helmet="true"
            rel="shortlink"
            href="https://www.datacodoe.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//www.datacode.in"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//s.w.org"
          ></link>
          <link
            data-react-helmet="true"
            rel="alternate"
            type="application/rss+xml"
            title="Datacode"
            href="https://www.datacode.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="canonical"
            href="https://www.datacode.in/"
          ></link>
        </Helmet>
        <div className="row mx-0">
          <div className="col-12">
            <div className="row mx-0 pt-md-5 pt-3">
              <div className="col-1"></div>
              <div className="col-md-7 col-12">
                <div className="">
                  <h1 className="mb-2 rounded">Communities Learning Blogs</h1>
                  <h5 className=" my-3">
                    Time to enhance your beauty with the information of special
                    documentation made for Special Learners
                  </h5>
                  <p className=" mb-5">let’s scratch your brain.</p>
                  {/* API gET CODE */}
                  <h1>{this.state.persons}</h1>
                  {this.state.persons.map((persons, i) => (
                    <li key={i}>{persons.user.name}</li>
                  ))}
                </div>
              </div>
              <div className="col-3"></div>
            </div>
            <div className="row mx-0 py-md-5 py-3">
              <div className="col-1"></div>
              {/* <div className="col-2">
              <Link
                to="/home"
                className="badge mr-1"
                style={{ backgroundColor: "#06CB6C" }}
              >
                <FontAwesomeIcon icon={faHome} size="2x" color="#072C71" />
              </Link>
            </div> */}
              <div className="col-md-4 align-items-lg-start">
                <div className="d-flex border rounded-lg">
                  <input
                    type="text"
                    placeholder="Search Here"
                    value={query}
                    onChange={this.handleChange}
                    className="w-100 px-2 border-0 rounded-lg"
                  />
                  <CustomButton
                    icon={<FiSearch />}
                    className="px-3"
                    hoverEffect={false}
                  />
                </div>
              </div>
              <div className="col-5 d-block"></div>
            </div>
            <div className="row mx-0 justify-content-lg-start justify-content-center d-flex">
              <div className="col-md-1"></div>
              <div className="col-md-8 px-0">
                <div className="row mx-0">
                  {filterData.length > 0 ? (
                    filterData.map((resource, index) => {
                      return (
                        <div className="col-md-4 col-12 my-3">
                          <PostCard
                            key={resource.id}
                            cardComponent={
                              <CardCover
                                index={index}
                                className="justify-content-center d-flex h-100"
                                cardName={resource.title}
                              />
                            }
                            title={resource.title}
                            paragraph={resource.paragraph}
                            link={resource.link}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12 text-center mt-4">
                      <h4 className="text-muted">No results found.</h4>
                    </div>
                  )}
                </div>
                <div className="py-md-5">
                  <h3 className="my-5" style={{ color: "#072C71" }}>
                    Discuss with the Community
                  </h3>
                  <Discuss
                    url="https://www.datacode.in/?p=/react-datacode/#/index_articles"
                    title="Communities Learning Blogs"
                    identifier="index_articles"
                  />
                </div>
                {/* <h4 className="mt-4 mb-4" style={{ color: "#072C71" }}>
                    For More Community Blogs
                  </h4>
                  <ArticleCardCarousel /> */}
              </div>
              <div className="col-md-3 ">
                <h5 className="" style={{ color: "#072C71" }}>
                  Recommended for you
                </h5>
                <div className=" d-flex flex-column gap-1 mb-4">
                  <RecommendPost
                    cardComponent={
                      <CardCover
                        cardName="Python MCQs Questions"
                        className="py-4 border-0 rounded-lg"
                        index={0}
                      />
                    }
                    RecPostHading="Python MCQs Questions"
                    link="/index_python_mcqs"
                  />
                  <RecommendPost
                    cardComponent={
                      <CardCover
                        cardName="Java MCQs Questions"
                        className="py-4  border-0 rounded-lg"
                        index={5}
                      />
                    }
                    RecPostHading="Java MCQs Questions"
                    link="/index_java_mcqs"
                  />
                  <RecommendPost
                    // imgsource='c_mcq.jpeg'
                    cardComponent={
                      <CardCover
                        cardName="C MCQs Questions"
                        className="py-4  border-0 rounded-lg"
                        index={1}
                      />
                    }
                    RecPostHading="C MCQs Questions"
                    link="/index_c_mcqs"
                  />
                </div>
                <div>
                  <RightSideSection />
                  <Technologies />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IndexArticles;
