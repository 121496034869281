import React from "react"
import CustomButton from "../../../../../shared_components/CustomButton"

const AcademyContributionSection = () => {
  return (
    <>
      <div className="row mx-0 my-5 py-5 d-flex justify-content-center academy-contribution-block">
        <div className="col-md-10 col-12">
          <div className="row mx-0">
            <div className="col-md-4 col-12 d-flex align-items-center">
              <div className="">
                <h1>Start Contributing and Gain Experience</h1>
                <p>
                  A platform where you will explore, experiment, learn, participate and build a project based on an industry-defined approach.
                </p>
                {/* <button className="btn btn-primary">Get Started</button> */}
                <CustomButton buttonName="Get Started" className="font-weight-bold" />
              </div>
            </div>
            <div className="col-md-8 col-12 text-right">
              <img
                src={'http://res.cloudinary.com/datacode/image/upload/v1688243868/o0rnul5a5zzhuerry21c.png'}
                alt="avatar"
                loading="lazy"
                className="my-md-0 my-3 my-auto img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcademyContributionSection