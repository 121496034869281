import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "../../../app/components/footer_component/CardCarouselItem";
import CardCover from "../../../shared_components/CardCover";

class TrainingCertificationCardCarousel extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className="mb-5 d-none d-sm-block">
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="DataScience Training Certification"
                      index={5}
                      className="py-5"
                    />
                  }
                  title="DataScience Training Certification"
                  link="/datascience_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Machine Learning Training Certification"
                      index={5}
                      className="py-5"
                    />
                  }
                  title="Machine Learning Training Certification"
                  link="/machinelearning_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Webdevelopment Training Certification"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="Webdevelopment Training Certification"
                  link="/webdevelopment_training_certification"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                   cardComponent={
                    <CardCover
                      cardName="computerscience Training Certification"
                      index={2}
                      className="py-5"
                    />
                  }
                  title="computerscience Training Certification"
                  link="/computerscience_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="NodeJs Training Certification"
                      index={2}
                      className="py-5"
                    />
                  }
                  title="NodeJs Training Certification"
                  link="/nodejs_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="PHP Training Certification"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="PHP Training Certification"
                  link="/php_training_certification"
                />
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                    cardComponent={
                    <CardCover
                      cardName="C Training Certification"
                      index={3}
                      className="py-5"
                    />
                  }
                  title="C Training Certification"
                  link="/c_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Python Training Certification"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="Python Training Certification"
                  link="/python_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Java Training Certification"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="Java Training Certification"
                  link="/java_training_certification"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Javascript Training Certification"
                      index={2}
                      className="py-5"
                    />
                  }
                  title="Javascript Training Certification"
                  link="/js_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="HTM CSS Training Certification"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="HTM CSS Training Certification"
                  link="/html_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Git Training Certification"
                      index={4}
                      className="py-5"
                    />
                  }
                  title="Git Training Certification"
                  link="/github_training_certification"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="Datastructure & Algorithm"
                      index={4}
                      className="py-5"
                    />
                  }
                  title="Datastructure & Algorithm"
                  link="/ds_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover
                      cardName="DBMS / SQL"
                      index={0}
                      className="py-5"
                    />
                  }
                  title="DBMS / SQL"
                  link="/dbms_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover cardName="swift" index={0} className="py-5" />
                  }
                  title="swift"
                  link="/swift_training_certification"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                   cardComponent={
                    <CardCover cardName="Android" index={0} className="py-5" />
                  }
                  title="Android"
                  link="/android_training_certification"
                />
                <CardCarouselItem
                  cardComponent={
                    <CardCover cardName="React" index={0} className="py-5" />
                  }
                  title="React"
                  link="/react_training_certification"
                />
                <CardCarouselItem
                 cardComponent={
                    <CardCover cardName="React" index={0} className="py-5" />
                  }
                  title="Angular"
                  link="/angular_training_certification"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={
                    <CardCover cardName="OOPS" index={0} className="py-5" />
                  }
                  title="OOPS"
                  link="/oops_training_certification"
                />
                <CardCarouselItem
                 cardComponent={
                    <CardCover cardName="C++" index={0} className="py-5" />
                  }
                  title="C++"
                  link="/cpp_training_certification"
                />
                <CardCarouselItem
                  cardComponent = {<CardCover index={0} className="py-5" cardName="Coming soon"/>}
                  title="Coming soon"
                  link="/tutorials"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className="mb-5 d-block d-md-none">
          <Carousel indicators={false}>
            <Carousel.Item>
              <CardCarouselItem
                imgsource=""
                title="DataScience Training Certification"
                link="/datascience_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Machine Learning Training Certification"
                    index={5}
                    className="py-5"
                  />
                }
                title="Machine Learning Training Certification"
                link="/machinelearning_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Webdevelopment Training Certification"
                    index={0}
                    className="py-5"
                  />
                }
                title="Webdevelopment Training Certification"
                link="/webdevelopment_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                    <CardCover
                      cardName="computerscience Training Certification"
                      index={2}
                      className="py-5"
                    />
                  }
                title="computerscience Training Certification"
                link="/computerscience_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="NodeJs Training Certification"
                    index={2}
                    className="py-5"
                  />
                }
                title="NodeJs Training Certification"
                link="/nodejs_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="PHP Training Certification"
                    index={0}
                    className="py-5"
                  />
                }
                title="PHP Training Certification"
                link="/php_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Python Training Certification"
                    index={0}
                    className="py-5"
                  />
                }
                title="Python Training Certification"
                link="/python_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                   cardComponent={
                    <CardCover
                      cardName="C Training Certification"
                      index={3}
                      className="py-5"
                    />
                  }
                title="C _training_certification"
                link="/c_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                 cardComponent={
                    <CardCover cardName="C++" index={0} className="py-5" />
                  }
                title="C++ Training Certification"
                link="/cpp_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Java Training Certification"
                    index={0}
                    className="py-5"
                  />
                }
                title="Java _training_certification"
                link="/java_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Javascript Training Certification"
                    index={2}
                    className="py-5"
                  />
                }
                title="Javascript"
                link="/js_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="HTM CSS Training Certification"
                    index={0}
                    className="py-5"
                  />
                }
                title="HTML"
                link="/html_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Git Training Certification"
                    index={4}
                    className="py-5"
                  />
                }
                title="Git Training Certification"
                link="/github_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="swift" index={0} className="py-5" />
                }
                title="Swift Training Certification"
                link="/swift_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover
                    cardName="Datastructure & Algorithm"
                    index={4}
                    className="py-5"
                  />
                }
                title="Datastructure & Algorithm"
                link="/ds_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="DBMS / SQL" index={0} className="py-5" />
                }
                title="DBMS"
                link="/dbms_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="OOPS" index={0} className="py-5" />
                }
                title="OOPs"
                link="/oops_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                    <CardCover cardName="Android" index={0} className="py-5" />
                  }
                title="Android"
                link="/android_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="React" index={0} className="py-5" />
                }
                title="React"
                link="/react_training_certification"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
               cardComponent={
                  <CardCover cardName="React" index={0} className="py-5" />
                  }
                title="Angular"
                link="/angular_training_certification"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}

export default TrainingCertificationCardCarousel;
