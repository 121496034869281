import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom/cjs/react-router-dom";
import CustomButton from "../../../shared_components/CustomButton";

export const growLearningData = [
  {
  
    img: "http://res.cloudinary.com/akshaydev1802/image/upload/v1742227767/g0vzalvmnfbic4ozybgb.png",
    title1: "find",
    title2: 'First "Find" the Best Learning Resources',
    description1:
      "Explore and get what you want",
    description2:
      "Let’s step into the next mile,without wondering of the crowd,so start exploring !",
    btn_action: {
      text: "Find Now",
      link: "#",
    },
  },
  {
    img:"http://res.cloudinary.com/akshaydev1802/image/upload/v1742227873/d8stfppkghvljjqxyosn.png",

    title1: "learn",
    title2: "Then Learn , what you have found Learn from top-rated resources",
    description1:
      "Upskill yourself",
    description2:
      "Time to learn from top-rated resources, and upskill yourself for achieving goals.",
    btn_action: {
      text: "Learn Now",
      link: "",
    },
  },
  {
    img:"http://res.cloudinary.com/akshaydev1802/image/upload/v1742227907/jqiz8mbemkzrnme45q48.png",

    title1: "practice",
    title2: "Then 'Practice' & 'Test' what you have learned",
    description1:
      "Practice ,practice ,practice,so never give up because great things take time ,for moulding iron to diamond!",
    description2:
      " Practice from various example questions, practice questions, etc . And test your practice from Quiz tests, MCQs, and coding  challenges",
    btn_action: {
      text: "Practice Now",
      link: "",
    },
  },
  {
    
    img:"http://res.cloudinary.com/akshaydev1802/image/upload/v1742227948/uaevnrdtvpszsp0sysl4.png",

    title1: "contribute",
    title2: "And finally 'Contribute' your learnings to the community",
    description1:
      "Let’s share and solve our errors together, and spread the colours of knowledge for the betterment of community.",
    description2:
      "Add your own Tutorials and courses, blogs, practice questions, and more",
    btn_action: {
      text: "Contribute Now",
      link: "",
    },
  },
];

export const LearningGrowCarousel = ({ selectedIndex, setIndex }) => {

  return (
    <div className="learning-grow-carousel">
      <Carousel
        indicators={false}
        controls={false}
        activeIndex={selectedIndex}
        onSelect={(selectedIndex) => setIndex(selectedIndex)}
        interval={4000}
        keyboard={true}
        slide={true}
        // next={() => setIndex(selectedIndex + 1)}
        // previous={() => setIndex(selectedIndex - 1)}
      >
        {growLearningData.map((item, idx) => (
          <Carousel.Item key={idx} slide={true}>
            <div className="row mx-0 align-items-center">
              <div className="col-lg-4 col-12">
                <img
                  src={item.img}
                  alt={item.title1}
                  loading="lazy"
                  className={`mx-auto img-fluid justify-content-center  d-flex align-items-center grow-img-section ${idx===0 || idx===3 ? ' bg-6C77C1  rounded-circle':''}`}
                />
              </div>
              <div className="col-1 d-lg-block d-block"></div>
              <div className="col-lg-7 col-12">
                <div className="grow-card">
                  <div className="d-flex flex-column gap-1 text-center">
                    <h5 className="lh-base">{item.title2}</h5>
                    <p className="lh-base">{item.description1}</p>
                    <p className="lh-base">{item.description2}</p>
                    <div className="text-center">
                      <Link to={item.btn_action.link}>
                        <CustomButton buttonName={item.btn_action.text}/>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
