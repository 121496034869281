import React from 'react'

const ImageUpload = ({ setUserImgDetails, getUploadImgUrl }) => {

  const handleImage = (e) => {
    const data = new FormData()
    data.append("file", e.target.files[0])
    data.append("upload_preset", "user-profile-img")
    data.append("cloud_name", "akshaydev1802")
    setUserImgDetails(data).then((res) => {
      if (res && res.success) {
        return getUploadImgUrl(res.data)
      }
    })
  }

  return (
    <>
      <input
        type="file"
        id="edit-profile-btn"
        onChange={(e) => handleImage(e)}
        hidden
      />
    </>
  )
}

export default ImageUpload