import { faChalkboardTeacher, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import FbLikeShare from "../../../FbLikeShare";
import RightSideSection from "../../../right_section/RightSideSection";
import Technologies from "../../../right_section/Technologies";
import RoadMapCard from "../../tutorials/RoadMapCard";

import QuestionCard from "./QuestionCard";
import { interviewQuestions, otherLearningResource } from "./helper";

class SDLCInterviewQuestions extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-md-7 mt-4">
            <h2
              className="text-center mb-4 display-5"
              style={{ color: "#072C71" }}
            >
              Find the Best SDLC Interview Questions
            </h2>
            <Link
              to="/home"
              className="badge mr-1"
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size="2x" color="#072C71" />
            </Link>
            <Link
              to="/index_interview_questioins"
              className="badge mr-1"
              style={{ backgroundColor: "#072C71" }}
            >
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                size="2x"
                color="#06CB6C"
              />
            </Link>
            <div className="row mb-5">
              <div className="col">
                {interviewQuestions
                  .filter((tech) => tech.technology === "sdlc")
                  .map((item, i) => {
                    return (
                      <div key={i}>
                        <QuestionCard
                          question={item.question}
                          answer={item.answer}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-5">
            <FbLikeShare />{" "}
            <h4 className="mb-3 mt-4" style={{ color: "#072C71" }}>
              Other Learning Resources
            </h4>
            {otherLearningResource
              .filter((tech) => tech.technology === "SDLC")
              .map((item, i) => {
                return (
                  <div key={i}>
                    <RoadMapCard
                      title={item.title}
                      source={item.source}
                      tag1={item.tag1}
                      tag2={item.tag2}
                      link={item.link}
                    />
                  </div>
                );
              })}{" "}
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default SDLCInterviewQuestions;
