import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FrameSection } from "../../../../Wittyhacks/SharedComponents";
import AiSummitCounter from "./AiSummitCounter";
import AiSummitHeder from "./AiSummitHeader";
import AiSummitOurCommunityPartner from "./AiSummitOurCommunityPartner";
import AiSummitOurSponsor from "./AiSummitOurSponsor";
import AisummitParticipantsCarosuel from "./AisummitParticipantsCarosuel";
import AiSummitSchedule from "./AiSummitSchedule";
import AiSummitSpeakerWords from "./AiSummitSpeakerWords";
import AiSummitVenuePartner from "./AiSummitVenuePartner";
import AiSummitEventGlimpse from "./EventGlimpse";
import AiSummitFeedback from "./AiSummitFeedback";

const AiSummit = () => {
  // const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='mx-0 AiSummit'>
        {/* <div className='col-12 px-0 text-center'> */}
        <div className='aisummit-header-bg mb-5'>
          <AiSummitHeder />
        </div>
        {/* <AiSummitJoinCard /> */}

        {/* <div className='py-0'>
          <AiSummitCounter />
        </div> */}
        {/* <div>
          <h1 className='py-5 text-danger text-center'>RSVP is closed now!!</h1>
        </div> */}
        {/* {location.pathname === "/aisummit/rsvp" ? (
          <div className='py-5'>
            <AiSummitRSPV />
          </div>
        ) : (
          <div className='py-5'>
            <AiSummitCardBox />
          </div>
        )} */}
        {/* <div className='py-5 participants-carosuel'>
          <AisummitParticipantsCarosuel />
        </div> */}
        <div className='py-md-5 py-0'>
          <AiSummitEventGlimpse />
        </div>

        <div className='wittyhacks'>
          <FrameSection
            frameImg={`https://res.cloudinary.com/datacode/image/upload/v1732653162/olrjebihorqwnvfe7fzl.png`}
            para1={"Share on social media"}
            tags={["#datacode", "@datacode"]}
          />
        </div>
        <div className='py-5'>
          <AiSummitSpeakerWords />
        </div>
        <div className="py-5">
          <AiSummitFeedback />
        </div>
        {/* <div className="py-5">
            <AiSummitOurPartners />
          </div> */}
        <div className='pt-5'>
          <AiSummitOurSponsor />
        </div>
        <div className='pt-5'>
          <AiSummitVenuePartner />
        </div>
        <div className='py-5'>
          <AiSummitOurCommunityPartner />
        </div>
        {/* <div className="py-5">
            <AiSummitOurOrganizer />
          </div> */}
        {/* <div className="py-5">
            <AiSummitSponsor />
          </div> */}
        {/* <div className='py-5'>
          <AiSummitOurSpeaker />
        </div> */}
        {/* <div className="py-5">
            <AiSummitOurSpeakerOrganizers />
          </div> */}
        <AiSummitSchedule />
        {/* <div className="py-5">
            <AiSummitVenue />
          </div> */}
      </div>
      {/* </div> */}
    </>
  );
};
export default AiSummit;
