import { FaRupeeSign } from "react-icons/fa";
import CustomButton from "../../../shared_components/CustomButton";
import { RiRefund2Line } from "react-icons/ri";
import { MdIncompleteCircle } from "react-icons/md";
import { useEffect } from "react";
import Aos from "aos";
import { feesSteps } from "./Constants";

const EffectiveFee = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div className="row mx-0 justify-content-center py-md-5">
        <div className="col-lg-10 px-lg-0 col-12 py-md-5">
          {/* <div className="row mx-0 justify-content-center align-items-center effective-fee-section">
              <div
                className="col-lg-7 col-12 py-5 border-0 effective-fee-col-1"
                data-aos="fade-right"
              >
                <div className="d-flex flex-column justify-content-center gap-1 p-4 ">
                  <button className="text-light bg-dark px-4 py-1 btn btn-dark border-0">
                    Zero Effective Fee
                  </button>
                  <h1 className=" text-capitalize justify-content-center align-items-center">
                    <span>
                      <FaRupeeSign /> 0
                    </span>{" "}
                    program fee, if you complete all projects
                  </h1>
                  <p>
                    You will get your 100% learning fee refunded if you complete
                    all projects within the program. This way students have the
                    motivation to learn more and complete projects necessary to
                    build real work-ex skills.
                  </p>
                  <div>
                    <CustomButton
                      buttonName="Enroll Now"
                      className="no-hover-button font-weight-bold"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 px-0 px-md-3 col-12 effective-fee-col-2"
                data-aos="fade-left"
              >
                <div className="d-flex flex-column justify-content-lg-end justify-content-center gap-3">
                  <div className="card border-0 card-a mt-lg-0 mt-5">
                    <div className="card-body ">
                      <h4 className="align-align-items-center justify-content-center">
                        Complete all tasks{" "}
                        <span>
                          <MdIncompleteCircle />
                        </span>
                      </h4>
                      <p>get 100% learning fee back</p>
                      <div>
                        <CustomButton
                          className="px-3 py-2 border-0 fs-12"
                          buttonName="Enroll Now"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 card-b m-md-auto m-0">
                    <div className="card-body ">
                      <h4 className="align-align-items-center justify-content-center">
                        My Refund Balance{" "}
                        <span>
                          <RiRefund2Line />{" "}
                        </span>
                      </h4>
                      <p>get 100% learning fee back</p>
                      <div>
                        <CustomButton
                          className="px-3 py-2 border-0 fs-12"
                          buttonName="Enroll Now"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="row mx-0  pt-5 mt-lg-5 my-2 justify-content-between text-center steps-section gap-md-5 gap-0">
            {feesSteps.map((item, index) => (
              <div
                className="py-4 col-md-3 col-12 px-0 steps-section"
                key={index}
              >
                <div className="card border-0 justify-content-center align-items-center d-flex flex-column gap-1">
                  <div className="">
                    <h1 className="justify-content-center align-items-center d-flex  steps-border-section mb-0">
                      {item.step}
                    </h1>
                  </div>
                  <h5>{item.heading}</h5>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default EffectiveFee;
