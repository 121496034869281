import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Modal, Button } from "antd";
import CustomButton from "../../../../../shared_components/CustomButton";
const JoinUs = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    AOS.init();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Open the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle email button
  const handleSendEmail = () => {
    const toEmail = "datacode.in@gmail.com";
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${toEmail}`;
    window.open(gmailLink, "_blank");
    setIsModalOpen(false);
  };
  return (
    <>
      <div
        className="row mx-0 justify-content-center join-us "
        data-aos="zoom-in"
      >
        <div className="col-12 col-md-10 col-lg-10 px-0 py-5 align-items-center justify-content-center px-0">
          <div className="row mx-0">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
              <div>
                <h1 className="text-center text-lg-left">
                  Ready to <span>Join Us</span>
                </h1>
                <p className="py-4 text-center text-lg-left">
                  Click on the button below and start your journey with
                  Datacode.
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  {/* <button
                    type="button"
                    className="px-5 py-3 text-center text-lg-left"
                    onClick={showModal}
                  >
                    Apply Now
                  </button> */}
                  <CustomButton buttonName="Apply Now" onClick={showModal} />
                </div>

                <Modal
                  title="Instructions to Send Your Application"
                  visible={isModalOpen}
                  onCancel={handleCancel}
                  footer={[
                    <Button key="cancel" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button key="send" type="primary" onClick={handleSendEmail}>
                      Click here to send mail
                    </Button>,
                  ]}
                >
                  <p>
                    Please follow the instructions below to write email for your
                    application:
                  </p>
                  <ul>
                    <li>Write your **Name**.</li>
                    <li>Write your **College/University Name**.</li>
                    <li>
                      Write about your **Skills and Experience** in frontend
                      technologies.
                    </li>
                    <li>Attach your **Updated Resume**.</li>
                  </ul>
                  <p>
                    Once you have prepared the email, click the **Send Email**
                    button in Gmail to submit your application.
                  </p>
                </Modal>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center">
              <div className="py-5 py-lg-0">
                <img
                  src={`http://res.cloudinary.com/datacode/image/upload/v1731595731/p6rkndc1uuxjp3xscbff.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default JoinUs;
