import React from "react";

const valuesData = [
  {
    icon: "fa fa-heart",
    head: "Do It as you Own It",
    description:
      "We take proactive ownership of our work, assuming full responsibility for projects, tasks, and outcomes.",
  },
  {
    icon: "fa fa-star",
    head: "Work Hard",
    description:
      "There is no alternative to hard work. We believe consistent dedication and effort are essential to achieve extraordinary results.",
  },
  {
    icon: "fa fa-thumbs-up",
    head: "Empathy",
    description:
      "We strive to understand the perspectives of our colleagues. This empathy allows us to build strong relationships and deliver solutions that truly address needs.",
  },
  {
    icon: "fa fa-handshake",
    head: "Deep Dive, Innovate",
    description:
      "We are driven by curiosity. We go beyond the surface to understand problems thoroughly, and then use that knowledge to generate creative solutions and make improvements.",
  },
];

const OurValues = () => {
  return (
    <div className="row mx-0 justify-content-center our-value py-lg-5">
      <div className="col-12 col-md-10 col-lg-10 px-lg-0 px-3">
        <h1 className="pb-lg-4 text-center heading">
          Our <span>Values</span>
        </h1>
        <div className="row mx-0 py-4" data-aos="fade-up">
          {valuesData.map((items) => (
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6  py-3 px-md-3 px-0"
              data-aos="fade-left"
            >
              <div className="container p-4 border shadow-sm">
                <i
                  style={{
                    background: "#fff",
                    borderRadius: "50%",
                    padding: "15px",
                    color: "red",
                    fontSize: "20px",
                  }}
                  className={items.icon}
                />
                <h5 className="pt-3 pb-2 text-light">{items.head}</h5>
                <p className="mb-0 para text-light">{items.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OurValues;
