import React, { useState } from "react";
import "../assets/scss/customComponent.scss";
import PropTypes from "prop-types";

const CustomButton = ({
  iconClass,
  hoverEffect,
  buttonName,
  className,
  buttonStyle,
  onClick,
  disabled,
  icon,
  buttontype,
  reverseOrder,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultButtonStyle = {
    backgroundColor: "#673de6",
    color: "#ffffff",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Inter",
    width: "auto",
    transition: "ease-in-out 0.3s ",
  };

  const defaultHoverStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #673de6 ",
    color: "#673de6",
  };

  const mergedStyle = {
    ...defaultButtonStyle,
    ...buttonStyle,
    ...(isHovered && typeof hoverEffect === "object"
      ? { ...defaultHoverStyle, ...hoverEffect }
      : {}),
  };

  return (
    <button
      className={`btn btn btn-custom-button ${className}`}
      icon={icon}
      style={mergedStyle}
      onClick={onClick}
      buttontype={buttontype}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
    >
      {reverseOrder ? (
        <>
          {buttonName || children}{" "}
          {icon && typeof icon === "object" ? (
            <span>{icon}</span>
          ) : (
            <span className="icon-class">{iconClass}</span>
          )}
        </>
      ) : (
        <>
          {icon && typeof icon === "object" ? (
            <span>{icon}</span>
          ) : (
            <span className="icon-class">{iconClass}</span>
          )}{" "}
          {buttonName || children}
        </>
      )}
    </button>
  );
};

export default CustomButton;

CustomButton.defaultProps = {
  iconClass: "",
  className: "",
  hoverEffect: {},
  buttonStyle: {},
  disabled: false,
  icon: "",
  reverseOrder: false,
  buttonName: "",
};

CustomButton.propTypes = {
  buttonName: PropTypes.string.isRequired,
  buttonStyle: PropTypes.object,
  onClick: PropTypes.func,
  hoverEffect: PropTypes.object,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  buttontype: PropTypes.oneOf(["button", "submit", "reset"]),
  reverseOrder: PropTypes.bool,
  children: PropTypes.node,
};
