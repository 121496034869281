import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import RecommendPost from "../RecommendPost";
import CardCover from "../../../shared_components/CardCover";
class LearningSideSection extends Component {
  state = {};
  render() {
    return (
      <>
        {/* C */}

        {this.props.language === "c" && (
          <>
            <div className="row mx-0 text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  C Programming Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="C Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C Tutorials & Courses"
              link="/c"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName=" C Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C Interview Questions"
              link="/c_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="C Programming Example Questions"
                  className="py-3 custom-cover-card"
                />
              }
              RecPostHading="C Programming Example Questions"
              link="/c_programming_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="C Programming Blogs"
                  className="py-3 custom-cover-card"
                />
              }
              RecPostHading="C Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row mx-0 text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  C Programming Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="C Programming Training & Certification"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C Programming Training & Certification"
              link="/c_training_certification"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="C Programming Practice Problems"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C Programming Practice Problems"
              link="/index_c_practice_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="C MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C MCQs"
              link="/index_c_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="C Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C Quiz Test"
              link="/c_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* Python */}

        {this.props.language === "python" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Python Programming Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Python Tutorials & Courses"
                  className="py-4 custom-cover-card "
                />
              }
              RecPostHading="Python Tutorials & Courses"
              link="/python"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="python Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="python Interview Questions"
              link="/python_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Python Programming Example Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Python Programming Example Questions"
              link="/python_programming_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="python Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="python Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row mx-0 text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Python Programming Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="python Programming Practice Problems"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="python Programming Practice Problems"
              link="/index_python_practice_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="python MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="python MCQs"
              link="/index_python_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="python Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="python Quiz Test"
              link="/python_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* Java */}

        {this.props.language === "java" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Java Programming Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Java Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Java Tutorials & Courses"
              link="/java"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Java Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Java Interview Questions"
              link="/java_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Java Programming Example Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Java Programming Example Questions"
              link="/java_programming_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Java Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Java Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Java Programming Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="Java Programming Practice Problems"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Java Programming Practice Problems"
              link="/index_java_practice_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="java MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="java MCQs"
              link="/index_java_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="java Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="java Quiz Test"
              link="/java_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* C++ */}

        {this.props.language === "cpp" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  C++ Programming Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="C++ Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ Tutorials & Courses"
              link="/cpp"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="C++ Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ Interview Questions"
              link="/cpp_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="C++ Programming Example Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ Programming Example Questions"
              link="/cpp_programming_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="C++ Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  C++ Programming Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="C++ Programming Practice Problems"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ Programming Practice Problems"
              link="/index_cpp_practice_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="C++ MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ MCQs"
              link="/index_cpp_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="C++ Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="C++ Quiz Test"
              link="/cpp_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challenge"
            />
          </>
        )}

        {/* JS */}

        {this.props.language === "javascript" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Javascript Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="avaScript Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="JavaScript Tutorials & Courses"
              link="/js"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Javascript Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Javascript Interview Questions"
              link="/js_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Javascript Example Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Javascript Example Questions"
              link="/javascript_programming_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Javascript Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Javascript Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Javascript Practice
                </h5>
              </div>
            </div>

            <RecommendPost

              cardComponent={
                <CardCover
                  index={3}
                  cardName="Javascript MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Javascript MCQs"
              link="/index_js_mcqs"
            />
            <RecommendPost
              // imgsource="js_quiz_test.jpeg"
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Javascript Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Javascript Quiz Test"
              link="/js_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* DBMS */}

        {this.props.language === "dbms" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  DBMS Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="DBMS Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DBMS Tutorials & Courses"
              link="/dbms"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="dbms_interview_questions.jpeg"
                  className="py-4 custom-cover-card"
                />
              }
              imgsource="dbms_interview_questions.jpeg"
              RecPostHading="DBMS Interview Questions"
              link="/dbms_interview_question"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="DBMS Learning Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DBMS Learning Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  DBMS Practice
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="DBMS MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DBMS MCQs"
              link="/index_dbms_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="DBMS Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DBMS Quiz Test"
              link="/dbms_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* SQL */}

        {this.props.language === "sql" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  SQL Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="SQL Tutorials & Courses"
                  className="py-4 border-0 rounded-lg"
                />
              }
              RecPostHading="SQL Tutorials & Courses"
              link="/sql"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="SQL Interview Questions"
                  className="py-4 border-0 rounded-lg"
                />
              }
              RecPostHading="SQL Interview Questions"
              link="/sql_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="SQL Learning Blogs"
                  className="py-4 border-0 rounded-lg"
                />
              }
              RecPostHading="SQL Learning Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  SQL Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="SQL MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="SQL MCQs"
              link="/index_sql_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="SQL Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="SQL Quiz Test"
              link="/sql_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* Datastructures */}

        {this.props.language === "datastructure" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  DataStructure Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="DataStructure Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure Tutorials & Courses"
              link="/ds"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="DataStructure Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure Interview Questions"
              link="/ds_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="DataStructure Example Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure Example Questions"
              link="/ds_programming_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="DataStructure Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  DataStructure Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="DataStructure Practice Problems"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure Practice Problems"
              link="/index_ds_practice_questions"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="DataStructure MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure MCQs"
              link="/index_ds_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="DataStructure Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="DataStructure Quiz Test"
              link="/ds_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* HTML */}

        {this.props.language === "html" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  HTML Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              // imgsource="html_tech.jpeg"
              cardComponent={
                <CardCover
                  index={4}
                  cardName="HTML Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="HTML Tutorials & Courses"
              link="/html"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="HTML Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="HTML Interview Questions"
              link="/html_interview_question"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="HTML Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="HTML Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  HTML Practice
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="HTML MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="HTML MCQs"
              link="/index_html_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="HTML Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="HTML Quiz Test"
              link="/html_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* CSS */}

        {this.props.language === "bootstrap" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  CSS Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="CSS Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="CSS Tutorials & Courses"
              link="/css"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="CSS Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="CSS Interview Questions"
              link="/css_interview_question"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={6}
                  cardName="CSS Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="CSS Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  CSS Practice
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="CSS MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="CSS MCQs"
              link="/index_css_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Html / CSS Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Html / CSS Quiz Test"
              link="/html_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challenge"
            />
          </>
        )}

        {/* OOPs */}

        {(this.props.language === "oops" ||
          this.props.language === "rdbms") && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  OOPs Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="OOPs Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="OOPs Tutorials & Courses"
              link="/oops"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="OOPs Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="OOPs Interview Questions"
              link="/oops_interview_question"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={7}
                  cardName="OOPs Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="OOPs Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  OOPs Practice
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="OOPs MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="OOPs MCQs"
              link="/index_oops_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="OOPs Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="OOPs Quiz Test"
              link="/oops_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* CN*/}
        {this.props.language === "cn" && (
          <>
            <div className="row mx-0 text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Computer Networking Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="Computer Networking Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Computer Networking Tutorials & Courses"
              link="/cn"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Computer Networking Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Computer Networking Interview Questions"
              link="/cn_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Computer Networking Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Computer Networking Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Computer Networking Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="Computer Networking MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Computer Networking MCQs"
              link="/index_cn_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Computer Networking Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Computer Networking Quiz Test"
              link="/cn_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* OS */}
        {this.props.language === "os" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Operating System Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Operating System Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Operating System Tutorials & Courses"
              link="/os"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Operating System Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Operating System Interview Questions"
              link="/os_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Operating System Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Operating System Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Operating System Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="Operating System MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Operating System MCQs"
              link="/index_os_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Operating System Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Operating System Quiz Test"
              link="/os_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* React */}
        {this.props.language === "react" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  React Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="React Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="React Tutorials & Courses"
              link="/react"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="React Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="React Interview Questions"
              link="/react_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="React Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="React Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  React Practice
                </h5>
              </div>
            </div>
            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="React MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="React MCQs"
              link="/index_react_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="React Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="React Test"
              link="/react_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* Angular */}
        {this.props.language === "angular" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Angular Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={2}
                  cardName="Angular Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Angular Tutorials & Courses"
              link="/angular"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Angular Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Angular Interview Questions"
              link="/angular_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Angular Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Angular Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Angular Practice
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Angular MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Angular MCQs"
              link="/index_angular_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={0}
                  cardName="Angular Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Angular Quiz Test"
              link="/angular_quize_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={1}
                  cardName="Weekly Code challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}

        {/* Android */}
        {this.props.language === "android" && (
          <>
            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faBook}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Android Learning
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Android Tutorials & Courses"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Android Tutorials & Courses"
              link="/android"
            />

            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Android Interview Questions"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Android Interview Questions"
              link="/android_interview_question"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Android Programming Blogs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Android Programming Blogs"
              link="/prog_lan_blog"
            />

            <div className="row text-center mt-5">
              <div className="col">
                {" "}
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  color="#133658"
                  size="4x"
                  className="text-center"
                />
                <h5
                  className="text-center mt-2 mb-md-4 mb-3"
                  style={{ color: "#133658" }}
                >
                  Android Practice
                </h5>
              </div>
            </div>

            <RecommendPost
              cardComponent={
                <CardCover
                  index={4}
                  cardName="Android MCQs"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Android MCQs"
              link="/index_android_mcqs"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={3}
                  cardName="Android Quiz Test"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Android Quiz Test"
              link="/android_quizes_tests"
            />
            <RecommendPost
              cardComponent={
                <CardCover
                  index={5}
                  cardName="Weekly Code Challenges"
                  className="py-4 custom-cover-card"
                />
              }
              RecPostHading="Weekly Code Challenges"
              link="/index_code_challange"
            />
          </>
        )}
      </>
    );
  }
}

export default LearningSideSection;
