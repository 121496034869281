import React from 'react'
import CustomButton from '../../../../../../shared_components/CustomButton'

const workshopDetailsPoints = [
  {
    icon: "",
    content: 'Get 16 hours learning in 7 Days',
  },
  {
    icon: "",
    content: 'Daily guided exercises',
  },
  {
    icon: "",
    content: 'Access to 50k+ community',
  },
  {
    icon: "",
    content: 'Regular expert feedback',
  },
]
const WorkshopDetailsSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center workshop-details-section">
        <div className="col-12 col-md-10">
          <div className="row mx-0 main">
            <div className="col-md-6 col-12 right">
              {
                workshopDetailsPoints.map((item, key) => (
                  <div key={key} className="detail-block duration d-flex m-4">
                    <img
                      className="rounded-circle"
                      height="20"
                      width="20"
                      loading="lazy"
                      src={require(`../../../../../../assets/images/svg/monolog.svg`)}
                      alt="avatar"
                    />
                    <span>{item.content}</span>
                  </div>
                ))
              }
              <div className="speaker-block m-4 p-5">
                <div className="d-flex align-items-center">
                  <img
                    className="rounded-circle border border-white"
                    height="120"
                    width="120"
                    loading="lazy"
                    src={require(`../../../../../../assets/images/svg/monolog.svg`)}
                    alt="avatar"
                  />
                  <div className="text-white ml-3">
                    <p className='text-white'>Dhanshree Nirgude</p>
                    <p className="text-white">Web Developer</p>
                  </div>
                </div>
                <div className="participants-images">
                  <div className="d-flex mt-4">
                    <img
                      className="rounded-circle border border-white"
                      height="32"
                      width="32"
                      loading="lazy"
                      src={require(`../../../../../../assets/images/svg/monolog.svg`)}
                      alt="avatar"
                    />
                    <img
                      className="rounded-circle border border-white"
                      height="32"
                      width="32"
                      loading="lazy"
                      src={require(`../../../../../../assets/images/svg/monolog.svg`)}
                      alt="avatar"
                    />
                    <img
                      className="rounded-circle border border-white"
                      height="32"
                      width="32"
                      loading="lazy"
                      src={require(`../../../../../../assets/images/svg/monolog.svg`)}
                      alt="avatar"
                    />
                  </div>
                  <p className='mt-2 text-white'>3200098 people have joined already</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 left">
              <div className="m-4 border">
                <img
                  height="auto"
                  width="auto"
                  src={`https://res.cloudinary.com/datacode/image/upload/v1650287597/h2y3w1ftk24ticvyawtu.png`}
                  alt="avatar"
                  loading="lazy"
                  className="img-fluid mx-auto"
                />
              </div>
              <p className='m-4 text-center'>
                get to Meet your web designer guru, Dhanshree Nirgude! Complete Web Design: from Figma to Webflow to Freelancing
              </p>
              <div className="text-center">
                {/* <button className='btn btn-primary'>Enroll Now!!</button> */}
                <CustomButton buttonName='Enroll Now!!' className='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkshopDetailsSection