import React from "react";

import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import CustomButton from "../../../../../../shared_components/CustomButton";

const Comment = ({
  reset,
  handleSubmit,
  submitting,
  id,
  userName,
  commentTutorial,
}) => {
  const history = useHistory();
  const onSubmit = (values) => {
    if (!userName) {
      history.push(`/login`);
    }
    const value = { ...values };
    const text = value.comment;
    if (id && text && userName) {
      commentTutorial({ id, text, userName }).then((res) => {
        if (res) {
          console.log("comm", res.data);
        }
      });
    }

    reset("commentForm");
  };
  console.log("redux form submit", submitting);

  return (
    <>
      <form className="w-100 px-mc-5 px-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="row mx-0">
          <div className="col-12">
            <Field
              name="comment"
              type="textarea"
              component={RenderInputField}
              placeholder="review"
              validate={[required]}
            />
          </div>
          <div className="col-12 text-center mt-2">
            {/* <button
              type='submit'
              className='btn btn-primary login-button'
              disabled={submitting}
            >
              Submit Review
            </button> */}
            <CustomButton
              buttonName="Submit Review"
              disabled={submitting}
              buttontype="submit"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "commentForm", // a unique name for this form
})(Comment);
