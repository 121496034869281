import { useEffect } from "react";
import { whyJoin } from "./Constants";
import Aos from "aos";

const WhyJoin = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div className="row mx-0 justify-content-center py-5 why-join-section">
        <div className="col-lg-10 col-12 px-lg-0 px-3">
          <div className="">
            <h1
              className="text-center  py-lg-5 py-4 sub-heading"
              data-aos="fade-up"
            >
              Why Join This Bootcamp?
            </h1>
            <div className="row mx-0 py-5  justify-content-center align-items-center">
              {whyJoin.map((item, index) => (
                <div
                  className="col-md-6 col-12 px-lg-2 px-0 pb-5 why-join-card"
                  key={index}
                >
                  <div
                    className="d-lg-inline-flex text-lg-left text-center justify-content-lg-start justify-content-center gap-2"
                    data-aos="fade-up"
                  >
                    <div
                      className={`justify-content-center text-center d-flex
      align-items-center border-0 circle-icon  rounded-circle ${
        index % 2 !== 0 ? "border-border-secondary" : "border-border-primary"
      }`}
                    >
                      <span
                        className={`fs-36 icon d-flex p-5 justify-content-center align-items-center  ${
                          index % 2 !== 0 ? "text-black-50" : " "
                        }`}
                      >
                        {item.icon}
                      </span>
                    </div>
                    <div>
                      <h5 className="pt-3">{item.head}</h5>
                      <p className="my-0">{item.details}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhyJoin;
