import { useEffect } from "react";
import { whoJoin } from "./Constants";
import Aos from "aos";

const WhoJoin = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div className="row mx-0 justify-content-center py-5  who-join-section">
        <div className="col-lg-10 col-12 px-lg-0 px-3">
          <div className="">
            <h1 className="text-center py-lg-3 sub-heading " data-aos="fade-up">
              Who Can Join
            </h1>
            <div className="row mx-0 justify-content-between align-content-stretch py-5">
              {whoJoin.map((item, index) => (
                <div className="pt-3 col-md-6 col-12 px-md-2 px-0" key={index}>
                  <div
                    className="card border-0 h-100 who-join-card-section"
                    data-aos="fade-up"
                  >
                    <div className="card-body">
                      <div className="">
                        <span className="fs-36 icon-section">{item.icon}</span>

                        <h4 className="py-lg-1 py-2 heading-4">
                          {item.categories}
                        </h4>
                        <p className="">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhoJoin;
