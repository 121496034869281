import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

class CardCarouselItem extends Component {
  state = {};
  render() {
    const { cardComponent } = this.props;
    return (
      <>
        <div className="card post-card text-center shadow h-100">
          <div> {cardComponent}</div>
          <h5 className="text-center mt-2">{this.props.title}</h5>
          <div
            className="card-footer p-1 mt-1"
            style={{ backgroundColor: "#30006b" }}
          >
            <Link to={this.props.link}>
              <div className="btn btn-block p-0 m-0 border-0">
                <span
                  className="mt-1 p-0"
                  style={{
                    color: "white",
                  }}
                >
                  Start Learning
                  <span className="pl-2 text-light">
                    <FaArrowRightLong />
                  </span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default CardCarouselItem;
