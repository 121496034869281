import React, { useEffect } from "react";
import AOS from "aos";

const projectData = [
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594593/qipc7p01bvjecqes93ux.png`,
    head: "Datacode",
    link: "https://www.datacode.in/",
    description:
      "DataCode is an learning platform where you can learn new skills and become expert in it.",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594639/ala0ngvwjlczfyhtoduw.png`,
    head: "Taxrules",
    link: "https://taxrules.vercel.app/",
    description:
      "Taxrules is an platform where you can fill and check your taxes effortlessly.",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594685/wtc2n3grmf9ktayqso7z.png`,
    head: "Physiospine",
    link: "https://dr-physio-next.vercel.app/",
    description:
      "Dr Physio is an platform where you can take the appointment of Dr Mallika sharma and also checkout all the details.",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594735/zwb2dqgh4n7mhvcr333k.png`,
    head: "Surya Stone and Builders",
    link: "https://suryastoneandbuilders.com/",
    description:
      "Surya Stone and Builders from where you can purchase the variety of stones and makes things more beautiful.",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594780/ug9hvrouzexirxkrgn4v.png`,
    head: "Viral Haat",
    link: "https://viral-haat.vercel.app/",
    description:
      "Viral Haat is a new age of digital marketing platform which has many more services.",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1731594831/lgcziif2pec6clsgloan.png`,
    head: "React Indore",
    link: "https://www.reactindore.com/",
    description:
      "React Indore is platform of connecting with the people of react community.",
  },
];

const Projects = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 justify-content-center mt-5 pt-lg-3 projects">
      <div className="col-12 col-md-10 col-lg-10 px-2">
        <h1 className="text-center">
          This Type Of Websites You Will Develop With <span>Datacode</span>
        </h1>
        <p className="para py-4 text-center">
          You can develop a wide range of fully customisable website with
          Datacode. Here are some of the most popular websites.
        </p>
        <div className="row mx-0 mt-4 justify-content-center">
          {projectData.map((items) => (
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 py-3 px-0"
              data-aos="fade-up"
            >
              <div className="row container mx-0 px-2">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 box-1 px-0">
                  <div className="d-flex  justify-content-center pt-5 pb-0">
                    <img src={items.img} className="projectimg" height={252} />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 box-2 px-0">
                  <div className="p-3">
                    <h4 className="text-light">{items.head}</h4>
                    <p className="py-2 text-light">{items.description}</p>
                    <a href={items.link} className="learn-more px-4 py-2">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Projects;
