import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../shared_components/CustomButton";

export const ProgramUpdates = () => {
  return (
    <>
      <div className="row mx-0 border card-schadow rounded-lg mt-md-5 datacode-accouncement">
        <div className="col-md-8 col-12 px-md-5 p-3">
          <h1 className="my-3">30 Days Of Code With Datacode</h1>
          <p className="text-dark">
            Whether you’re a novice or an expert at coding, come and put your
            brain to test in this battle.
            <br />
            Learn what problem solving is and get a high-level view of the
            programming landscape.
          </p>

          <div className="text-center">
            <Link to="/dayscode">
              <div className="mt-2 mb-4" type="button">
                <CustomButton
                  buttonName="More Details"
                  className="shadow-lg border-0 rounded-pill custom-hover-button"
                />
              </div>
            </Link>
            <div className="">
              <h1 className="h4 text-danger">Applications are closed now!!!</h1>
            </div>
          </div>
          <p className="text-dark px-md-5 text-center">
            Attempt one problem each day for 30 days to master your programming
            skills with datacode
          </p>
        </div>
        <div className="col-md-4 col-12">
          <img
            src={require("../../../assets/images/gif/landing-days-code.gif")}
            alt="avatar"
            className="my-4 img-fluid"
          />
        </div>
      </div>
    </>
  );
};
