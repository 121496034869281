import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";

import { techFlowCards } from "./helper";
import { techIcon, techCategory } from "../helpers";
import { responsive } from "../../../../../../utils";

const IndexTutorial = ({ showAddTutorial, hideAddTutorial }) => {
  const [showTechIcon, setShowTechIcon] = useState("all");
  const [search, setSearch] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
    showAddTutorial();

    return () => {
      hideAddTutorial();
    };
  }, []);

  const renderTechItem = (item, i) => {
    return (
      <Link
        className='col-12 col-md-2 tech-card'
        key={i}
        to={`/tutorials/${item.technology}`}
      >
        <img
          height='50px'
          width='50px'
          src={require(`../../../../../../assets/images/svg/${item.svgSource}`)}
          alt='avatar'
          loading='lazy'
          className='mr-md-3 mr-4'
        />
        <h6>{item.title}</h6>
      </Link>
    );
  };

  const filteredList = techIcon.filter((tech) =>
    Object.values(tech).some((value) =>
      value.toLowerCase().includes(search.toLowerCase()),
    ),
  );

  return (
    <>
      <Helmet>
        <title>Find the Best Courses & Tutorials | Datacode.in</title>
        <meta
          data-react-helmet='true'
          name='description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          http-equiv='cleartype'
          content='on'
        ></meta>
        <meta
          data-react-helmet='true'
          name='apple-mobile-web-app-capable'
          content='yes'
        ></meta>
        <meta
          data-react-helmet='true'
          name='robots'
          content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:locale'
          content='en_US'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:type'
          content='website'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:title'
          content='Find the Best Courses & Tutorials | Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://www.datacode.in/'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:site_name'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:secure_url'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:width'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:height'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          name='keywords'
          content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:card'
          content='summary_large_image'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:title'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:site'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:creator'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <link
          data-react-helmet='true'
          rel='shortlink'
          href='https://www.datacodoe.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//www.datacode.in'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//s.w.org'
        ></link>
        <link
          data-react-helmet='true'
          rel='alternate'
          type='application/rss+xml'
          title='Datacode'
          href='https://www.datacode.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://www.datacode.in/'
        ></link>
      </Helmet>
      <div className='row m-0 px-md-5 px-3 category-page'>
        <div className='col-12 px-md-2 px-0'>
          <div className='row m-0 category-header '>
            <div className='col-md-6 col-12 pt-md-4 pt-3 left-header-section px-md-5 px-2'>
              <h1>Tutorials & Courses</h1>
              <p className='desktop-tutorial-text'>
                Are you desperate for learning? <br />
                Here’s the Best Material awaiting for you,show your
                determination towards the knowledgeable world.
              </p>
            </div>
            <div className='col-md-6 col-12 text-center pt-5 d-flex justify-content-left right-header-section'>
              <img
                width='510px'
                loading='lazy'     
                src="http://res.cloudinary.com/akshaydev1802/image/upload/v1742228157/jvzsmd7hsbcuypmzswgf.png"

                alt='avatar'
                className='img-section-tutorial d-block'
                style={{borderRadius: "10px "}}
              />
             
            </div>
          </div>
          <p className='text-center mt-3 mobile-tutorial-text px-4 px-md-0'>
            Are you desperate for learning? <br />
            Here’s the Best Material awaiting for you,show your determination
            towards the knowledgeable world.
          </p>
          <div className='row m-0 my-5 mx-3 tutorial-community-card'>
            <div className='col-12 col-md-4 px-3 text-center'>
              <h2 className='text-center px-0 px-md-3'>
                Let's start, learning with community
              </h2>
              <p className='text-center'>How it works?</p>
            </div>
            <div className='col-12 col-md-8 p-0 m-0 text-center '>
              <div className='m-0 p-2' style={{ position: "relative" }}>
                <Carousel
                  draggable={true}
                  infinite={true}
                  dotListClass='custom-dot-list-style'
                  responsive={responsive}
                  autoPlay={true}
                >
                  {techFlowCards.map((item, i) => (
                    // <div className="m-2 p-3 text-center flow-card">
                    //   <img
                    //     height="100px"
                    //     width="100px"
                    //     src={require(`../../../../../../assets/images/svg/${item.svgSource}`)}
                    //     alt="avatar"
                    //     loading="lazy"
                    //     className="text-center"
                    //   />
                    //   <h5>{item.title}</h5>
                    // </div>
                    <div
                      key={i}
                      className='row m-0 m-2 px-1 px-md-2 contirbute-flow-card'
                    >
                      <div className='col-12 px-0 text-center'>
                        <img
                          height='100px'
                          width='100px'
                          src={require(`../../../../../../assets/images/svg/${item.svgSource}`)}
                          alt='avatar'
                          loading='lazy'
                          className='text-center'
                        />
                        <p className=''>{item.title}</p>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
          <div className='row m-0 mt-5'>
            <div className='col-12 text-center'>
              <h2 className='text-center my-5'>
                Find the Best Programming Courses & Tutorials
              </h2>
            </div>
          </div>
          <div className='row px-md-5 px-3'>
            <div className='col-12 text-center d-flex justify-content-center'>
              <div className='d-flex mx-md-5 mx-2 px-md-4 px-1 fontSearch'>
                <input
                  type='text'
                  className='search'
                  value={search}
                  placeholder='Technology'
                  onChange={(e) => setSearch(e.target.value)}
                />
                <i className='fa fa-search search-icon' />
              </div>
            </div>
          </div>
          <div className='row mx-md-5 px-md-5 px-3'>
            <div className='col-12 tech-tag-col pr-4'>
              <ul>
                {techCategory.map((item, i) => (
                  <li
                    key={i}
                    className={`tech-tag ${
                      showTechIcon === item.techCategory
                        ? "bg-primary text-white"
                        : ""
                    }`}
                    onClick={() => setShowTechIcon(item.techCategory)}
                  >
                    <i
                      height='30px'
                      width='30px'
                      // src={require(`../../../../../../assets/images/svg/${item.svgSource}`)}
                      alt='avatar'
                      loading='lazy'
                      className={`mr-2 ${item.iconSource}`}
                    />
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='row m-0 my-2 my-md-5'>
            <div className='col-12'>
              <div className='row m-0 mt-4 px-md-5 px-3 justify-content-center'>
                {showTechIcon === "all"
                  ? filteredList.map((item, i) => renderTechItem(item, i))
                  : filteredList.map((item, i) =>
                      search === ""
                        ? item.techCategory === showTechIcon &&
                          renderTechItem(item, i)
                        : renderTechItem(item, i),
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexTutorial;
