import React, { Component } from "react";
import RecommendPost from "../../right_section/RecommendPost";
import CodeChallangeShowCard from "./CodeChallangeShowCard";
import CardCover from "../../../shared_components/CardCover";

class IndexCodeChallange extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className="container-fluids ">
          <div className="row m-0 px-3">
            <div className="col ">
              <div className="card bg-info">
                <div className="card-body p-2">
                  <h1 className="card-title text-light text-center">
                    Code Challange Week
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 d-none d-sm-block  mt-4">
              <CodeChallangeShowCard
                cardComponent={
                  <CardCover cardName="" className="py-5" index={1} />
                }
                link="code_challange_week_1"
                title="Code Challange Week #1"
              />
              <CodeChallangeShowCard
                cardComponent={
                  <CardCover cardName="" className="py-5" index={3} />
                }
                link="code_challange_week_2"
                title="Code Challange Week #2"
              />
              <CodeChallangeShowCard
                cardComponent={
                  <CardCover cardName="" className="py-5" index={4} />
                }
                link="code_challange_week_1"
                title="Code Challange Week #3"
              />
            </div>
            <div className="col-md-5 mt-3 ">
              <img
                className=" card-img-top mx-auto img-fluid img-circle d-block"
                src={
                  "http://res.cloudinary.com/datacode/image/upload/v1731504219/leryvzoaxtc5mvgxjs4j.jpg"
                }
                alt="avatar"
              />
            </div>
            {/* Mobile */}
            <div className="col-md-3 d-block d-md-none  mt-4 text-center">
              <CodeChallangeShowCard
                cardComponent={
                  <CardCover cardName="" className="py-5" index={0} />
                }
                link="/code_challange_week_1"
                title="Code Challange Week #1"
              />
              <CodeChallangeShowCard
                cardComponent={
                  <CardCover cardName="" className="py-5" index={2} />
                }
                link="/code_challange_week_1"
                title="Code Challange Week #2"
              />
              <CodeChallangeShowCard
                cardComponent={
                  <CardCover cardName="" className="py-5" index={5} />
                }
                link="/code_challange_week_1"
                title="Code Challange Week #3"
              />
            </div>

            <div className="col-md-4 mt-4 ">
              <RecommendPost
                cardComponent={
                  <CardCover
                    cardName="Python MCQs Questions"
                    className="py-4"
                    index={0}
                  />
                }
                RecPostHading="Python MCQs Questions"
                link="/index_python_mcqs"
              />
              <RecommendPost
                cardComponent={
                  <CardCover
                    cardName="Java MCQs Questions"
                    className="py-4"
                    index={1}
                  />
                }
                RecPostHading="Java MCQs Questions"
                link="/index_java_mcqs"
              />
              <RecommendPost
                imgsource="datacodelogo.jpeg"
                RecPostHading="Programminig Quize Test"
                link="/index_quize_tests"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IndexCodeChallange;
