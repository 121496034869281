import React from "react";
import { AttendeesFeedback } from "./Constants";
import { FaUsersLine } from "react-icons/fa6";

const AiSummitFeedback = () => {
  return (
    <>
      <h1 className="text-center my-5 text-light feedback-heading">
        <FaUsersLine className="p-0 mr-lg-2 mr-1" /> Our participant's love 💕
        to the community
      </h1>
      <div className="row mx-0 d-flex justify-content-center">
        <div className="col-md-3 col-sm-12 px-0">
          <div className="row mx-0 text-light">
            {AttendeesFeedback &&
              AttendeesFeedback.map(
                (feedback, index) =>
                  index % 3 == 0 && (
                    <div
                      className="col-12 p-3 aisummit-feedback-card"
                      key={index}
                    >
                      <div className="d-flex flex-column h-auto border border-light rounded-lg p-3">
                        <h1>{feedback.participantsName}</h1>
                        <p className="text-light">
                          {feedback.participantsFeedback}
                        </p>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
        <div className="col-md-3 col-sm-12 px-0">
          <div className="row mx-0 text-light">
            {AttendeesFeedback &&
              AttendeesFeedback.map(
                (feedback, index) =>
                  index % 3 == 1 && (
                    <div
                      className="col-12 p-3 aisummit-feedback-card"
                      key={index}
                    >
                      <div className="d-flex flex-column h-auto border border-light rounded-lg p-3">
                        <h1>{feedback.participantsName}</h1>
                        <p className="text-light">
                          {feedback.participantsFeedback}
                        </p>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
        <div className="col-md-3 col-sm-12 px-0">
          <div className="row mx-0 text-light">
            {AttendeesFeedback &&
              AttendeesFeedback.map(
                (feedback, index) =>
                  index % 3 == 2 && (
                    <div
                      className="col-12 p-3 aisummit-feedback-card"
                      key={index}
                    >
                      <div className="d-flex flex-column h-auto border border-light rounded-lg p-3">
                        <h1>{feedback.participantsName}</h1>
                        <p className="text-light">
                          {feedback.participantsFeedback}
                        </p>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AiSummitFeedback;
