import React from "react"
import CustomButton from "../../../../../../shared_components/CustomButton"
const WeAreHereToHelp = () => {
  return (
    <>
      <div className="row mx-0 my-5 py-5 d-flex justify-content-center">
        <div className="col-md-8 col-12 d-flex help-section">
          <div className="border-module">
            <div className="row mx-0">
              <div className="col-2 d-md-flex d-none align-items-end">
                <img
                  className=""
                  height='250'
                  loading="lazy"
                  src={`http://res.cloudinary.com/datacode/image/upload/v1687464299/km107cyuxugvzstivavr.png`}
                  alt="avat ar"
                />
              </div>
              <div className="col-8 d-block d-md-flex align-items-center">
                <h1>We’re here to help</h1>
                <div className="px-md-5 px-0">
                  <p>Learn more about our Student Learning Club, and get specific answers from our support team any time.</p>
                  {/* <button className="btn btn-light">Get Support</button> */}
                  <CustomButton buttonName="Get Support"/>
                </div>
              </div>
              <div className="col-md-2 col-4 align-items-md-end justify-content-center d-flex">
                <img
                  className=""
                  height='300'
                  loading="lazy"
                  src={`http://res.cloudinary.com/datacode/image/upload/v1687464554/iltjdoh3b3ldvu4it97l.png`}
                  alt="avat ar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WeAreHereToHelp