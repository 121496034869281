import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "../../../../app/components/footer_component/CardCarouselItem";
import CardCover from "../../../../shared_components/CardCover";

class ProgrammingQuestionCardCarousel extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className="mb-5 d-none d-sm-block ">
          <Carousel indicators={false}>
            <Carousel.Item  className="">
              <div className="card-deck align-items-stretch  justify-content-center">
                <CardCarouselItem
                   cardComponent = {<CardCover cardName="C Programming Practice Questions" index={0} className="py-5 h-100"/>}
                  title="C programming questions"
                  link="/c_programming_questions"
                />
                <CardCarouselItem
                  cardComponent = {<CardCover cardName="Python Programming Questions"  index={1} className="py-5 h-100"/>}
                  title="Python Programming Questions"
                  link="/datastructure_programming_questions"
                />
                <CardCarouselItem
                  cardComponent = {<CardCover cardName="Java Programming Questions"  index={2} className="py-5 h-100"/>}
                  title="Java Programming Questions"
                  link="/java_programming_questions"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className="mb-5 d-block d-md-none">
          <Carousel indicators={false}>
            <Carousel.Item>
              <CardCarouselItem
                 cardComponent = {<CardCover cardName="C Programming Practice Questions" index={0} className="py-5"/>}
                title="C programming questions"
                link="/c_programming_questions"
              />
            </Carousel.Item>

            <Carousel.Item>
              <CardCarouselItem
                cardComponent = {<CardCover cardName="Java Programming Questions"  index={2} className="py-5"/>}
                title="Java Programming Questions"
                link="/java_programming_questions"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
               cardComponent = {<CardCover cardName="Python Programming Questions"  index={1} className="py-5"/>}
                title="Python Programming"
                link="/python"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}

export default ProgrammingQuestionCardCarousel;
