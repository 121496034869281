import React, { useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import CustomButton from "../../../../shared_components/CustomButton";

const OurPartners = () => {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1
  //   }
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='row m-0 our-partners-header align-items-center'>
        <div className='col-12 text-center'>
          <h1>Our Partners</h1>
        </div>
      </div>
      <h1 className='px-md-5 px-3 mt-5 mb-4 text-center'>
        Our Education Partners
      </h1>
      <div className='row m-0 p-md-4 p-3'>
        <div className='col-md-6 col-12 text-center'>
          <img
            height='270px'
            width='270px'
            src={require(`../../../../assets/images/svg/education_partner.svg`)}
            alt='avatar'
            className=''
          />
        </div>
        <div className='col-md-6 col-12'>
          {/* <div className="row m-0 px-md-5 px-2">
            <div className="col-md-4 col-12">
              <img
                height="70px"
                width="70px"
                src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                alt="avatar"
                className="my-3"
              />
            </div>
            <div className="col-md-8 col-12">
              <h6>Facebook Developer Circle, Indore</h6>
              <p>Local Indore Region chapter for students and developer</p>
            </div>
          </div>
          <div className="row m-0 px-md-5 px-2">
            <div className="col-md-4 col-12">
              <img
                height="70px"
                width="70px"
                src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                alt="avatar"
                className="my-3"
              />
            </div>
            <div className="col-md-8 col-12">
              <h6>Facebook Developer Circle, Indore</h6>
              <p>Local Indore Region chapter for students and developer</p>
            </div>
          </div>
          <div className="row m-0 px-md-5 px-2">
            <div className="col-md-4 col-12">
              <img
                height="70px"
                width="70px"
                src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                alt="avatar"
                className="my-3"
              />
            </div>
            <div className="col-md-8 col-12">
              <h6>Facebook Developer Circle, Indore</h6>
              <p>Local Indore Region chapter for students and developer</p>
            </div>
          </div> */}
          <div className='row m-0 '>
            <div className='col-12 text-center'>
              <Link to='/contact_us'>
                {/* <div className='btn join-us-button'>Become Our Partner</div> */}
                <CustomButton buttonName="Become Our Partner" className="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h1 className='px-md-5 px-3 mt-5 mb-4 text-center'>
        Our Community Partners
      </h1>
      <div className='row m-0 community-partner p-md-4 p-3'>
        <div className='col-md-6 col-12 left'>
          {/* <div className="row m-0 px-md-5 px-2">
            <div className="col-md-4 col-12">
              <img
                height="70px"
                width="70px"
                src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                alt="avatar"
                className="my-3"
              />
            </div>
            <div className="col-md-8 col-12">
              <h6>Facebook Developer Circle, Indore</h6>
              <p>Local Indore Region chapter for students and developer</p>
            </div>
          </div>
          <div className="row m-0 px-md-5 px-2">
            <div className="col-md-4 col-12">
              <img
                height="70px"
                width="70px"
                src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                alt="avatar"
                className="my-3"
              />
            </div>
            <div className="col-md-8 col-12">
              <h6>Facebook Developer Circle, Indore</h6>
              <p>Local Indore Region chapter for students and developer</p>
            </div>
          </div>
          <div className="row m-0 px-md-5 px-2">
            <div className="col-md-4 col-12">
              <img
                height="70px"
               TutorialDark.png width="70px"
                src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                alt="avatar"
                className="my-3"
              />
            </div>
            <div className="col-md-8 col-12">
              <h6>Facebook Developer Circle, Indore</h6>
              <p>Local Indore Region chapter for students and developer</p>
            </div>
          </div> */}
          <div className='row m-0 '>
            <div className='col-12 text-center'>
              <Link to='/contact_us'>
                {/* <div className='btn join-us-button'>Become Our Partner</div> */}
                <CustomButton buttonName="Become Our Partner" className="" />
              </Link>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12 text-center right'>
          <img
            height='270px'
            width='270px'
            src={require(`../../../../assets/images/svg/community_partner.svg`)}
            alt='avatar'
            className=''
          />
        </div>
      </div>
      <div className='row m-0 '>
        <div className='col-12 text-center'>
          <Link to='/contact_us'>
            {/* <div className='btn join-us-button'>Become Our Partner</div> */}
            <CustomButton buttonName="Become Our Partner" className="mb-3" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default OurPartners;
