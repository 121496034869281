import { certificateType } from "./Constants";
import { useEffect, useState } from "react";
import datacodelogo from "../../../assets/images/Datacode_PNG_Logo.png";
import Aos from "aos";

const Certificates = () => {

  const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
      Aos.init();
    }, []);
  return (
    <>
      <div className="row mx-0 justify-content-center py-5 certificate-section">
        <div className="col-lg-10 px-lg-0 px-2 col-12 py-lg-5 py-4">
          <div className="">
            <h1 className="text-center  py-lg-5 py-4 sub-heading" data-aos="fade-up">
              Work-Ex Certificates
            </h1>
            <p className="text-center pt-2 pb-5" data-aos="fade-up">
              During this program, you will gain 2 work experience certificates
              from Datacode .
            </p>
            <div className="row mx-0 py-5 " data-aos="fade-up">
              <div className="col-lg-3 px-0 col-8">
                <div className="d-flex flex-column gap-2">
                  {certificateType.map((item, index) => (
                    <div
                      className=""
                      key={index}
                      onClick={() => setSelectedIndex(index)}
                    >
                      <div
                        className={`d-flex align-items-center px-1 certificate-div  gap-1 ${
                          selectedIndex === index ? "border-section" : ""
                        }`}
                      >
                        <span className="fs-28">{item.icon}</span>
                        <h6>{item.certificate}</h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-9 px-lg-3 px-0 mt-lg-0 mt-5 col-12">
                <div className="rounded-lg shadow-lg certificate-details-section-a">
                  <div className="border-0 mx-lg-4 mx-3 certificate-details-section-b">
                    {selectedIndex !== null && (
                      <div className="p-lg-4 p-2">
                        <div className="d-flex justify-content-between">
                          <h5>Datacode</h5>
                          <img
                            src={datacodelogo}
                            alt=""
                            className="logo-section"
                          />
                        </div>
                        <div className="text-center pt-4 text-capitalize d-flex flex-column gap-1 justify-content-center align-items-center">
                          <h3>{certificateType[selectedIndex].name}</h3>
                          <p>{certificateType[selectedIndex].certify}</p>
                          <h4>{certificateType[selectedIndex].internName}</h4>
                          <p className="course-name-details">
                            for successfully Completing the{" "}
                            <span>
                              {certificateType[selectedIndex].hasCompleted}
                            </span>{" "}
                            in {certificateType[selectedIndex].course}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between pt-5">
                          <div>
                            <h6 className="text-capitalize">Date</h6>
                            <p>22/3/2025</p>
                          </div>
                          <div className="authorized-section">
                            <div className="border"></div>
                            <h6 className="text-capitalize py-3">
                              Authorized signatory
                            </h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Certificates;
