import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../../shared_components/CustomButton";
class McqsShowCard extends Component {
  state = {};
  render() {
    return (
      <>
        <div className='card border-secondary shadow'>
          <div className='card-body text-center'>
            <h4>{this.props.title}</h4>
          </div>
          <Link to={this.props.link}>
            {/* <div className=' btn btn-block btn-primary'> Start</div> */}
            <div className="text-center py-3">
            <CustomButton buttonName="Start" className="text-center" />
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default McqsShowCard;
