import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import CustomLoader from "../../../../../shared_components/CustomLoader";
import {
  RenderInputField,
  renderRadioField,
} from "../../../../../shared_components/ReduxFormFields";
import { checkProfileProgress } from "../../../../../utils";
import { required } from "../../../../../utils/validators";

import ImageUpload from "../../../../../shared_components/ImageUpload";
import CustomButton from "../../../../../shared_components/CustomButton";

const EditProfile = ({
  initialize,
  reset,
  handleSubmit,
  submitting,
  signupUserLoading,
  editCurrentUser,
  userProfileLoading,
  currentUser,
  imageUploading,
}) => {
  const [image, setImage] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentUser) {
      setImgUrl(currentUser.imgUrl);
      initialize(currentUser);
      setImage("");
    }
  }, [currentUser]);

  const history = useHistory();

  const onSubmit = (values) => {
    const user = { ...values };
    user["imgUrl"] = imgUrl;

    editCurrentUser(user).then((res) => {
      if (res) {
        history.goBack();
        reset("editForm");
      } else {
        console.log("Error", res);
        reset("editForm");
      }
    });
  };

  const getUploadImgUrl = (url) => {
    if (url) {
      setImgUrl(url);
      setImage(url);
    }
  };

  return (
    <>
      {userProfileLoading ? (
        <CustomLoader />
      ) : (
        <div className="row m-0 edit-profile">
          <div className="col-md-4 col-12 text-center left-profile-section">
            <div className="mt-4">
              {imageUploading ? (
                <Loader
                  type="Puff"
                  color="#30006d"
                  height={100}
                  width={100}
                  timeout={3000} //3 secs
                />
              ) : (
                <>
                  <img
                    className="rounded-circle header-profile-img"
                    height="140"
                    width="140"
                    loading="lazy"
                    src={
                      imgUrl
                        ? imgUrl
                        : require(`../../../../../assets/images/svg/monolog.svg`)
                    }
                    alt="avatar"
                  />
                </>
              )}
            </div>
            <div>
              {
                <>
                  <ImageUpload getUploadImgUrl={getUploadImgUrl} />
                  {image ? (
                    <button
                      type="submit"
                      className="btn custom-button"
                      onClick={() =>
                        editCurrentUser({
                          imgUrl,
                          userName: currentUser.userName,
                          email: currentUser.email,
                        })
                      }
                    >
                      <span>Save</span>
                    </button>
                  ) : (
                    <label
                      className="btn btn my-3 bg-color-673de6"
                      for="edit-profile-btn"
                    >
                      Add Profile Picture
                      <i className="fad fa-user-edit ml-2" />
                    </label>
                  )}
                </>
              }
            </div>
            <div className="row m-0 px-3 my-5">
              <div className="col-12 text-left">
                {checkProfileProgress(currentUser)}
              </div>
            </div>
            {/* <div>
            <Link to="/reset_password" >
              <div className="btn edit-profile-btn mt-0 mb-3">Reset Password<i className="fad fa-user-edit ml-2" /></div>
            </Link>
          </div> */}
          </div>
          <div className="col-12 col-md-8 px-3 px-md-5">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <h6 className="mt-5 dashboard-heading">Personal Details</h6>
              <div className="mb-5 border rounded-lg p-md-3 p-2">
                <div className="row m-0">
                  <div className="col-12">
                    <Field
                      name="userName"
                      type="text"
                      component={RenderInputField}
                      label="Username"
                      placeholder="system123"
                      validate={[required]}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-6 col-12">
                    <Field
                      name="firstName"
                      type="text"
                      component={RenderInputField}
                      label="First Name"
                      placeholder=""
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-6 col-12 ">
                    <Field
                      name="lastName"
                      type="text"
                      component={RenderInputField}
                      label="Last Name"
                      placeholder=""
                      validate={[required]}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-6 col-12">
                    <Field
                      name="email"
                      type="email"
                      component={RenderInputField}
                      label="Email"
                      placeholder=""
                      validate={[required]}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Field
                      name="phone"
                      type="number"
                      component={RenderInputField}
                      label="Phone No."
                      placeholder=""
                      validate={[required]}
                      // isDisabled={true}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-6 col-12">
                    <Field
                      name="bio"
                      type="text"
                      component={RenderInputField}
                      label="Status / Bio"
                      placeholder="learner"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="mt-4">
                      <label className="form-label">Gender</label>
                      <div className="type-tag">
                        <div className="px-1 type-checkbox">
                          <Field
                            name="gender"
                            component={renderRadioField}
                            value="male"
                            label="Male"
                            type="radio"
                            validate={[required]}
                          />
                        </div>
                        <div className="px-1 mx-1 type-checkbox">
                          <Field
                            name="gender"
                            component={renderRadioField}
                            value="female"
                            label="Female"
                            type="radio"
                            validate={[required]}
                          />
                        </div>
                        <div className="px-1 mx-1 type-checkbox">
                          <Field
                            name="gender"
                            component={renderRadioField}
                            value="others"
                            label="Others"
                            type="radio"
                            validate={[required]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-5">
                <h6 className="dashboard-heading">Acadmics Details</h6>
                <div className="row m-0 border rounded-lg p-md-3 p-2">
                  <div className="col-12">
                    <Field
                      name="university"
                      type="text"
                      component={RenderInputField}
                      label="College / University"
                      validate={[required]}
                    />
                    <Field
                      name="branch"
                      type="text"
                      component={RenderInputField}
                      label="Branch"
                      validate={[required]}
                    />
                    <Field
                      name="graduationYear"
                      type="text"
                      component={RenderInputField}
                      label="Graduation Year"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="mt-3 mb-5">
              <h6>Your Achivements</h6>
              <Field
                name="email"
                type="email"
                component={RenderInputField}
                label='Email'
                placeholder="member_name@joe.com"
                validate={[required, email]}
              />
              <Field
                name="userName"
                type="text"
                component={RenderInputField}
                label='Username'
                placeholder="system123"
                validate={[required]}
              />
              <Field
                name="password"
                type="password"
                component={RenderInputField}
                label='Password'
                placeholder="******"
                validate={[required]}
              />
            </div> */}
              <div className="mt-3 mb-5">
                <h6 className="dashboard-heading">Social Media Links</h6>
                <div className="row m-0 border rounded-lg p-md-3 p-2">
                  <div className="col-12">
                    <Field
                      name="linkedin"
                      type="text"
                      component={RenderInputField}
                      label="Linkedin"
                      validate={[required]}
                      isRequired={true}
                    />
                    <Field
                      name="twitter"
                      type="text"
                      component={RenderInputField}
                      label="Twitter"
                    />
                    <Field
                      name="github"
                      type="text"
                      component={RenderInputField}
                      label="Github"
                    />
                  </div>
                </div>
              </div>
              <div className="my-3 p-3 rounded-lg border card-schadow">
                <h6 className="">
                  Please Share Your Exprience With Datacode Community
                </h6>
                <p className="">
                  We would love to show your words with other community members.
                </p>
                <Field
                  name="testimonial"
                  type="textarea"
                  component={RenderInputField}
                  // label='What is your Experience with Datacode'
                />
              </div>
              <small>
                *Please make sure to save correct links and details in your
                profile
              </small>
              <div className="row my-5">
                <div className="col-12 text-center">
                  {/* <button
                    type='submit'
                    className='btn custom-button'
                    disabled={submitting}
                  >
                    <span>Save</span>
                  </button> */}
                  <CustomButton
                    buttonName="Save"
                    className="rounded-pill mt-4 font-weight-bolder w-25 custom-hover-button"
                    disabled={submitting}
                    buttontype="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default reduxForm({
  form: "editForm", // a unique name for this form
})(EditProfile);
