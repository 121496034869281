import axios from "axios";
import { jwtDecode } from "jwt-decode";
import * as actions from "./actionCreators";
import DatacodeConfig from "../../../core/config";
import {
  SET_LOGIN_USER_LOADING,
  SET_USER_PROFILE_LOADING,
  SET_ADD_SPEAKER_LOADING,
  SET_IMAGE_UPLOADING,
} from "../constants";
import { toast } from "react-hot-toast";
import Axios from "axios";

const baseURL = DatacodeConfig.baseURL;

const getTokenExpiration = (token) => {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp) {
    return decodedToken.exp * 60000; // convert to milliseconds
  }
  return null;
};

const scheduleTokenExpiryLogout = (token) => (dispatch) => {
  const expirationTime = getTokenExpiration(token);
  if (expirationTime) {
    const timeLeft = expirationTime - Date.now();
    if (timeLeft > 0) {
      setTimeout(dispatch(actions.logoutUser()), timeLeft);
    } else {
      dispatch(actions.logoutUser()); // Token already expired
    }
  }
};

export const loginUser = (user) => (dispatch) => {
  dispatch({ type: SET_LOGIN_USER_LOADING });
  return axios
    .post(`${baseURL}/user/login`, user)
    .then((res) => {
      if (res.data && res.data.success === true) {
        dispatch(actions.setLoginUser(res.data));
        scheduleTokenExpiryLogout(res.data.token);
        return { success: true, data: res.data };
      } else {
        dispatch({ type: SET_LOGIN_USER_LOADING });
        dispatch(actions.setLoginUserError(res.data.message));
        setTimeout(() => {
          dispatch(actions.setLoginUserError(null));
        }, 5000);
        return { success: false };
      }
    })
    .catch((error, res) => {
      dispatch({ type: SET_LOGIN_USER_LOADING });
      dispatch(actions.setLoginUserError(error));
      setTimeout(() => {
        dispatch(actions.setLoginUserError(null));
      }, 5000);
    });
};

export const signupUser = (user) => (dispatch) => {
  dispatch({ type: SET_LOGIN_USER_LOADING });
  return axios
    .post(`${baseURL}/user/signup`, user)
    .then((res) => {
      if (res.data && res.data.success === true) {
        dispatch(actions.setSignupUser(res.data));
        return { success: true, data: res.data };
      } else {
        dispatch({ type: SET_LOGIN_USER_LOADING });
        dispatch(actions.setSignupUserError(res.data.message));
        setTimeout(() => {
          dispatch(actions.setSignupUserError(null));
        }, 5000);
      }
    })
    .catch((err) => {
      dispatch({ type: SET_LOGIN_USER_LOADING });
      dispatch(actions.setSignupUserError(err));
      setTimeout(() => {
        dispatch(actions.setSignupUserError(null));
      }, 5000);
    });
};

export const forgotPassword = (user) => (dispatch) => {
  return axios
    .post(`${baseURL}/user/forgot-password`, user)
    .then((res) => {
      if (res && res.data) {
        toast.success("reset password link sent to mail", {
          duration: 5000,
        });
        return { success: true, data: res.data };
      } else {
        toast.error("Something went wrong", {
          duration: 5000,
        });
        dispatch(actions.setForgotPasswordError(res.message));
      }
    })
    .catch((error) => {
      dispatch(actions.setForgotPasswordError(error));
      setTimeout(() => {
        dispatch(actions.setForgotPasswordError(null));
      }, 5000);
      console.log("Forgot Password error", error);
    });
};

export const resetPassword = (userPasscode) => (dispatch) => {
  return axios
    .post(
      `${baseURL}/user/reset-password/${userPasscode?.email_code}`,
      userPasscode,
    )
    .then((res) => {
      if (res && res.data) {
        toast.success("Password Reset Successfuly", {
          duration: 1000,
        });
        return { success: true, data: res.data, response: res };
      } else {
        dispatch(actions.setResetPasswordError(res.message));
      }
    })
    .catch((error) => {
      dispatch(actions.setResetPasswordError(error));
      setTimeout(() => {
        dispatch(actions.setResetPasswordError(null));
      }, 5000);
      console.log("Reset Password error", error);
    });
};

export const getUserProfile = (userName) => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  return axios
    .post(`${baseURL}/user/get-profile`, { userName })
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setUserProfile(data.userProfile));
        return { success: true, data: data.userProfile };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_PROFILE_LOADING });
      console.log("get userProfile error", error);
    });
};

export const getCurrentUserProfile = (userName) => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  return axios
    .post(`${baseURL}/user/get-profile`, { userName })
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setLocalUser(data.userProfile));
        return { success: true, data: data.userProfile };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_PROFILE_LOADING });
      console.log("get userProfile error", error);
    });
};

export const editCurrentUser = (user) => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  return axios
    .put(`${baseURL}/user/edit-profile`, user)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getCurrentUserProfile(data.updatedUser.userName));
        return { success: true, data: data.updatedUser };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_PROFILE_LOADING });
      console.log("get userProfile error", error);
    });
};


const axiosCloudinary = Axios.create({
  baseURL: 'https://api.cloudinary.com/v1_1/akshaydev1802',
});

export const setUserImgDetails = (data) => (dispatch) => {
  dispatch({ type: SET_IMAGE_UPLOADING });
  return axiosCloudinary
    .post(`/image/upload`, data)
    .then(({ data }) => {
      dispatch({ type: SET_IMAGE_UPLOADING });
      return { success: true, data: data.url };
    })
    .catch((error) => {
      dispatch({ type: SET_IMAGE_UPLOADING });
      console.log("img uploading error", error);
    });
};

export const googleLogin = (data) => (dispatch) => {
  return axios
    .get(`${baseURL}/user/google`)
    .then(({ data }) => {
      return { success: true, data: data.url };
    })
    .catch((error) => {
      console.log("get userProfile error", error);
    });
};

export const saveToLibrary = (data) => (dispatch) => {
  return axios
    .put(`${baseURL}/user/save-to-library`, data)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setLocalUser(data.user));
        return { success: true, data: data.user };
      }
    })
    .catch((error) => {
      console.log("save to library error", error);
    });
};

export const unsaveFromLibrary = (data) => (dispatch) => {
  return axios
    .put(`${baseURL}/user/unsave-from-library`, data)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setLocalUser(data.user));
        return { success: true, data: data.userProfile };
      }
    })
    .catch((error) => {
      console.log("save to library error", error);
    });
};

export const followUser = (data) => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  return axios
    .put(`${baseURL}/user/follow_user`, data)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setUserProfile(data.userProfile));
        return { success: true, data: data.userProfile };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_PROFILE_LOADING });
      console.log("follow user error", error);
    });
};

export const unfollowUser = (data) => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  return axios
    .put(`${baseURL}/user/unfollow_user`, data)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setUserProfile(data.userProfile));
        return { success: true, data: data.userProfile };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_PROFILE_LOADING });
      console.log("unfollow user error", error);
    });
};

export const addSpeaker = (speaker) => (dispatch) => {
  dispatch({ type: SET_ADD_SPEAKER_LOADING });
  return axios
    .post(`${baseURL}/user/add_speaker`, speaker)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_ADD_SPEAKER_LOADING });
        console.log("speaker", res.data);
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_ADD_SPEAKER_LOADING });
      console.log("addSpeaker Error", error);
    });
};

export const getSpeakers = () => (dispatch) => {
  dispatch({ type: SET_ADD_SPEAKER_LOADING });
  return axios
    .post(`${baseURL}/user/fetch_speakers`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_ADD_SPEAKER_LOADING });
        return { success: true, speakers: res.data.Speakers };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_ADD_SPEAKER_LOADING });
      console.log("get Speakers Error", error);
    });
};

export const getUsersListDetails = () => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  return axios
    .get(`${baseURL}/user/fetch-users`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: SET_USER_PROFILE_LOADING });
        return { success: true, data: data.users };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_PROFILE_LOADING });
      console.log("get users list error", error);
    });
};

export const authCheck = (authProfile) => (dispatch) => {
  dispatch({ type: SET_LOGIN_USER_LOADING });
  return axios
    .post(`${baseURL}/user/authCheck`, authProfile)
    .then((res) => {
      if (res.data && res.data.success === true) {
        dispatch({ type: SET_LOGIN_USER_LOADING });

        dispatch(actions.setLoginUser(res.data));
        return { success: true, data: res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_LOGIN_USER_LOADING });
      console.log("Auth Check Error", error);
    });
};
