import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../../../../shared_components/CustomButton";

const pointsList = [
  {
    icon: "fa fa-cogs",
    title: "Technical Advancements",
  },
  {
    icon: "fa fa-comments",
    title: "Interactive debates",
  },
  {
    icon: "fa fa-briefcase",
    title: "Placement preparation",
  },
  {
    icon: "fa fa-laptop-code",
    title: "Project Building",
  },
  {
    icon: "fa fa-chalkboard-teacher",
    title: "Expert Sessions",
  },
  {
    icon: "fa fa-users",
    title: "Round Table discussions",
  },
  {
    icon: "fas fa-hands-helping",
    title: "Collaborative Learning",
  },
  {
    icon: "fas fa-tools",
    title: "Hands on Activities",
  },
  {
    icon: "fas fa-trophy",
    title: "Ultimately!! Get what you want",
  },
];
const WelcomeToClubSection = () => {
  return (
    <>
      <div className='row py-5 mx-0 d-flex justify-content-center'>
        <div className='col-md-10 col-12 py-md-5 py-0 px-md-5 px-0'>
          <h1 className='welcome-heading text-center mb-5'>
            Welcome to the Club, Where Learning Comes Alive!
          </h1>
          <div className='row mx-0 d-flex align-items-center'>
            <div className='col-12'>
              <div className='row mx-0 d-flex p-2'>
                {pointsList &&
                  pointsList.map((item, i) => (
                    <div
                      key={i}
                      className='col-md-4 col-12 px-md-2 py-md-2 px-0 py-2'
                    >
                      <div className='p-3 border rounded shadow-sm align-items-center'>
                        <p
                          style={{ fontSize: "16px", color: "rgb(92 91 91)" }}
                          className='mb-0'
                        >
                          <i
                            style={{
                              background: "#e7ecfc",
                              borderRadius: "50%",
                              padding: "10px",
                              color: "#000",
                            }}
                            className={item.icon}
                          />
                          <span className='ml-3'>{item.title}</span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className='text-center p-5 '>
                <Link to='/club/application'>
                  {/* <button className='text-center club-btn-outline'>
                    Apply Now! <i className='ml-3 fas fa-file-alt' />
                  </button> */}
                  <CustomButton buttonName="Apply Now!" reverseOrder={true} iconClass={<i className='ml-3 fas fa-file-alt ml-1'/>} className='px-5 py-3'/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WelcomeToClubSection;
