import React from "react";
import BecomeProfessional from "./BecomeProfessional";
import JoinUs from "./JoinUs";
import HiringPartners from "./HiringPartners";
import Testimonials from "./Testimonials";
import Technologies from "./Technologies";
import HeroSection from "./HeroSection";
import Registration from "./Registration";
import Events from "./Events";
import Projects from "./Projects";
import OurCulture from "./OurCulture";
import OurValues from "./OurValues";
import LifeHere from "./LifeHere";

const ScrollToTop = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};
const InternshipProgram = () => {
  return (
    <>
      <ScrollToTop />
      <div className="row mx-0 ">
        <div className="col-12 px-0">
          <div className="">
            <HeroSection />
          </div>
          {/* <div className="pt-lg-5">
            <FirstStepToFuture />
          </div> */}
          <div className="pt-lg-5">
            <OurCulture />
          </div>
          <div className="pt-lg-5">
            <OurValues />
          </div>
          <div className="pt-lg-5 pt-4">
            <BecomeProfessional />
          </div>
          <div className="pt-lg-5 pt-4">
            <Registration />
          </div>
          <div className="pt-lg-5">
            <Technologies />
          </div>
          <div className="pt-lg-5">
            <Projects />
          </div>
          <div className="pt-lg-5">
            <Events />
          </div>
          <div className="pt-lg-5">
            <LifeHere />
          </div>
          <div className="pt-lg-5">
            <HiringPartners />
          </div>
          <div className="pt-lg-5">
            <Testimonials />
          </div>
          <div className="pt-lg-5">
            <JoinUs />
          </div>
        </div>
      </div>
    </>
  );
};
export default InternshipProgram;
