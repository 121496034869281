import React from 'react'
import Loader from 'react-loader-spinner'

const BannerImageUpload = ({ setUserImgDetails, getUploadImgUrl, imageUploading }) => {

  const handleImage = (e) => {
    const data = new FormData()
    data.append("file", e.target.files[0])
    data.append("upload_preset", "user-profile-img")
    data.append("cloud_name", "akshaydev1802")
    setUserImgDetails(data).then((res) => {
      if (res && res.success) {
        return getUploadImgUrl(res.data)
      }
    })
  }

  return (
    <>
      <div className="row">
        <div className="col-12 border-2 align-items-center">
          {imageUploading ? <Loader
            type="ThreeDots"
            color="#30006d"
            height={100}
            width={100}
            timeout={3000} //3 secs
          /> :
            <>
              <input
                type="file"
                id="upload-btn"
                onChange={(e) => handleImage(e)}
                hidden
              />
              <label className="btn upload-block my-3 align-items-center" for="upload-btn">
                <i className="fas fa-upload mt-4" />
              </label>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default BannerImageUpload