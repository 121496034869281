const testimonialData = [
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1732199056/lrgvzm7wydfmxgb1dlt1.png`,
    name: "Divya Attarde",
    college: "SAIT",
    designation: "React Developer",
    review:
      "Interning at Datacode was a fantastic learning journey. The projects were challenging yet rewarding, and the guidance from the team helped me enhance my technical and analytical skills. It was a great opportunity to develop both personally and professionally.",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731935665/am3nfwet1uzprogb8yiw.png`,
    name: "Poorti Jain",
    college: "SVIMS",
    designation: "React Developer",
    review:
      "Working with this team has been an incredible experience! Their expertise and attention to detail ensured our project's success, exceeding all expectations. The seamless communication and dedication to delivering high-quality results truly set them apart. Highly recommended for anyone seeking top-notch service!",
  },
  // {
  //   image1: `http://res.cloudinary.com/datacode/image/upload/v1731935665/am3nfwet1uzprogb8yiw.png`,
  //   name: "Preety Arya",
  //   college: "CDGI",
  //   designation: "React Developer",
  //   review:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias pariatur sit culpa perferendis et possimus animi, temp assumenda, voluptatibus repellendus magnam deserunt incidunt optio excepturi quae ipsum reprehenderit nemo sed?",
  // },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1732198750/a6p41uy2fghxtlvphzhh.png`,
    name: "Smriti Jain",
    college: "Mandsaur University",
    designation: "React Developer",
    review:
      "My internship at Datacode was a highly enriching experience. I gained hands-on exposure to real-world projects, enhanced my technical skills, and learned the importance of teamwork and problem-solving. The supportive mentors and collaborative environment made it an excellent place to grow professionally.",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731999476/xwlgbzgodgb9tjm513xx.png`,
    name: "Aryan Karma",
    college: "Sage University",
    designation: "React Developer",
    review:
      "My internship at Datacode.in was an enriching experience. Working on impactful projects for an ed-tech platform helped me grow professionally while contributing meaningfully to education and technology.",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1732264312/a5tjhmdl28xv8mizfbb2.png`,
    name: "Sheetal Chouhan",
    college: "SVIMS",
    designation: "React Developer",
    review:
      "I had the incredible opportunity to intern with DataCode, and it has been an enriching experience that significantly contributed to my professional growth. From day one, the team was welcoming and supportive, providing me with valuable guidance and constructive feedback.",
  },
];
export default testimonialData;
