import React, { Component } from "react";
import { Link } from "react-router-dom";
import RightSideSection from "../../../right_section/RightSideSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFileCode } from "@fortawesome/free-solid-svg-icons";
import PostCard from "../../PostCard";
import ProgrammingQuestionCardCarousel from "./ProgrammingQuestionCardCarousel";
import Technologies from "../../../right_section/Technologies";
import { Helmet } from "react-helmet";
import CardCover from "../../../../shared_components/CardCover";

class IndexProgrammingQuestions extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const description =
      "Things can be stolen, Talent can be stolen,but the practice which decides learning are always yours,let’s wave your hands with (“Hello World”);!";
    const title = "Programming Example Questions | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet="true"
            name="description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            http-equiv="cleartype"
            content="on"
          ></meta>
          <meta
            data-react-helmet="true"
            name="apple-mobile-web-app-capable"
            content="yes"
          ></meta>
          <meta
            data-react-helmet="true"
            name="robots"
            content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:locale"
            content="en_US"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:type"
            content="website"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:url"
            content="https://www.datacode.in/"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:site_name"
            content="Datacode.in"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:secure_url"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:width"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:height"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            name="keywords"
            content="programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:site"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:creator"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <link
            data-react-helmet="true"
            rel="shortlink"
            href="https://www.datacodoe.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//www.datacode.in"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//s.w.org"
          ></link>
          <link
            data-react-helmet="true"
            rel="alternate"
            type="application/rss+xml"
            title="Datacode"
            href="https://www.datacode.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="canonical"
            href="https://www.datacode.in/"
          ></link>
        </Helmet>
        <div className="row mx-0">
          <div className="col-12 px-0">
            <div className="row m-0 px-3">
              <div className="col-1"></div>
              <div className="col-md-7 my-5">
                <h1 className="">Programming Example Question</h1>
                <p className="">
                  Things can be stolen, Talent can be stolen,but the practice
                  which decides learning are always yours,let’s wave your hands
                  with (“Hello World”);!
                </p>
                <Link
                  to="/home"
                  className="badge mr-1 my-4"
                  style={{ backgroundColor: "#06CB6C" }}
                >
                  <FontAwesomeIcon icon={faHome} size="2x" color="#072C71" />
                </Link>
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row mx-0">
              <div className="col-1"></div>
              <div className="col-md-8 col-12">
                <div className="row mx-0">
                  <div className="col-md-6 col-12">
                    <PostCard
                      cardComponent={
                        <CardCover
                          index={0}
                          cardName="C Programming Example Questions"
                          className="h-100 d-flex justify-content-center"
                        />
                      }
                      title="C Programming Example Questions"
                      paragraph="We Love to Code and this is the best way to express our
                  love to Computer Science Wolrd."
                      link="/c_programming_questions"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <PostCard
                      cardComponent={
                        <CardCover
                          index={1}
                          cardName="Python Programming Example Questions"
                          className="h-100 d-flex justify-content-center"
                        />
                      }
                      title="Python Programming Example Questions"
                      paragraph="We Love to Code and this is the best way to express our
                  love to Computer Science Wolrd."
                      link="/python_programming_questions"
                    />
                  </div>
                </div>
                <div className="row mx-0 pt-5">
                  <div className="col-md-6 col-12">
                    <PostCard
                      cardComponent={
                        <CardCover
                          index={3}
                          cardName="Java Programming Example Questions"
                          className="h-100 d-flex justify-content-center"
                        />
                      }
                      title="Java Programming Example Questions"
                      paragraph="We Love to Code and this is the best way to express our
                  love to Computer Science Wolrd."
                      link="/java_programming_questions"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <PostCard
                      cardComponent={
                        <CardCover
                          index={4}
                          cardName="DataStructure Example Questions"
                          className="h-100 d-flex justify-content-center"
                        />
                      }
                      title="DataStructure Example Questions"
                      paragraph="We Love to Code and this is the best way to express our
                  love to Computer Science Wolrd."
                      link="/datastructure_programming_questions"
                    />
                  </div>
                </div>
                {/* <div className="py-3">
                  <h5 className="text-secondary my-5">
                    For More Programming Questions
                  </h5>
                  <ProgrammingQuestionCardCarousel />
                </div> */}
              </div>
              <div className="col-md-3 col-12">
                <RightSideSection />
                <Technologies />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IndexProgrammingQuestions;
