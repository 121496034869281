import React, { useEffect, useState } from "react";
import { LuPartyPopper } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { rspvAllDetails } from "../../../actions";

const AisummitParticipantsCarosuel = () => {
  //   const [isPaused, setIsPaused] = useState(false);
  const [rsvpData, setRsvpData] = useState([]);
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rspvAllDetails()).then((res) => {
      const userData = res.data.rsvpUsers.slice(1);
      setRsvpData(userData);
    });
  }, [dispatch]);

  useEffect(() => {
    if (rsvpData.length > 0) {
      const convertedSlides = rsvpData.map((user, index) => {
        const img = user[1] && user[1].endsWith(".jpg") ? user[1] : null;
        return {
          id: index + 1,
          name: user[0],
          img: img,
        };
      });
      setSlides(convertedSlides);
    }
  }, [rsvpData]);

  const ScrollingRow = ({ slides, direction }) => {
    const renderSlides = (slides) => {
      return slides.map((slide, i) => {
        return (
          <>
            {slide.img && (
              <div key={i} className='d-inline-block mx-2 '>
                <div className='position-relative transform-hover bg-dark rounded-lg shadow d-flex align-items-center justify-content-center '>
                  <img
                    src={slide.img}
                    alt={slide.name}
                    className='img-fluid  custom-image rounded-lg'
                  />
                  <img
                    src='https://res.cloudinary.com/datacode/image/upload/v1732288011/g1ea3asibamysppju4fh.png'
                    alt='frame-img'
                    className='position-absolute top-0 start-0 translate-middle custom-frame rounded-lg'
                  />

                  <div className='overlay d-flex align-items-center justify-content-center text-white opacity-0 transition-opacity rounded-lg'>
                    <div className='text-center p-2'>
                      <h3 className='small font-weight-bold text-white'>
                        {slide.name}
                      </h3>
                      <p className='small font-weight-light'>{slide.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      });
    };

    return (
      <div className='my-3 overflow-hidden'>
        <div
          className={`d-flex flex-nowrap my-4 
            ${direction === "left" ? "scroll-left" : "scroll-right"}`}
        >
          {renderSlides(slides)}
          {renderSlides(slides)}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <h1 className='text-center text-light d-flex justify-content-center '>
            <span>Our Amazing Participants</span>
            <LuPartyPopper color='orange' className='mx-2' />
          </h1>
          <ScrollingRow slides={slides} direction={"left"} />
          <ScrollingRow slides={slides} direction={"right"} />
        </div>
      </div>
    </>
  );
};
export default AisummitParticipantsCarosuel;
