import { successStory } from "./Constants";
import { useEffect } from "react";
import Aos from "aos";
import { FaStar } from "react-icons/fa";

const SuccessStory = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="row mx-0 py-md-5 justify-content-center success-story-section">
      <div className="col-lg-10 col-12  px-md-0 px-0 py-md-5">
        <h1 className="text-center  py-lg-5 py-4 sub-heading">
          Success Stories
        </h1>
        <p className="text-center p-lg-3 p-2">
          Get to know how datacode's Programs have helped students upskill and
          achieve their goals effectively and efficiently.
        </p>
        <div className="row mx-0 justify-content-center gap-1">
          {successStory.map((items, index, i) =>
            items.size === "big" ? (
              <div
                key={index}
                className="col-md-7 col-12 pt-md-0  pt-5 mb-md-4"
              >
                <div
                  className="card  border shadow-sm success-story-card"
                  data-aos="fade-up"
                >
                  <div className="card-body d-flex flex-column justify-content-between">
                    <div className="d-flex  fs-16 icon-story">
                      {Array.from({ length: 4 }, (index) => (
                        <FaStar key={index} className="gap-2" />
                      ))}
                    </div>
                    <p className="details-section">{items.description}</p>
                    <div className="d-flex gap-2">
                      {/* <div className=" rounded-circle">
                        <img
                          src={items.img}
                          alt=""
                          className="rounded-circle d-flex  justify-content-start align-items-center"
                          width={75}
                        />
                      </div> */}
                      <div>
                        <h5 className="text-capitalize">{items.name}</h5>
                        <p className="fs-18">{items.profession}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-4 col-12">
                <div
                  className="card border shadow-sm success-story-card"
                  data-aos="fade-up"
                >
                  <div className="card-body d-flex flex-column justify-content-between">
                    <div className="d-flex  fs-16 icon-story">
                      {Array.from({ length: 4 }, (index) => (
                        <FaStar key={index} className="gap-2" />
                      ))}
                    </div>
                    <p className="details-section">{items.description}</p>
                    <div className="d-flex gap-2">
                      {/* <div className=" rounded-circle">
                        <img
                          src={items.img}
                          alt=""
                          className="rounded-circle d-flex  justify-content-start align-items-center"
                          width={75}
                        />
                      </div> */}
                      <div>
                        <h5 className="text-capitalize">{items.name}</h5>
                        <p className="fs-18">{items.profession}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessStory;
