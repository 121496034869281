import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import CardCarouselItem from "../../../app/components/footer_component/CardCarouselItem";
import CardCover from "../../../shared_components/CardCover";

class ArticleCardCarousel extends Component {
  state = {};
  render() {
    return (
      <>
        {/* Desktop Carousel Cards  */}
        <div className="mb-5 d-none d-sm-block">
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className="card-deck ">
                <CardCarouselItem
                      cardComponent={<CardCover index={0} cardName="Programming Languages Blogs" className="py-5" />}
                  title="Programming Languages Blogs"
                  link="/prog_lan_blog"
                />
                <CardCarouselItem
                  cardComponent={<CardCover index={1} cardName="Web Design Blogs" className="py-5" />}
                  title="Web Design Blogs"
                  link="/webdesign_blog"
                />
                <CardCarouselItem
                  cardComponent={<CardCover index={2} cardName="Machine Learning Blogs" className="py-5" />}
                  title="Machine Learning Blogs"
                  link="/machine_learning_blog"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-deck">
                <CardCarouselItem
                  cardComponent={<CardCover index={3} cardName="DBMS Blogs" className="py-5" />}
                  title="DBMS Blogs"
                  link="/dbms_blog"
                />
                <CardCarouselItem
                      cardComponent={<CardCover index={4} cardName="Tech Documentation" className="py-5" />}
                  title="Tech Documentation"
                  link="/documentation_blog"
                />
                <CardCarouselItem
                   cardComponent={<CardCover index={5} cardName="Machine Learning Blogs" className="py-5" />}
                  title="Machine Learning Blogs"
                  link="/machine_learning_blog"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Mobile Carousel Cards  */}
        <div className="mb-5 d-block d-md-none">
          <Carousel indicators={false}>
            <Carousel.Item>
              <CardCarouselItem
                 cardComponent={<CardCover index={0} cardName="Programming Languages Blogs" className="py-5" />}
                title="Programming Languages Blogs"
                link="/prog_lan_blog"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={
                  <CardCover cardName="Web Design Blogs" index={1} />
                }
                title="Web Design Blogs"
                link="/webdesign_blog"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
               cardComponent={<CardCover index={2} cardName="Machine Learning Blogs" className="py-5" />}
                title="Machine Learning Blogs"
                link="/machine_learning_blog"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
              cardComponent={<CardCover index={3} cardName="DBMS Blogs" className="py-5" />}
                title="DBMS Blogs"
                link="/dbms_blog"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={<CardCover index={4} cardName="Tech Documentation" className="py-5" />}
                title="Tech Documentation"
                link="/documentation_blog"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CardCarouselItem
                cardComponent={<CardCover index={5} cardName="Machine Learning Blogs" className="py-5" />}
                title="Machine Learning Blogs"
                link="/machine_learning_blog"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}

export default ArticleCardCarousel;
