import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./core/store";
import { Provider } from "react-redux";
import "react-widgets/styles.css";
import { Toaster } from 'react-hot-toast';
import { setupAxiosInterceptors } from "./core/axios";
import "./assets/scss/bootcamp.scss";


setupAxiosInterceptors(); //for Managing API Headers
ReactDOM.render(
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <App />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      />
    </Router>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
