import React from "react";
import img from "../../../../../../assets/images/AISummit/AI & Copilot summit Indore Posters  (LinkedIn Article Cover Image).png";
import { useLocation } from "react-router-dom";

const AiSummitHeder = () => {
  const location = useLocation();

  return (
    <>
      <div className='row mx-0 py-5 ai-summit-header'>
        <div className='col-12 px-0'>
          <div className='text-center'>
            <img src={img} loading="lazy" alt='' className='ai-summit-header-img' />
          </div>
          <div className='row mx-0 justify-content-center'>
            <div className='col-lg-9 col-11 ai-summit-header-contain'>
              <h3 className='mt-md-4 mt-2 text-center text-white'>01 Dec 2024</h3>
              <p className='text-light text-justify text-md-center'>
                Join industry experts, developers, and React enthusiasts for a
                day of learning, hands-on workshops, and networking. Boost your
                React skills and explore the latest in front-end development.
              </p>
              {/* {
                location.pathname !== "/aisummit/rsvp" &&
                <div className='d-flex gap-2 text-center justify-content-center align-items-center'>
                  <div>
                    <a href='https://forms.gle/2MkurnKrKMQTggEL6' target='_blank'>
                      <button className='btn btn-secondary'>Register Now</button>
                    </a>
                  </div>
                </div>
              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AiSummitHeder;
