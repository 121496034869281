import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faUserNinja } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../shared_components/CustomButton";
class RecommendPost extends Component {
  
  state = {};
  render() {
    const {cardComponent , className} = this.props;
    return (
      <>
        {/* FOr Popular POst */}

        {/* Recommend Post */}
        <div className='row mx-0'>
          <div className='card border border-white col-12 px-0'>
            <div className='row mx-0'>
              <div className='col-md-6 col-12'>
              
              <div>{cardComponent || null}</div>

              </div>
              <div className='col-md-6 col-12 pt-lg-0 pt-2'>
                <h6
                  className='card-title fs-14'
                  style={{ color: "#072C71" }}
                >
                  {this.props.RecPostHading}
                </h6>
                <Link to={this.props.link} className='font-weight-bold'>
                  <CustomButton buttonName="Start Learning " className="p-1 fs-12" iconClass={<FontAwesomeIcon icon={faUserNinja}/>}/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RecommendPost;
