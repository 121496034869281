import PropTypes from "prop-types";

const backgroundColors = [
  "#574964",
  "#143D60",
  "#FFD95F",
  "#A1E3F9",
  "#A94A4A",
  "#3B6790",
  "#000000"
];

const CardCover = ({ index,className , cardName , cardStyle }) => {
  const backgroundColor = backgroundColors[index % backgroundColors.length];
  const style = {
    backgroundColor,
  };
  return (
    <>
      <div style={style} className={`${className} justify-content-center align-items-center text-center`}>
      <p className="m-0 px-2 fs-12" style={cardStyle}>{cardName}</p>
      </div>
    </>
  );
};
export default CardCover;

CardCover.defaultProps = {
 cardName :"",
 cardStyle : {
  color : "#ffffff",
  fontSize : "14px",
 }
} 

CardCover.propTypes = {
  cardName: PropTypes.string.isRequired,
  cardStyle: PropTypes.object

}